@import '../../../variables';

.option-filter-container {
    height: 40px;

    .option-filter__control {
        height: 100%;
    }

    .option-filter__menu{
        z-index: 10 !important;
    
        .option-filter__menu-list {
            .option-filter__option {
                label{
                    margin-bottom: unset !important;
                    margin-left: 0.5em;
                }
                .filter-option-all-label {
                    font-weight: bold;
                }
            }   
        }
    }
}
.option-filter-container-filtered {
    .option-filter__control {
        background-color: $filter-option-selected !important;
    }
}