@import '../../../_variables.scss';
@import '../../../shared/_common.scss';

.internal-Vehicles {
    padding: $view-padding;

    .internal-vehicles-grid {
        width: 100%;

        //height: calc(100vh - (160px + 0.8rem));
        .k-grid td,
        .k-grid th {
            font-family: Roboto;
        }

        .k-grid th {
            color: $color-thead;
        }

        .k-checkbox-label {
            min-height: 24px;
            min-width: 28px;
            margin-left: 2px;
            margin-top: 3px;
        }

        thead {
            background-color: $gray-background-card;
        }

        thead tr {
            height: 40px;
        }

        .vehicles-grid {
            width: 100%;
        }

        .warning-icon-cell {
            color: $grid-referential-internal-vehicle-difference-data-warning-color !important;
            text-align: center !important;
        }

        .field-changed {
            background-color: $field-td-diff-background-color !important;
            color: black !important;
        }
    }
}

.add-internal-vehicle-dialog {
    .dialog-close-button {
        padding: 0 10px;
    }

    .add-internal-vehicle-content {
        width: 440px;

        .vehicle-text-info {
            margin-bottom: 20px;
        }

        .input-label {
            color: $dialog-transport-flow-label-color;
        }

        .input-text {
            width: 100%;
        }

        .vehicle-type-list {
            width: 100%;
        }

        .info-licence-plate {
            font-size: 12px;
            margin: 5px 0;
        }
    }
}

.edit-vehicle-dialog {
    padding-top: 4px;

    .dialog-close-button {
        padding: unset !important;
    }

    .simple-dialog-content {
        .edit-content {
            padding-left: 1em;

            .vehicle-title {
                font-size: 1.2em;
                color: #858889;
            }

            .licence-plate {
                font-weight: bold;
                font-size: 1.2em;
            }

            .title {
                font-size: 0.8em;
                color: #858889;

                &.required {
                    color: #FF6E00;
                }
            }

            .text-field {
                width: 90%;

                .MuiInputBase-root {
                    height: 56px;
                }
            }

            label {
                margin-bottom: unset;
            }

            .input-vehicle-type-select {
                width: 360px;

                &.required>div {
                    border-color: #FF6E00;
                }
            }
        }
    }
}