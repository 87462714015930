.edit-Production-site {
    width: 900px;
    height: 600px;

    .title {
        font-size: 1.2em;
    }

    .value {
        font-size: 1.2em;
        font-weight: 700;
        margin-left: 5px;
        margin-right: 20px;
    }

    .activities {
        margin-top: 20px;

        .activity-item {
            padding: 5px;

            .activity, .label, .business-unit {
                &.header {
                    font-weight: 700;
                }
            }

            .activity {
                width: 150px;

                .select {
                    width: 130px;
                    height: 30px;
                }
            }

            .label {
                width: 350px;
            }

            .business-unit {
                width: 350px;

                .input {
                    width: 320px;

                    &.error {
                        border: 1px solid red;
                    }
                }
            }

            .fa-times {
                color: red;
            }
        }
    }

    .fa-plus {
        color: #6098f2;
    }
}
