@import '../../../_variables.scss';
@import '../../../shared/_common.scss';

.level2Activities {
    padding: $view-padding;

    .level2Activities-grid-content {
        width: 100%;
        //height: calc(100vh - (160px + 0.8rem));
        .k-grid td, .k-grid th {
            font-family: Roboto;
        }

        .k-grid th {
            color: $color-thead;
        }

        .k-checkbox-label {
            min-height: 24px;
            min-width: 28px;
            margin-left: 2px;
            margin-top: 3px;
        }

        thead {
            background-color: $gray-background-card;
        }

        thead tr {
            height: 40px;
        }

        .level2Activities-grid {
            width: 100%;
        }
    }
}
