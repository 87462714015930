@import '../variables';

#user-tooltip {
    z-index: 99999;
}

.user-settings-popup {
    margin-left: -10px;
    padding-right: 15px;

    .content {
        background-color: #004568;
        color: #fff;
        width: 200px;
        padding: 10px;
    }
}

.navMenu {

    .logisticsUnits-menu {

        .logisticsUnitLabel {
            margin: 0 5px;
        }

        .logistics-unit-menu {
            background-color: #EFF2F4;
            max-height: calc(100vh - 100px);
            overflow-y: auto;
            padding: 0 !important;
        }

        .logistics-unit-item {
            background-color: white !important;
            border-bottom: 1px solid $gray !important;
            padding: 0 !important;
        }

        .logistics-unit-item.logistics-unit-item-checked {
            background-color: $light-blue !important;
            color: $primary;
        }

        .logistics-unit-item > label {
            margin: 0 !important;
            width: 100%;
            padding: 5px 15px 5px 0px;
        }

        .checked-logistics-unit {
            color: $primary !important;
            font-weight: bold !important;
        }

        .approve-logistics-unit-section {
            display: flex;
            flex-direction: column;
            margin: 10px 0;
        }

        .warning-select-logistics-unit {
            color: red;
            margin: 10px;
        }

        .btn-approve-logistics-unit {
            margin: auto !important;
            background-color: $primary !important;
            color: white !important;
        }
    }

    .initial-profile {
        width: 50px !important;
        height: 50px !important;
        background-color: $blue !important;
    }

    .initial-profile.is-log {
        cursor: pointer;
    }
}
