@import '../../../_variables.scss';
@import '../../../shared/_common.scss';

$dialog-content-height: 440px;

.internal-Drivers {
    padding: $view-padding;

    .internal-drivers-grid {
        width: 100%;

        //height: calc(100vh - (160px + 0.8rem));
        .k-grid td,
        .k-grid th {
            font-family: Roboto;
        }

        .k-grid th {
            color: $color-thead;
        }

        .k-grid .phone-number-ref {
            color: blue;
            text-decoration: underline;
        }

        .k-checkbox-label {
            min-height: 24px;
            min-width: 28px;
            margin-left: 2px;
            margin-top: 3px;
        }

        thead {
            background-color: $gray-background-card;
        }

        thead tr {
            height: 40px;
        }

        .drivers-grid {
            width: 100%;

            .checkbox {
                height: 20px;
                width: 20px;
            }
        }


        .warning-icon-cell {
            color: $grid-referential-internal-driver-difference-data-warning-color !important;
            text-align: center !important;
        }

        .field-changed {
            background-color: $field-td-diff-background-color !important;
            color: black !important;
        }
    }
}

.add-internal-driver-dialog {
    .MuiDialog-paper {
        max-width: none;
        width: 684px;
    }

    .dialog-close-button {
        padding: 0 10px;
    }

    .add-driver-component {
        .loading-content {
            height: 30px;
        }

        .search-driver-component {
            .driver-infos {
                height: $dialog-content-height;
            }

            .input-text-content {
                width: 450px;
            }

            .mgrh-input-text-label,
            .driver-last-name-input-text-label {
                color: $dialog-transport-flow-label-color;
            }

            .driver-mgrh-input-text,
            .driver-last-name-input-text {
                width: 100%;
            }
        }

        .driver-choices {
            .driver-choices-grid {
                width: 100%;
                height: $dialog-content-height;

                .k-grid td,
                .k-grid th {
                    font-family: Roboto;
                }

                thead {
                    background-color: $gray-background-card;
                    color: $color-thead;
                }

                thead tr {
                    height: 40px;
                }
            }
        }

        .confirm-add-driver {
            .input-text-content {
                width: 450px;
            }

            .driver-phone-number-input-text-label {
                color: $dialog-transport-flow-label-color;
            }

            .driver-phone-number-input-text {
                width: 100%;
            }

            .driver-phone-number-input-text-label.invalid {
                color: $dialog-transport-flow-error-label-color;
            }

            .driver-phone-number-input-text.invalid {
                border-bottom: 1px solid $dialog-transport-flow-error-label-color;
            }

            .driver-phone-number-input-text::before {
                border-bottom: none;
            }
        }

        .previous-button {
            background-color: #fff;
            margin-right: 10px;
            color: unset !important;
        }
    }
}

.edit-driver-dialog {
    padding-top: 4px;

    .dialog-close-button {
        padding: unset !important;
    }

    .simple-dialog-content {
        min-width: 350px;

        .edit-content {
            padding-left: 1em;

            .driver-title {
                .driver-name {
                    font-weight: bold;
                }

                font-size: 1.2em;
            }

            .text-field {
                width: 90%;

                .MuiInputBase-root {
                    height: 56px;
                }
            }

            .phone-number .MuiInputBase-root {
                height: 56px;
            }

            label {
                margin-bottom: unset;
            }

            .input-select {
                width: 500px;
            }
        }
    }
}

.add-temporary-driver-dialog {
    .MuiDialog-paper {
        max-width: none;
        width: 450px;
    }

    .dialog-close-button {
        padding: 0 10px;
    }

    .input-text-content {
        width: 400px;
        margin-bottom: 20px;
    }

    .temporary-driver-input-text-label {
        font-size: 12px;
        color: $dialog-transport-flow-label-color;
    }

    .temporary-driver-input-text-label.required {
        color: $dialog-required-label-color;
    }

    .invalid-phone-number-label {
        font-size: 12px;
        color: $dialog-required-label-color;
    }

    .temporary-driver-input-text-value,
    .temporary-driver-select-value {
        width: 100%;
    }

    .temporary-driver-input-text-value.required::before {
        border-bottom: 1px solid $dialog-required-label-color;
    }

    .temporary-driver-select-value.required>div {
        border-color: $dialog-required-label-color;
    }

    .cancel-button {
        margin-right: 5px;
    }
}

#add-driver-tooltip-image .info-user-text {
    line-height: 1.5;
}

#add-driver-tooltip-image>.MuiTooltip-tooltip {
    width: 745px;
    max-width: none;
}

.add-temporary-driver-modal {
    margin-top: 5em;
}