@import '../../../_variables.scss';
@import '../../../shared/_common.scss';

.internal-contacts {
    padding: $view-padding;

    .header-content {
        .span-disabled {
            cursor: default !important;
        }
    }

    .colas_customers-contact-grid {
        width: 100%;
        //height: calc(100vh - (170px + 0.8rem));
        .k-grid td, .k-grid th {
            font-family: Roboto;
        }

        .k-grid th {
            color: $color-thead;
        }

        .k-grid .phone-number-ref {
            color: blue;
            text-decoration: underline;
        }

        .k-checkbox-label {
            min-height: 24px;
            min-width: 28px;
            margin-left: 2px;
            margin-top: 3px;
        }

        thead {
            background-color: $gray-background-card;
        }

        thead tr {
            height: 40px;
        }

        .internal-contact-grid {
            width: 100%;
            padding-left: 0.2em;

            .k-grid-container {
                .k-virtual-content {
                    overflow: auto;
                }
            }
        }

        th.k-header.logistics-unit, td.logistics-unit-cell {
            border-left: solid;
            padding-left: 0.5em !important;
            text-align: center;
        }

        td.logistics-unit-cell {
            .checkbox-label {
                align-self: center;
                margin-right: 10px;
            }

            .custom-checkbox {
                margin-right: 10px;
            }
        }
    }

    .contact-choice-cell {
        margin-left: 8px;
        margin-bottom: 0px;

        span {
            font-size: 12px;
        }
    }

    #global-search-contacts-referential {
        margin: 0 10px;
    }

    #global-search-contacts-referential > span {
        font-size: 14px;
        font-weight: 500;
        white-space: nowrap;
    }
}

.add-contact-dialog {
    .dialog-close-button {
        padding: 0 10px;
    }


    .add-contact-content {
        width: 300px;
    }

    .username-input-text {
        width: 100%;
    }

    .username-input-text-label, .user-role-label {
        color: $dialog-transport-flow-label-color;
    }
}

.edit-contact-dialog {
    .simple-dialog-content {
        padding-top: 4px;

        .dialog-close-button {
            padding: 0 10px;
        }

        .edit-content {
            width: 300px;
            padding-left: 1em;

            .user-name {
                font-weight: bold;
                font-size: 1.2em;
            }

            .phone-number .MuiInputBase-root {
                height: 56px;
            }

            label {
                margin-bottom: unset;
            }
        }

        .user-role-label {
            color: $dialog-transport-flow-label-color;
        }
    }
}

#add-contact-tooltip-image .info-user-text {
    line-height: 1.5;
}

#add-contact-tooltip-image > .MuiTooltip-tooltip {
    width: 415px;
    max-width: none;
}
