@import '../../variables';
@import '../../shared/common';

.purchase-provisionings-view {
    margin-right: $drawer-lot-width;

    .purchase-content {
        transition: margin 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
        width: 100% !important;
        height: 100% !important;

        .header-content {
            background-color: $color-white;

            .logistics-unit-select {
                width: 370px;

                div {
                    z-index: 10;
                }
            }

            .btn-users {
                border-top-left-radius: 0%;
                border-top-right-radius: 50%;
                border-bottom-left-radius: 0%;
                border-bottom-right-radius: 50%;

                &.btn-actif {
                    background-color: $button-track-flow-background-color;
                }

                .btn-icon {
                    color: $button-icon-open-drawer-color;
                }
            }

            .title.pl-2 {
                padding-left: 2.5em !important;
            }

            .title {
                padding-left: 0.5em !important;
                color: $title-color;
                font-weight: 500;
                size: 20px;
            }

            .pl-5 {
                padding-left: 5em !important;
            }

            .pl-2 {
                padding-left: 2em !important;
            }

            .k-dateinput {
                width: 48%;
            }
        }

        table {
            tr th, tr td {
                height: 20px;
                width: 80px;
            }

            .empty-content {
                background: linear-gradient(to right bottom, #BDCFD8 50%, white 50%);
            }
        }

        .filter-title {
            color: gray;
            font-size: 12px;
            height: 20px;
        }

        .provisioning-label-filter, .diff-provisioning-label-filter {
            margin-bottom: unset;
        }

        .diff-provisioning-label-filter {
            color: $title-diff-color;
            font-weight: bold;
        }

        .purchase-provisioning-sent-successfully {
            color: $green-text !important;
            font-weight: 500;
        }

        .purchase-provisioning-sent-error {
            color: red !important;
            font-weight: 500;
        }

        .option-filter-container .option-filter__control {
            background-color: #F0F4F6;
            font-size: 14px;
        }

        .splitter {
            height: calc(100% - 100px);
        }

        .parent-pp {
            height: calc(100% - 200px);

            .spinnerClass {
                margin-left: unset !important;
                text-align: center;
            }

            .pp-splitter {
                height: 100%;

                .k-grid td, .k-grid th {
                    font-family: Roboto;
                }

                .k-grid th {
                    color: $color-thead;
                }

                .k-grid-header th {
                    font-weight: 500;
                    color: #858889;
                }

                .k-grid-container .k-grid-content {
                    overflow: auto;
                }

                .k-checkbox-label {
                    min-height: 24px;
                    min-width: 28px;
                    margin-left: 2px;
                    margin-top: 3px;
                }

                thead {
                    background-color: #F0F4F6;
                    font-size: 12px;
                    font-weight: 600;
                    letter-spacing: -0.3px;
                    line-height: 17px;

                    .preInvoice-header {
                        text-align: center;
                    }

                    tr {
                        height: 40px;
                    }
                }

                .preInvoice-icon {
                    text-align: center;

                    .line-hr {
                        width: 1.5em;
                    }

                    .icon-success {
                        color: $purchase-provision-icon-success-color;
                    }

                    .icon-error {
                        color: $purchase-provision-icon-error-color;
                    }

                    .icon-pending {
                        color: $purchase-provision-icon-pending-color;
                    }

                    .icon-minus {
                        color: $costs-are_not_published_color;
                    }
                }

                .field-different-value-purchaseProvisioning {
                    padding-left: 2px !important;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    flex-direction: row;
                    background-color: $field-td-diff-background-color;
                }

                .vehicle-removed, .negative-price {
                    color: red;
                }

                .icon-work {
                    color: $purchase-provision-icon-work-color;
                }

                .purchase-cost-overriden {
                    color: $color-light-blue;
                }

                .purchase-cost-is-nightwork {
                    background-color: rgb(228, 243, 243);
                }

                .title-grid {
                    font-weight: bold;
                    padding-left: 16px;
                    font-size: 15px;
                }

                .vehicles-by-provisioning {
                    height: calc(100% - 30px);

                    .grid {
                        height: 100%;
                        width: 100%;
                    }
                }

                .vehicles-provisionings {
                    height: 100%;
                    width: 100%;

                    .grid {
                        width: 100%;
                    }

                    .cell-price-half {
                        width: 50%;
                    }

                    .cell-price-full {
                        width: 100%;
                    }

                    td.cell-checkbox, th .cell-checkbox {
                        padding-left: 1em !important;
                    }

                    .confirmed-vehicle {
                        height: 1.5em;
                        width: 1.5em;
                        border-radius: 50%;

                        &.confirmed-vehicle-2 {
                            border: 1px solid $color-confirmed;
                            background-color: $color-confirmed;
                        }

                        &.confirmed-vehicle-3 {
                            background-color: $color-planned;
                            border: 1px solid $color-planned;
                        }

                        &.confirmed-vehicle-1 {
                            border: 1px solid $color-confirmed;
                        }
                    }

                    .remarks-cell {
                        max-height: 39px;
                    }
                }
            }
        }
    }

    &.purchase-shiftLeft {
        transition: margin 225ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
        margin-left: $drawer-transporter-margin-width;
        flex-grow: 1;
    }

    .provisionning-table {
        border-collapse: separate;
        border-spacing: 0;
        background-color: $header-grid;

        tr:first-child th:first-child {
            border-top-left-radius: 4px;
        }

        tr:first-child th:last-child {
            border-top-right-radius: 4px;
        }

        tr:last-child td:first-child {
            border-bottom-left-radius: 4px;
        }

        tr:last-child td:last-child {
            border-bottom-right-radius: 4px;
        }

        thead {
            background-color: unset;
        }

        tr th {
            border-top: 1px solid $border-color;
        }

        tr th:first-child, tr td:first-child {
            border-left: 1px solid $border-color;
        }

        tr td, tr th {
            border-right: 1px solid $border-color;
            border-bottom: 1px solid $border-color;
            height: 40px;
        }

        td.center-content, th.center-content {
            text-align: center;
            font-weight: bold;
            font-size: 12px;
        }

        td.left-content, th.left-content {
            padding-left: 2px;
            font-weight: bold;
            font-size: 12px;
        }

        td.title {
            color: $title-color !important;
        }

        td.value {
            color: black !important;
        }
    }
}

.drawer-tr {
    z-index: 0;
    width: $drawer-transporter-margin-width;
    flex-shrink: 0;

    .drawer-paper {
        height: 100vh;
        width: $drawer-transporter-margin-width;
        background-color: $color-white;

        .content-drawer {
            flex-grow: 1;
            height: 95vh;
            font-family: 'Roboto', sans-serif;

            .spinnerClass {
                text-align: center;
                margin-left: unset !important;
            }

            .header {
                padding-top: 1.8em;
                padding-left: 1.5em;
                padding-bottom: 1.1em;
                width: 95%;

                .title {
                    color: $title-color;
                    font-weight: bold;
                    font-size: 1.2rem !important;
                    letter-spacing: -0.63px;
                }
            }

            .body {
                padding-left: 1.5em;
                padding-right: 1.5em;
                padding-bottom: 0.5em;

                .transporter-item {
                    margin-left: unset;
                    margin-right: unset;
                    margin-bottom: unset;

                    .lbl {
                        font-weight: 500;
                        line-height: 16px;
                        font-size: 14px;
                    }

                    &.selected-transporter {
                        width: 100%;
                        background-color: $list-item-selected;
                    }

                    .black-cls {
                        color: #000000;
                    }
                }
            }
        }
    }
}

.drawer-generation-historical-lot {
    z-index: 0;
    width: $drawer-lot-width;
    flex-shrink: 0;

    .drawer-paper {
        height: calc(100vh - 67px);
        width: $drawer-lot-width;
        margin-top: 67px;
        background-color: $color-white;

        .k-content {
            height: calc(100vh - 137px);
            padding-left: unset;
            padding-right: unset;
        }

        .tab-strip {
            padding-bottom: unset !important;
            padding-right: unset !important;

            .k-tabstrip-content {
                overflow: hidden;
            }

            .k-tabstrip-items-wrapper {
                border: unset !important;

                .k-item {
                    padding-left: 0.5em !important;
                    color: $title-color;
                    font-weight: 500;
                    font-size: 1.2em;
                    size: 20px;

                    &.k-state-active {
                        font-weight: 600;
                    }
                }
            }

            .k-animation-container {
                width: 100%;

                .k-child-animation-container {
                    height: 100%;

                    div[role="tabpanel"] {
                        height: 100%;
                        overflow-y: auto;
                    }
                }
            }

            .new-provisioning-content {
                padding-left: 24px;
                padding-right: 24px;

                .component {
                    height: 100%;
                    margin-right: 0.2em;

                    .container-grid {
                        height: 90%;
                        min-height: 290px;

                        table {
                            background-color: $header-grid;
                        }

                        tr:first-child th:first-child {
                            border-top-left-radius: 4px;
                        }

                        tr:first-child th:last-child {
                            border-top-right-radius: 4px;
                        }

                        tr:last-child td:first-child {
                            border-bottom-left-radius: 4px;
                        }

                        tr:last-child td:last-child {
                            border-bottom-right-radius: 4px;
                        }

                        thead {
                            background-color: unset;
                        }

                        tr th {
                            border-top: 1px solid $border-color;
                        }

                        tr th:first-child, tr td:first-child {
                            border-left: 1px solid $border-color;
                        }

                        tr td, tr th {
                            border-right: 1px solid $border-color;
                            border-bottom: 1px solid $border-color;
                            height: 40px;
                        }

                        td.center-content, th.center-content {
                            text-align: center;
                            font-weight: bold;
                            font-size: 12px;
                        }

                        td.left-content, th.left-content {
                            padding-left: 2px;
                            font-weight: bold;
                            font-size: 12px;
                        }

                        td.title {
                            color: $title-color !important;
                        }

                        td.value {
                            color: black !important;
                        }

                        .error-message {
                            color: red;
                            font-weight: bold;
                        }
                    }

                    .new-provisioning-btn, .regularization-provisioning-btn {
                        width: 100%;
                        height: 40px;
                        border-radius: 2px !important;
                        background-color: $button-actions-enabled !important;
                        color: $color-white !important;
                        font-size: 14px !important;
                        font-weight: 500 !important;
                        letter-spacing: -0.35px !important;
                        line-height: 19px !important;
                        text-align: center !important;
                    }

                    .new-provisioning-btn {
                        margin-bottom: 0.5em;
                    }

                    .new-provisioning-btn:disabled {
                        background-color: #e0e0e0 !important;
                    }

                    .regularization-provisioning-btn-disabled {
                        width: 100%;
                        height: 40px;

                        Button {
                            background-color: #e0e0e0 !important;
                            width: 100%;
                            height: 100%;
                            border-radius: 2px !important;
                            color: $color-white !important;
                            font-size: 14px !important;
                            font-weight: 500 !important;
                            letter-spacing: -0.35px !important;
                            line-height: 19px !important;
                            text-align: center !important;
                        }
                    }
                }
            }

            .purchase-to-send-drawer {
                padding-left: 24px;
                padding-right: 24px;

                .header-costs {
                    padding-top: 0.3em;
                    padding-bottom: 0.3em;
                    border-radius: 10px;
                    background-color: $header-menu-background-color !important;
                    color: white;
                    text-align: center;

                    .title {
                        font-size: 0.8em;
                    }

                    .value {
                        font-size: 1.2em;
                    }

                    .vertical-separator {
                        background-color: white;
                        height: 80%;
                        width: 2px;
                    }
                }

                .spinnerClass {
                    text-align: center;
                    margin-left: unset !important;
                }

                .card-selected {
                    background-color: $template-card-selected;
                }

                .regularization-card .title-div {
                    background-color: $color-regularization-orange;
                }

                .card {
                    margin-bottom: 0.7em;
                    cursor: pointer;

                    .card-content {
                        padding: unset !important;

                        .title-div {
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            padding: 0.7em;

                            .title {
                                font-weight: bold;

                                &.title-error {
                                    color: red;
                                }

                                &.title-success {
                                    color: $green-text;
                                }

                                &.title-partially-success {
                                    color: darkorange;
                                }
                            }

                            .triangle {
                                width: 0px;
                                height: 0px;
                                border-left: 10px solid transparent;
                                border-right: 11px solid transparent;
                                border-bottom: 19px solid $grid-transport-flow-diff-color;
                                margin-left: 15px;

                                .number {
                                    margin-left: -3px;
                                    font-size: 10px;
                                    font-weight: bold;
                                    color: #FFFFFF;
                                }
                            }

                            .icon-trash {
                                width: 10%;
                                padding-left: 1em;
                                color: $color-delayed;
                            }
                        }

                        .footer-div {
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            padding-left: 0.7em;
                            padding-right: 0.7em;

                            .mail-transporter-div {
                                width: 50%;
                                height: 42px;

                                .label-mail {
                                    .MuiFormControlLabel-label {
                                        font-size: 14px;
                                    }

                                    &.label-mail-error {
                                        .Mui-disabled {
                                            color: $color-delayed !important;
                                        }
                                    }

                                    margin-right: unset !important;
                                    margin-bottom: unset !important;
                                }
                            }

                            .send-zephyr-div {
                                margin-left: 10px;

                                &.btn-disabled {
                                    cursor: default;
                                }
                            }

                            .to-be-visible-div {
                                .to-be-visible-BI-icon {
                                    color: $to-be-visible-BI-icon-color;
                                }

                                .to-be-invisible-BI-icon {
                                    color: $to-be-invisible-BI-icon-color;
                                }

                                .to-be-visible-BI-icon-disabled {
                                    color: $to-be-visible-BI-icon-color-disabled;
                                    cursor: default;
                                }
                            }
                        }

                        .line-details {
                            padding-top: 0.5em;
                            padding-left: 0.7em;
                            display: flex;
                            flex-direction: row;

                            .field-content {

                                &.field-content-w50 {
                                    width: 50%;
                                }

                                &.field-content-w100 {
                                    width: 100%;
                                }

                                .title-field {
                                    color: #858889;
                                    font-size: 12px;
                                    display: flex;
                                    flex-direction: row;

                                    .icon-pen {
                                        padding-left: 1em;

                                        .icon-pen-blue {
                                            color: $icon-pen-blue;
                                        }
                                    }
                                }

                                .title-value {
                                    height: 25px;
                                    width: 100%;

                                    &.sum-exceeded {
                                        color: red;
                                    }

                                    .k-numerictextbox, .input-remarks, .gasoil-index-input {
                                        border-radius: 21.5px !important;
                                        border: 1px solid $gray;
                                        background-color: $input-background-color !important;
                                        padding-right: unset !important;
                                        padding-left: 1em !important;
                                        height: 30px !important;
                                    }

                                    .input-remarks {
                                        width: 95%;
                                    }

                                    .input-remarks:focus {
                                        outline: unset !important;
                                    }

                                    .gasoil-index-input {
                                        max-width: 120px;
                                    }

                                    .edit-field {
                                        display: flex;
                                        flex-direction: row;
                                        align-items: center;
                                        height: 100%;
                                        width: 95%;
                                        cursor: default;

                                        .value {
                                            width: 100%;

                                            &.gasoil-index-overriden {
                                                color: $overriden-color;
                                            }

                                            &.sum-exceeded {
                                                color: red;
                                            }
                                        }
                                    }
                                }

                                .value-field {
                                    font-size: 14px;
                                }
                            }
                        }

                        .pb-1 {
                            padding-bottom: 1em;
                        }
                    }
                }
            }

            .input-search-purchase-provisioning {
                height: 40px !important;
                border-radius: 21.5px;
                background-color: $input-background-color;
                padding-right: unset !important;

                .icon-search {
                    width: 56px;
                    color: white;
                }

                .input-icon-close-root, .input-icon-search-root {
                    max-height: unset;
                }

                .input-icon-search-root {
                    background-color: $icon-input-search-background-color;
                    height: 100%;
                    border-radius: 21.5px;
                }

                #input-search-text-provisioning {
                    font-size: 11px;
                    padding-left: 1em !important;
                }
            }
        }
    }
}


.simple-dialog-regularization-purchase-provisioning {
    .MuiDialog-paper {
        #draggable-dialog-title {
            cursor: move;
        }

        .MuiDialogTitle-root {
            background-color: $color-regularization-orange;
            color: white;
        }

        .MuiDialogContent-root {
            padding: 1em !important;

            .transporter-content {
                overflow: auto;
                padding-right: 1em;
                max-height: calc(100vh - 335px);

                .transporter-header {
                    background-color: #b4c7e7;
                    font-weight: 500;
                }

                .k-numerictextbox {
                    height: 40px !important;
                    width: 95%;
                    border-radius: 4px !important;
                    border: 1px solid $gray;
                    background-color: $input-background-color !important;
                    padding-right: unset;

                    input {
                        text-align: center !important;
                    }
                }

                .txt-field-vehicle-remarks {
                    margin-top: unset;
                    margin-bottom: unset;
                    border-radius: 4px;
                    border: 1px solid $gray;
                    background-color: $input-background-color;
                    width: 100%;
                    margin-right: 1em !important;

                    .Mui-disabled:before, .Mui-focused {
                        border-bottom: unset !important;
                    }

                    .MuiInputBase-multiline {
                        padding: unset !important;

                        .MuiInputBase-inputMultiline {
                            padding: unset !important;
                        }
                    }
                }

                .error-message {
                    color: red;
                    font-weight: bold;
                }
            }

            .title-help {
                color: grey;
                font-size: 0.8em;
            }

            .title, .cell {
                text-align: center;
            }
        }
    }

    .MuiDialog-paperWidthSm {
        max-width: unset;
        min-width: 950px;
        max-height: calc(100vh - 110px);
    }
}
