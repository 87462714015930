@import '../../../variables';

.transporterPublishedCosts {
    padding: $pulished-costs-content-padding;

    .transporter {
        padding-left: 10px;
        height: $pulished-costs-transporter-height;
    }

    .calendar {
        height: $pulished-costs-calendar-height;
    }

    .calendar-daterange-picker .k-dateinput {
        background-color: $gray-background-calendar-file;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        width: 48%;
        border: none;

        .k-input-inner {
            padding-left: 0;
            padding-right: 0;
            border-bottom: 1px solid rgba(0,0,0,.54);
        }
    }

    .checkbox-detail {
        margin: 0;
    }

    .total-sum-content {
        background-color: $color-blue-dark;
        border-bottom-left-radius: 5px;
        border-top-left-radius: 5px;
    }

    .total-content {
        background-color: #eff2f4;
    }

    .total-content.border-radius {
        border-bottom-right-radius: 5px;
        border-top-right-radius: 5px;
    }

    .total-sum-content, .total-content {
        width: 150px;
        text-align: center;
        padding: 10px;
    }

    .total-title {
        color: $color-thead;
    }

    .totals-title, .totals-value {
        color: white;
    }

    .total-title, .totals-title {
        font-size: 12px;
        font-family: Roboto;
    }

    .total-value {
        color: $color-blue-dark
    }

    .totals-value, .total-value {
        font-family: Roboto;
        font-size: 18px;
        font-weight: bold;
    }

    .published-costs-list {
        width: 100%;
        min-width: 500px;
        padding: $pulished-costs-list-padding;

        .published-costs-grid {
            width: 100%;

            thead {
                background-color: $header-grid;
                font-size: 12px;
                font-weight: 600;
                letter-spacing: -0.3px;
                line-height: 17px;
            }

            thead tr {
                height: 40px;
            }

            .k-grid-header th {
                font-weight: 500;
                color: #858889;
            }

            tr.k-selected > td {
                background-color: inherit !important;
                color: inherit !important;
            }

            .editable-header {
                cursor: pointer;
            }

            .editable-header-title {
                margin-right: 5px;
            }

            .Removal {
                background-color: $removal-transport-service-kind-color;
            }
            
            .JobsiteVehicle {
                background-color: $jobsitevehicle-transport-service-kind-color;
            }
            
            .Delivery_Perishable {
                background-color: $delivery-perishable-transport-service-kind-color;
            }
            
            .Delivery_Not_Perishable {
                background-color: $delivery-not-perishable-transport-service-kind-color;
            }

            .vehicle-line {
                background-color: #8ec9f8;
            }

            .fees-line {
                background-color: #80808075;
            }
            
            .principal-price-line {
                background-color: white;
            }
            
            .default-line {
                height: 40px;
            }

            .specific-price-kind {
                Width: 20px;
                Height: 20px;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: #FFFFFF;
                border-radius: 25px 25px;
                right: 3px;
                bottom: 23px
            }
        
            .negotiated-price-kind {
                Width: 20px;
                Height: 20px;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: black;
                border-radius: 25px 25px;
                right: 3px;
                bottom: 23px
            }
        
            .specific-price-kind-font {
                color: #000000;
                Font: Open Sans ExtraBold;
                font-size: 14px;
                font-weight: bold;
                text-align: center;
            }
        
            .negotiated-price-kind-font {
                color: white;
                Font: Open Sans ExtraBold;
                font-size: 14px;
                font-weight: bold;
                text-align: center;
            }

            .costs-agreed-checkbox .Mui-checked {
                color: $costs-are_agreed_color;
            }

            .costs-not-agreed-checkbox .MuiCheckbox-root {
                color: $costs-are_not_agreed_color;
                background-color: $costs-are_not_agreed_color;
                border-radius: unset;
                height: 18px;
                width: 18px;
                margin: 0 12px;
                border-radius: 4px;
            }

            .MuiFormControlLabel-label {
                font-size: 10px;
            }

            .costs-checkbox {
                margin: 0;
            }
        }
    }
}
