@import '../../../variables';

.drawer-dc-details{
    z-index: 0;
    width: $drawer-dc-details-width;
    flex-shrink: 0;
    .drawer-dc-details-paper{
        height: 100vh;
        width: $drawer-dc-details-width;
        .content-drawer-details {
            flex-grow: 1;
            height: 95vh;
            font-family: 'Roboto', sans-serif;

            .content-header-drawer-details {
                background-color: $gray;

                .title-details {
                    color: black;
                    font-weight: bold;
                    font-size: 1.2rem !important;
                    letter-spacing: -0.63px;
                }

                .title-dc {
                    color: black;
                    font-weight: bold;
                    font-size: 14px;
                    letter-spacing: -0.63px;
                }

                .container-details {
                    background-color: white;
                    height: calc(95vh - 60px);
                    overflow-y: auto;

                    .column-title {
                        color: $color-thead;
                        font-size: 12px;
                        letter-spacing: -0.38px;
                    }

                    .column-value {
                        font-size: 14px;
                        letter-spacing: -0.44px;
                    }

                    .title-bold-column {
                        font-size: 16px;
                        font-weight: bold;
                        letter-spacing: -0.5px;
                    }

                    .txt-field-flow-complementary-detail {
                        width: 100%;
                    }

                    .icon-pen-blue {
                        color: $icon-pen-blue;
                    }
                }
            }
        }
    }
}