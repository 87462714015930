@import '../../shared/common';
@import '../../variables';

.expeditions-content {
    transition: margin 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
    background-color: $color-white;

    .div-linear-progress {
        height: 8px !important;
        padding-top: 2px;

        .loader-linear-progress {
            background-color: unset;
            height: 8px;

            .MuiLinearProgress-bar {
                background-color: $loader-liner-progress-color;
            }
        }

        .empty-div {
            padding-top: 10px;
        }
    }

    .k-datepicker {
        font-size: 14px;
        font-weight: 500;
        width: 130px;
        border-style: unset;
        flex-direction: row-reverse;

        .k-input-inner {
            text-align: center;
            font-size: 16px;
            padding: 0;
            height: 100%;
            background-color: $input-background-color;
        }

        &.highlighted {
            .k-input-inner {
                background-color: $color-highlighted;
            }
        }

        .k-icon-button {
            color: $icon-datepicker-color;
            font-weight: bold;
            background-color: white;
        }
    }

    .specific-price-kind {
        Width: 15px;
        Height: 15px;
        background-color: #FFFFFF;
        border-radius: 25px 25px;
        position: absolute;
        right: 3px;
        bottom: 23px
    }

    .negotiated-price-kind {
        Width: 15px;
        Height: 15px;
        background-color: black;
        border-radius: 25px 25px;
        position: absolute;
        right: 3px;
        bottom: 23px
    }

    .specific-price-kind-font {
        color: #000000;
        Font: Open Sans ExtraBold;
        font-size: 14px;
        font-weight: bold;
        text-align: center;
    }

    .negotiated-price-kind-font {
        color: white;
        Font: Open Sans ExtraBold;
        font-size: 14px;
        font-weight: bold;
        text-align: center;
    }

    .input-search-vehicles {
        height: 40px !important;
        border-radius: 21.5px;
        background-color: $input-background-color;
        padding-right: unset;

        .icon-search {
            width: 56px;
            color: white;
        }

        .input-icon-close-root,
        .input-icon-search-root {
            max-height: unset;
        }

        .input-icon-search-root {
            background-color: $icon-input-search-background-color;
            height: 100%;
            border-radius: 21.5px;
        }

        #input-search-text {
            font-size: 12px;
            padding-left: 1em !important;
        }
    }

    .btn-track {
        border-top-left-radius: 0%;
        border-top-right-radius: 50%;
        border-bottom-left-radius: 0%;
        border-bottom-right-radius: 50%;
        background-color: $button-track-flow-background-color;

        .btn-icon {
            color: $button-icon-open-drawer-color;
        }
    }

    .btn-actions {
        background-color: $button-track-flow-background-color;

        .btn-icon {
            color: $button-icon-open-drawer-color;
        }

        .Mui-disabled {
            color: gray;
        }
    }

    .btn-flow {
        border-top-left-radius: 50%;
        border-top-right-radius: 0%;
        border-bottom-left-radius: 50%;
        border-bottom-right-radius: 0%;
        background-color: $button-track-flow-background-color;

        .btn-icon {
            color: $button-icon-open-drawer-color;
        }
    }

    .divider-filter {
        background-color: $border-color;
        height: 1px;
    }

    .filter-title {
        color: $filter-color;
        font-size: 14px;
        min-height: 1.5rem;
    }

    .icon-open-filter,
    .div-open-filter {
        margin-right: 0.5rem;
    }

    .div-open-filter {
        border: solid 1px $filter-button-selected;
        border-radius: 4px;
        margin-left: 4.7rem;
        padding: 6px 8px;
        cursor: pointer;
    }

    &.prestation-perishable-selected .Delivery_Perishable,
    &.prestation-not-perishable-selected .Delivery_Not_Perishable,
    &.prestation-removal-selected .Removal,
    &.prestation-jobsite-selected .JobsiteVehicle,
    &.criticity-modulable-selected .criticity-Low,
    &.criticity-day-selected .criticity-Medium,
    &.criticity-fix-selected .criticity-High,
    &.status-needed-selected .status-TransportNeeded,
    &.status-planned-selected .status-TransportPlanned,
    &.status-confirmed-selected .status-TransportConfirmed,
    &.status-inprogress-selected .status-InProgress,
    &.status-finished-selected .status-Finished,
    &.status-canceled-selected .status-Canceled {
        background-image: unset !important;
    }

    &:not(.prestation-perishable-selected):not(.prestation-not-perishable-selected):not(.prestation-removal-selected):not(.prestation-jobsite-selected):not(.criticity-modulable-selected):not(.criticity-day-selected):not(.criticity-fix-selected):not(.status-needed-selected):not(.status-planned-selected):not(.status-confirmed-selected):not(.status-inprogress-selected):not(.status-finished-selected):not(.status-canceled-selected) {
        .event-sch {
            background-image: unset !important;
        }
    }

    .filter-selected {
        background-color: $button-icon-open-drawer-color;
        color: white;

        .icon-open-filter {
            color: white !important;
        }
    }

    .filter-not-selected {
        background-color: white;
        color: $filter-button-selected;

        .icon-open-filter {
            color: $filter-button-selected !important;
        }
    }

    .night-work {
        background: url("data:image/svg+xml;utf8,<svg aria-hidden='true' focusable='false' data-prefix='fas' data-icon='moon' class='svg-inline--fa fa-moon fa-w-16 fa-sm icon-night-work' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' > <path fill='currentColor' d='M283.211 512c78.962 0 151.079-35.925 198.857-94.792 7.068-8.708-.639-21.43-11.562-19.35-124.203 23.654-238.262-71.576-238.262-196.954 0-72.222 38.662-138.635 101.498-174.394 9.686-5.512 7.25-20.197-3.756-22.23A258.156 258.156 0 0 0 283.211 0c-141.309 0-256 114.511-256 256 0 141.309 114.511 256 256 256z'> </path > </svg >") no-repeat;
        height: 15px;
        width: 15px;
    }

    .day-work {
        background: url("data:image/svg+xml;utf8,<svg aria-hidden='true' focusable='false' data-prefix='fas' data-icon='sun' class='svg-inline--fa fa-sun fa-w-16 fa-sm icon-night-work' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'> <path fill='currentColor' d='M256 160c-52.9 0-96 43.1-96 96s43.1 96 96 96 96-43.1 96-96-43.1-96-96-96zm246.4 80.5l-94.7-47.3 33.5-100.4c4.5-13.6-8.4-26.5-21.9-21.9l-100.4 33.5-47.4-94.8c-6.4-12.8-24.6-12.8-31 0l-47.3 94.7L92.7 70.8c-13.6-4.5-26.5 8.4-21.9 21.9l33.5 100.4-94.7 47.4c-12.8 6.4-12.8 24.6 0 31l94.7 47.3-33.5 100.5c-4.5 13.6 8.4 26.5 21.9 21.9l100.4-33.5 47.3 94.7c6.4 12.8 24.6 12.8 31 0l47.3-94.7 100.4 33.5c13.6 4.5 26.5-8.4 21.9-21.9l-33.5-100.4 94.7-47.3c13-6.5 13-24.7.2-31.1zm-155.9 106c-49.9 49.9-131.1 49.9-181 0-49.9-49.9-49.9-131.1 0-181 49.9-49.9 131.1-49.9 181 0 49.9 49.9 49.9 131.1 0 181z'> </path> </svg>") no-repeat;
        height: 15px;
        width: 15px;
    }

    .b-fa.b-fa-sms {
        font-size: 22px;
        cursor: default;
        transform: none;
        cursor: pointer;
    }


    .b-fa.b-fa-sms {
        &.has-notified {
            color: $green-text;
        }

        &.sms-invalid-number {
            color: lightgrey;
        }
    }

    .planning-vehicle_costs-are-not-published {
        color: $costs-are_not_published_color;
    }

    .planning-vehicle_costs-are-published {
        color: $costs-are_published_color;
    }

    .planning-vehicle_costs-are-agreed {
        color: $costs-are_agreed_color;
    }

    .planning-vehicle_costs-are-not-agreed {
        color: $costs-are_not_agreed_color;
    }

    .remarks {
        display: flex;
        flex-direction: row;

        .pen {
            background: url("data:image/svg+xml;utf8,<svg aria-hidden='true' focusable='false' data-prefix='fas' data-icon='pen' class='svg-inline--fa fa-pen fa-w-16 fa-1x' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'><path fill='%2300a3e0' d='M290.74 93.24l128.02 128.02-277.99 277.99-114.14 12.6C11.35 513.54-1.56 500.62.14 485.34l12.7-114.22 277.9-277.88zm207.2-19.06l-60.11-60.11c-18.75-18.75-49.16-18.75-67.91 0l-56.55 56.55 128.02 128.02 56.55-56.55c18.75-18.76 18.75-49.16 0-67.91z'></path></svg>") no-repeat;
            height: 15px;
            width: 15px;
            margin-left: 1em;
        }
    }

    .b-sch-event {
        padding-left: 0;
        border-radius: 0.4em;
        height: 100% !important;

        .b-sch-event-content {
            border-radius: 0.4em;
        }
    }

    .event-sch {
        height: 100%;
        width: 100%;
        background-image: linear-gradient(to right, transparent, white);
        display: flex;
        align-items: center;
    }

    .b-sch-event-wrap {
        height: 39px !important;
        margin-top: 1.8px;
    }

    .b-grid-body-container {
        height: 100% !important;
    }

    .flow-sch {
        font-size: 12px;
        font-weight: 500;
        padding-left: 0.4em;
        color: black;
        width: calc(100% - 0.4em - 5px);
    }

    .flow-mgl {
        margin-left: 30px;
    }

    .trip-sch {
        height: 90%;
        width: 5px;
        display: inline-block;
        border-bottom-left-radius: 0.4em;
        border-top-left-radius: 0.4em;
        margin: 2px;
    }

    .filter-button {
        height: 100%;

        .trip-sch {
            border-bottom-left-radius: 0.4em;
            border-top-left-radius: 0.4em;
            margin-right: 0.4em;
            padding-top: unset;
            padding-bottom: unset;
            padding-left: unset;
        }
    }

    #genericBtn,
    #plannedBtn,
    #confirmedBtn,
    #pendingBtn,
    #finishedBtn,
    #canceledBtn {
        padding-left: unset;
        padding-top: unset;
        padding-bottom: unset;
        height: 100%;

        .MuiButton-label {
            height: 100%;
        }
    }

    .trip-generic {
        //height: unset;
        background-color: $color-white;
    }

    .trip-confirmed {
        background-color: $color-confirmed;
    }

    .trip-planned {
        background-color: $color-planned;
    }

    .trip-inProgress {
        background-color: $color-inprogess;
    }

    .trip-finished {
        background-color: $color-finished;
    }

    .trip-canceled,
    .trip-rejected {
        background-color: $color-rejected;
    }

    #defaultBtn.selected,
    #senderBtn.selected,
    #receiverBtn.selected,
    #beneficiaryBtn.selected,
    #perNightFilterBtn.selected,
    #perDayFilterBtn.selected,
    &.tripNumber-btn-selected #tripNumberBtn,
    &.city-btn-selected #cityBtn,
    &.cch-btn-selected #cchBtn,
    &.product-btn-selected #productBtn,
    &.criticity-modulable-selected #modulableBtn,
    &.criticity-fix-selected #fixBtn,
    &.criticity-day-selected #dayBtn,
    &.status-needed-selected #genericBtn,
    &.status-planned-selected #plannedBtn,
    &.status-confirmed-selected #confirmedBtn,
    &.status-inprogress-selected #pendingBtn,
    &.status-finished-selected #finishedBtn,
    &.status-canceled-selected #canceledBtn {
        color: $title-color !important;
        background-color: $filter-button-selected !important;
    }

    &.prestation-perishable-selected #perissableBtn {
        color: $filter-button-selected !important;
        border: 1px solid #104c5c;
    }

    &.prestation-not-perishable-selected #notPerissableBtn {
        color: $filter-button-selected !important;
        border: 1px solid #0b920b;
    }

    &.prestation-removal-selected #removalBtn {
        color: $filter-button-selected !important;
        border: 1px solid #d8b50c;
    }

    &.prestation-jobsite-selected #jobSiteBtn {
        color: $filter-button-selected !important;
        border: 1px solid #e0926d;
    }

    .vehicle-management-buttons {
        padding: 0.7rem;
        background-color: $gray-background-card;

        #defaultBtn,
        #senderBtn,
        #receiverBtn,
        #beneficiaryBtn,
        #tripNumberBtn,
        #cityBtn,
        #cchBtn,
        #productBtn,
        #modulableBtn,
        #fixBtn,
        #dayBtn,
        #genericBtn,
        #plannedBtn,
        #confirmedBtn,
        #pendingBtn,
        #finishedBtn,
        #canceledBtn,
        #perDayFilterBtn,
        #perNightFilterBtn {
            color: white;
            background-color: $filter-button-not-selected;
            margin-right: .5rem !important;
        }

        #perissableBtn, #notPerissableBtn, #removalBtn, #jobSiteBtn, #perDayFilterBtn, #perNightFilterBtn {
            margin-right: .5rem !important;
        }

        #perissableBtn {
            color: white;
            background-color: $filter-button-perissable-not-selected;
        }

        #notPerissableBtn {
            color: white;
            background-color: $filter-button-not-perissable-not-selected;
        }

        #removalBtn {
            color: white;
            background-color: $filter-button-removal-not-selected;
        }

        #jobSiteBtn {
            color: white;
            background-color: $filter-button-jobSite-not-selected;
        }

        .div-filter-select-all {
            text-align: right;

            .filter-select-all {
                color: $filter-button-selected;
                cursor: pointer;
            }
        }
    }

    .b-grid-row {
        border-bottom: 2px solid grey;

        .b-grid-cell {
            .vehicle-generic {
                color: lightgray;
                font-weight: bold;
            }

            .vehicle-transporter {
                color: black;
            }

            .value-negatif-marge {
                color: red;
            }

            .quantities-used-old {
                color: $color-orange;
            }

            .quantities-used-planned {
                color: $color-gray;
            }

            .quantities-used-delivered {
                color: $color-black;
            }

            .quantities-used-mixed {
                color: $color-mauve;
            }
        }
    }

    .b-group-row {
        background-color: $gray !important;
    }
    //c'est un workaround pour que afficher toute la ligne des sites quand on met la souris dessus
    .b-group-row:hover {
        .b-group-title {
            min-width: inherit !important;
        }
    }

    .b-hover {
        .b-group-title {
            min-width: inherit !important;
        }
    }

    .TransportNeeded {
        background-color: $color-white;
    }

    .TransportPlanned {
        background-color: $color-planned;
    }

    .TransportConfirmed {
        background-color: $color-confirmed;
    }

    .InProgress {
        background-color: $color-inprogess;
    }

    .Finished {
        background-color: $color-finished;
    }

    .Canceled,
    .Rejected {
        background-color: $color-rejected;
    }

    .transport-margin-container {
        background-color: $input-background-color;
        margin-left: 1em;
        border: 1px solid rgba(0, 0, 0, 0.12);
        border-radius: 4px;
        margin-bottom: 2px;

        .divider-title {
            height: 2px;
        }

        .table-title {
            font-size: 0.8em;
            font-weight: bold;
            padding-left: 1em;
            color: $title-color;
        }

        .title {
            font-size: 0.8em;
            color: $title-color;
            padding-left: 1em;
        }

        .value {
            font-size: 1em;
            font-weight: bold;
            float: right;
            padding-right: 1em;
        }

        .value-positif-marge {
            color: $green-text;
        }

        .value-negatif-marge {
            color: red;
        }

        .table-row {
            height: 24px;
            margin-bottom: 2px;
        }
    }

    .b-sch-event-content {
        height: 100%;
        width: 100%;
        margin: 0 !important;
    }
}

.input-search-vehicles {
    height: 40px !important;
    border-radius: 21.5px;
    background-color: $input-background-color;
    padding-right: unset !important;

    .icon-search {
        width: 56px;
        color: white;
    }

    .input-icon-close-root,
    .input-icon-search-root {
        max-height: unset;
    }

    .input-icon-search-root {
        background-color: $icon-input-search-background-color;
        height: 100%;
        border-radius: 21.5px;
    }

    .MuiInput-input {
        font-size: 12px !important;
        padding: 1em !important;
    }
}

.expeditions-content-shiftLeft {
    transition: margin 225ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
    margin-left: $drawer-vehicles-width;
}

.expeditions-content-shiftRight {
    transition: margin 225ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
    margin-right: $drawer-flow-management-width;
}

.Removal {
    background-color: $removal-transport-service-kind-color;
}

.JobsiteVehicle {
    background-color: $jobsitevehicle-transport-service-kind-color;
}

.Delivery_Perishable {
    background-color: $delivery-perishable-transport-service-kind-color;
}

.Delivery_Not_Perishable {
    background-color: $delivery-not-perishable-transport-service-kind-color;
}

.negative-flow,
.hidden-flows {
    border: 3px solid #FF6E00 !important;
    border-radius: 5px;
}

.negative-flow .btn-info:focus {
    box-shadow: none !important
}

.positive-flow {
    border: none !important;
}

.site-label {
    color: #004568;
    font-size: 1rem;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 16px;
}

.line-copy {
    box-sizing: border-box;
    height: 1px;
    border: 1px solid $border-color;
}

.flow-site-resolution-label {
    color: #000000;
    font-family: "Open Sans";
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 19px;
}

.flow-status {
    margin-right: 5px;
    border-radius: 4px;
    padding: 3px;
    font-size: 10px;
    min-width: 65px;
    text-align: center;
    margin-left: 5px;
}

.flow-status.Pending {
    color: $color-black;
    background-color: $color-white !important;
}

.flow-status.Optimized {
    color: $color-white;
    background-color: $color-optimized !important;
}

.flow-status.Planned {
    color: $color-white;
    background-color: $color-plannedFlow-flow !important;
}

.flow-status.Confirmed {
    color: $color-white;
    background-color: $color-confirmed-flow !important;
}

.flow-status.InProgress {
    color: $color-white;
    background-color: $color-inProgress-flow !important;
}

.flow-status.Finished {
    color: $color-white;
    background-color: $color-finished-flow !important;
}

.flow-status.Canceled {
    color: $color-white;
    background-color: $color-canceled !important;
}

.drawer-flow-management {
    z-index: -1;
    width: $drawer-flow-management-width;
    flex-shrink: 0;

    .drawer-flow-management-paper {
        height: 100vh;
        width: $drawer-flow-management-width;
        z-index: 0;

        .content-drawer-flows {
            flex-grow: 1;
            height: 90vh;
            font-family: 'Roboto', sans-serif;
            background-color: $gray;

            .content-header-drawer-flows {
                background-color: $gray;
                height: $content-header-drawer-flows-height;

                .title-flow {
                    color: black;
                    font-weight: bold;
                    font-size: 1.2rem !important;
                    letter-spacing: -0.63px;
                }

                .header-item-section {
                    padding: 15px 8px;

                    .checkbox-filter {
                        padding: 5px;
                    }

                    label {
                        margin-right: 10px;
                        margin-left: -5px;
                    }
                }

                .btn-actions {
                    background-color: $button-track-flow-background-color;

                    .btn-icon {
                        color: $button-icon-open-drawer-color;
                    }

                    .Mui-disabled {
                        color: gray;
                    }
                }
            }

            .container-flows {
                padding: 5px;
                overflow-y: auto;
                height: calc(100% - #{$content-header-drawer-flows-height});

                .flow-content {
                    width: 100%;
                    background-color: white;
                    margin-bottom: $content-drawer-flows-item-margin-bottom;
                    border-radius: 5px;
                    user-select: none;
                    height: $content-drawer-flows-item-height;

                    .drag-icon {
                        align-self: center;
                        background: url('icones/drag-indicator.svg') no-repeat;
                        height: 24px;
                        width: 24px;
                    }

                    .drag {
                        cursor: grabbing;
                    }

                    .input-nb-trips {
                        height: 20px;
                        background: white !important;
                        padding: 0;
                        border-radius: unset !important;

                        .k-input-inner {
                            padding-left: 0 !important;
                            padding-right: 0 !important;
                            text-overflow: unset;
                            text-align: center;
                            height: 100%;
                        }
                    }

                    .oval-filter-icon {
                        background: url('icones/filter-icon.svg') no-repeat;
                    }

                    .oval-icon {
                        Width: 35px;
                        Height: 35px;
                        background-color: $color-white;
                        border-radius: 50%;
                        margin-right: 3px;
                        background-size: 60%;
                        background-position: center;
                        cursor: pointer;
                        justify-content: center;
                    }

                    .oval-icon-disabled {
                        background-color: lightgrey !important;
                        cursor: unset !important;
                    }

                    .oval-icon:hover:not(.oval-icon-disabled) {
                        background-color: #00ffff !important;
                    }

                    .oval-icon.selected {
                        background-color: $search-input-text-active-bgc !important;
                    }

                    .trips-number-forced {
                        cursor: pointer;
                        color: #00ffff;
                    }

                    .trips-number-not-forced {
                        cursor: pointer;
                        color: white !important;
                    }

                    .flow-card-font {
                        height: 17px;
                        color: #858889;
                        font-size: 12px;
                        font-weight: 600;
                        letter-spacing: -0.3px;
                        line-height: 17px;
                    }

                    .flow-card-font-p {
                        height: 16px;
                        color: #004568;
                        font-size: 14px;
                        font-weight: bold;
                        letter-spacing: 0;
                        line-height: 16px;
                    }

                    .flow-card-font-negative-p {
                        height: 16px;
                        font-size: 14px;
                        font-weight: bold;
                        letter-spacing: 0;
                        line-height: 16px;
                        color: #FF6E00 !important;
                    }

                    .table-negotiated-purchase-price td {
                        border: 1px #858889 solid;
                        text-align: center;
                        width: 100px;
                        font-size: 12px;
                        color: #004568;
                        font-weight: 700;
                    }

                    .negotiated-purchase-price-kind-logo {
                        border-radius: 50%;
                        border: 1px solid black;
                        background-color: black;
                        width: 25px;
                        height: 25px;
                        text-align: center;
                        font-weight: 700;
                        color: white;
                    }

                    .first-part {
                        background-color: $gray-background-card;
                        padding: 10px;
                        width: 100%;
                        border-top-left-radius: 4px;
                        border-top-right-radius: 4px;

                        .flow-number {
                            font-size: 18px;
                            font-weight: bold;
                            word-break: break-word;
                        }

                        .Removal,
                        .JobsiteVehicle,
                        .Delivery_Perishable,
                        .Delivery_Not_Perishable {
                            border-radius: 2px;
                            height: 25px;
                        }
                    }

                    .second-part {
                        background-color: white;
                        padding: 10px;
                        width: 100%;
                        border-bottom-left-radius: 4px;
                        border-bottom-right-radius: 4px;

                        .product-type {
                            font-size: 16px;
                            font-weight: bold;
                        }

                        .remaining-type-label {
                            font-size: 18px;
                            font-weight: bold;
                            color: $color-icons-blue-dark;
                        }

                        .remaining-label {
                            font-size: 12px;
                            font-weight: bold;
                            align-self: center;
                        }

                        .unit-label {
                            font-size: 12px;
                            font-weight: normal;
                            color: $unit-label-color;
                            align-self: center;
                        }
                    }
                }

                .flow-content.selected {
                    background-color: $search-input-text-active-bgc;
                }
            }
        }
    }
}

.manage-vehicle {
    .logisticsUnit-select .title-select {
        color: red;
    }

    .vehicle-type-select .title-select {
        color: red
    }

    .number-of-vehicles-input {
        width: 50px;
        margin-left: 5px;
        background: white !important;
        padding: 0;
        border-radius: unset !important;

        .k-input-inner {
            padding-left: 0 !important;
            padding-right: 0 !important;
            text-overflow: unset;
        }
    }

    .error-msg {
        font-size: 12px;
        color: red;
    }
}

.btn-remainning-quantity {
    color: white;
    background-color: #CCEEFF;
    border: none;
    display: inline-block;
    font-weight: bolder;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 0.25rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    cursor: pointer;
    width: 100%;
}

.filter-selected {
    color: #004568 !important;
    background-color: #1DB3DD !important;
}

.btn-remainning-quantity.disabled {
    cursor: default;
    background-color: gray !important;
}

.panel-header-details {
    background-color: white;
    border-bottom: 1px $border-color solid;
    height: 40px;
    margin-bottom: 10px;

    .header-title {
        color: black;
        font-size: 16px !important;
        font-weight: 500;

        .finished-trip-by-foreman {
            font-size: 0.8em;
            color: $color-finished;
        }
    }
}

.trip-detail {
    .txt-field-flow-complementary-detail {
        margin-top: 8px;
        border-radius: 4px;
        border: 1px solid $gray;
        background-color: $input-background-color;
        width: 100%;
        margin-right: 1em !important;

        .Mui-disabled:before,
        .Mui-focused {
            border-bottom: unset !important;
        }

        &.disabled {
            .MuiInputBase-input {
                color: $color-disabled;
            }
        }
    }

    .alert-message {
        height: 20px;
        display: flex;
        align-items: center;
        flex-direction: row;

        .icon-alert {
            color: red;
            padding-right: 0.5em;
        }
    }

    .icon-pen-disabled {
        color: gray !important;
    }

    .planned-quantity {
        cursor: pointer;
    }

    .input-planned-quantity,
    .planned-quantity {
        height: 30px !important;
    }

    .input-planned-quantity {
        color: $color-light-blue;
    }
}

.sms-notifications-dialog {

    .MuiDialog-paper {
        max-width: none;
        width: 900px;
        height: 80vh;
    }

    .dialog-close-button {
        padding: 0 10px;
    }

    .sms-notifications-content {
        width: 100%;
        height: 100%;

        .MuiInputAdornment-positionEnd {
            margin-right: 9px;
        }

        .search-text {
            align-items: center;
            height: 50px;

            .search-text-area {
                height: 50px;

                textarea {
                    height: unset !important;
                }
            }
        }

        .drivers-label-filter {
            margin-bottom: 0px;

            .MuiCheckbox-root {
                padding-top: 0px;
                padding-bottom: 0px;
            }

            .MuiIconButton-label {
                padding-right: 0.5em;
            }

            .MuiIconButton-root {
                padding: unset;
            }
        }

        .driver-sms-notification {
            padding: 10px 0;
            border-bottom: 1px solid $dialog-transport-flow-input-text-border-color;

            .fa-user-driver-internal {
                color: $icon-user-color;
            }

            .fa-user-driver-temporary {
                color: black;
            }

            .driver-name {
                margin-bottom: unset;
            }
        }

        .notifications-list {
            overflow: auto;
            margin-top: 20px;
            padding-right: 10px;
        }

        .driver-name > span {
            font-weight: bold;
        }

        .sms-counter {
            color: $snackbar-background-success
        }

        .sms-length-counter {
            font-weight: bold;

            &.error {
                color: red;
            }
        }

        .sms-text-input {
            width: 100%;
            height: 244px !important;
            resize: none;
            border: 1px solid $dialog-transport-flow-input-text-border-color;
            border-radius: 4px;

            &.error {
                border: 1px solid red;
            }
        }

        .sms-text-input:focus {
            outline: 0;
        }
    }
}

.update-specificPrice-deliveryTrips {
    .MuiDialog-paper {
        max-width: none;
        width: 800px;

        .select-trip-details {
            align-items: center;
            background-color: rgba(0, 0, 0, 0);
            border: none;
            color: rgb(0, 0, 0);
            cursor: pointer;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            height: 30px;
            margin: 0px;
            overflow-wrap: normal;
            overflow: visible;
            padding: 7px;
            width: 177px;
            margin-top: 5px;
            border-bottom: 1px solid gray;
            outline-color: transparent;
        }

        .text-orange {
            color: $color-orange;
        }
    }
}

.attach-zephyr-flow-manually {
    .MuiDialog-paper {
        max-width: none;
        min-width: 800px;
        height: 80vh;
    }

    .dialog-close-button {
        padding: 0 10px;
    }

    .simple-dialog-content {
        padding: 24px 24px 0;
        height: 100%;
    }
}

.orphan-delivery-notes-buttons {
    height: 58px;

    .cancel {
        background-color: #f1127a !important;
    }
}

.orphan-delivery-notes-grid {
    width: 100%;

    .k-grid td,
    .k-grid th {
        font-family: Roboto;
    }

    .k-grid th {
        color: $color-thead;
    }

    .k-checkbox-label {
        min-height: 24px;
        min-width: 28px;
        margin-left: 2px;
        margin-top: 3px;
    }

    thead {
        background-color: $gray-background-card;

        .k-filter-row {
            display: none;
        }

        .k-cell-inner {
            margin-block: unset !important;
            margin-inline: unset !important;
        }

        .k-link {
            padding-block: 8px !important;
            padding-inline: 10px !important;
        }

        .k-grid-header-menu {
            margin-inline-end: 0 !important;
        }

        .k-filterable-active {
            .k-grid-column-menu, .k-column-title {
                color: #1db3dd;
            }
        }

        .k-column-resizer {
            width: 2px;
            background: rgba(0, 0, 0, 0.12);
        }
    }

    thead tr {
        height: 40px;
    }

    .delivery-notes-grid {
        width: 100%;
    }

    .warning-icon-cell {
        color: $grid-referential-internal-vehicle-difference-data-warning-color !important;
        text-align: center !important;
    }

    .field-changed {
        background-color: $field-td-diff-background-color !important;
        color: black !important;
    }
}

.kendo-grid-filter-menu-container {
    .k-item {
        padding-block: 2px !important;
    }
}

.driver-sms-notifications-dialog {
    .MuiDialog-paper {
        max-width: none;
        width: 800px;
        height: 80vh;
    }

    .dialog-close-button {
        padding: 0 10px;
    }

    .simple-dialog-content {
        padding: 24px 24px 0;
        height: calc(100% - 90px);
    }

    .driver-notifications-content {
        .sms-notifications-content {
            height: 100%;
            overflow: auto;
            padding-right: 5px;
        }

        .sms-content {
            word-break: break-word;
            margin-left: 50%;
            margin-bottom: 10px;
            padding: 10px;
            border-radius: 4px;
            background-color: $sms-content-color;
            color: white;
            font-size: 12px;
        }

        .sms-text-input {
            width: 100%;
            resize: none;
            border: 1px solid $dialog-transport-flow-input-text-border-color;
            border-radius: 4px;

            &.error {
                border: 1px solid red;
            }
        }

        .sms-text-input:focus {
            outline: 0;
        }

        .sms-length-counter {
            font-weight: bold;

            &.error {
                color: red;
            }
        }

        .send-section {
            padding: 20px 10px 0;
            border-top: 1px solid $dialog-transport-flow-input-text-border-color;
        }
    }
}

.cancel-planning-vehicle-dialog {
    .cancel-planning-vehicle-content {
        .cancel-remarks {
            margin: 0 20px;
            width: 300px;
        }

        .btn-save {
            margin-left: 15px;
        }
    }
}

.b-float-root {
    z-index: unset !important;
}
