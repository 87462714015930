﻿@import '../variables';

.MuiInputBase-input {
    padding: 0.5rem !important;
}

.MuiInputBase-input::-ms-clear {
    display: none;
}

.MuiInputBase-adornedEnd, .MuiInputBase-adornedStart {
    background-color: white;
    border: 1px solid $gray;
    padding-right: 0.5rem;
    width:100%;
}

.MuiInputAdornment-positionEnd {
    cursor: pointer;
}

.input-search-vehicles, .search-text-vehicle-grid, .search-text-drawer, .search-text-Flow {
    width: 100%;
}

.input-search-vehicles {
    height: 50px;
}

.search-text-active {
    background-color: $search-input-text-active-bgc !important;
}

.default-width{
    width: auto;
}