@import '../../../variables';
@import '../../../shared/common';

.transporter-main-content {
    padding-right: 450px;

    @media (min-width: 1715px) {
        .div-transporter-cards {
            width: calc(100% - 1.3em - 450px) !important;
        }
    }

    .transporter-card-content {
        @media (max-width: 1500px) {
            min-width: 300px !important;
        }
    }
}

.transporter {
    .tr-section {
        border: 1px solid $gray;
    }

    .transporter-button {
        margin-left: 1em;
        width: 190px;
        color: white;
        border-radius: 0.125em !important;
        text-transform: none !important;
        background-color: $color-light-blue !important;
    }

    .important-information {
        font-weight: bold;
        color: red;
    }

    .transporter-header {
        .title-field {
            font-size: 0.8em;
            color: $color-thead;
        }

        .value-field {
            font-size: 0.9em;
        }

        .transporter-select {
            width: 270px;
        }

        .k-datepicker {
            padding-left: 0.5rem;
            padding-right: 0.5rem;
            background-color: $gray-background-calendar-file;
            flex-direction: row-reverse;
            width: 165px;
            padding-top: 3px;
            padding-bottom: 3px;

            .k-input-inner {
                padding-right: 1.5em;
                padding-top: 0;
                padding-bottom: 0;
                padding-left: 0;
            }

            .k-icon-button {
                padding-right: 16px;
                color: rgba(0, 0, 0, .54);
            }
        }

        .not-confirmed-canceled-vehicle-number {
            color: $canceled-vehicle-color;
        }
    }

    .cancelation-note {
        color: $canceled-vehicle-color;
    }

    .mt-8 {
        margin-top: 8px;
    }

    .transporter-card-content {
        padding: 0px !important;

        .canceled-label {
            color: $canceled-vehicle-color;
            margin-right: 10px;
        }

        .transporter-title-card {
            background-color: $gray-background-calendar-file;

            .transporter-card-content-vehicle-title {
                font-size: 1.2em;
                font-weight: bold;

                .icon-night-work {
                    margin-left: 1em;
                    margin-right: 1em;
                    color: $color-icons-blue-dark;
                }
            }

            .transporter-card-content-vehicle-type-title {
                font-size: 1em;
                font-weight: 500;
            }

            .confirmation-date,
            .total-fees {
                font-size: 0.8em;
                color: $color-thead;
                margin-right: 1em;
            }

            .confirmation-canceled-date {
                font-size: 0.8em;
                color: $canceled-vehicle-color;
                margin-right: 1em;
            }

            .confirmation-date,
            .confirmation-canceled-date {
                margin-left: 5px;
            }

            .vehicle-number-id {
                font-size: 0.8em;
                padding-right: 8px;
                text-align: center;
            }

            .main-price-kind-ending-letters {
                font-size: 0.6em !important;
            }

            .main-price-kind-first-letter {
                margin-left: 0.5em;
                font-weight: bold;
                font-size: 1.2em;
            }

            .contract-number {
                margin-left: 0.5em;
                color: $color-contracted;
            }
        }

        .transporter-sub-card-vehicle-type {
            margin: 1em;
            max-width: 90rem;
        }

        .control-box {
            background-color: $blue-light-background;
            padding-left: 0.5em;
            padding-right: 0.5em;
            padding-bottom: 0.5em;

            .form-control {
                background-color: $blue-light-background;
                font-weight: bold;
            }
        }

        label {
            margin: 0px !important;
            font-size: 0.8em;
            color: $color-thead;
        }

        .transporter-dc-expansionPanel {
            padding-bottom: 1em;

            .transporter-dc-expansionPanel-summary {
                height: 1.5em;
                min-height: 1em;
                padding-top: 0.5em;

                .nb-vehicles {
                    margin-right: 1em;
                    font-size: 1.1em;
                    font-weight: bold;
                    color: $color-icons-blue-dark;
                }
            }

            .transporter-dc-expansionPanel-details {
                min-width: 280px;
                padding-left: 1em;

                .trip-order-title {
                    font-weight: 500;
                    padding-right: 0.5em;
                    min-width: 150px;
                }

                .trip-order-card {
                    color: white;
                    background-color: $color-blue-dark;
                    margin-right: 0.5em;
                    border: 1px solid;
                    border-radius: 0.4em !important;
                    width: 200px;

                    .trip-number {
                        margin-left: 0.2em;
                        font-size: 1em;
                    }

                    .trip-label {
                        font-size: 0.8em;
                        width: 100%;
                    }
                }

                .transporter-dc-card {
                    margin-right: 0.5em;
                    margin-bottom: 0.5em;

                    .transporter-card-content {
                        background-color: $blue-light-background;

                        @media (min-width: 1001px) {
                            min-width: 900px;
                        }

                        @media (min-width: 701px) and (max-width: 1000px) {
                            min-width: 600px;
                        }

                        @media (max-width: 700px) {
                            min-width: 250px;

                            .transporter-dc-details {
                                width: unset !important;
                            }
                        }

                        height: 100%;

                        .job-foreman {
                            font-weight: bold;
                        }

                        .transporter-card-content-vehicle-type-title {
                            font-size: 1em;
                            font-weight: bold;
                        }

                        .title-field {
                            font-size: 0.8em;
                            color: $color-thead;
                            margin-right: 1em;
                        }

                        .value-field {
                            font-size: 0.9em;
                            font-weight: bold;
                        }

                        .phone-number {
                            color: $color-light-blue;

                            .icon-phone-number {
                                padding-left: 0.2em;
                            }
                        }
                    }
                }
            }
        }

        .attachment-name {
            color: $blue;
            cursor: pointer;
            text-decoration: underline;
        }
    }

    .drawer-title {
        color: black;
        font-weight: bold;
        font-size: 1.2rem !important;
        letter-spacing: -0.63px;
    }

    .confirmation-panel {
        background-color: white;
        margin-left: -$margin-content-size;
        margin-right: -$margin-content-size;

        .container-buttons-confirmation {
            @media (max-width: 700px) {
                width: 55% !important;
            }

            @media (min-width: 701px) {
                width: 40% !important;
            }
        }

        .txt-paragraph {
            @media (max-width: 700px) {
                width: 45% !important;
            }

            @media (min-width: 701px) {
                width: 60% !important;
            }
        }

        .gdpr-cancelation-msg-div {
            justify-content: space-between;
        }

        .cancelation-msg-div {
            justify-content: flex-end;
        }
    }

    .icon-print,
    .icon-file-pdf,
    .txt-imprimer {
        color: $color-light-blue;
    }

    .txt-imprimer {
        margin-top: 0.3em;
        padding-left: 2em;
        font-size: 0.8em;
    }

    .txt-paragraph {
        font-weight: 500;
    }
}

.add-update-vehicle {
    .input-vehicle {
        width: 80%;
    }

    .input-vehicle>div>input {
        padding: 10.5px 10px;
    }

    .input-vehicle>label {
        top: -8px
    }

    .error-msg {
        margin-bottom: 10px;
        margin-top: -18px;
        font-size: 12px;
        color: red;
        width: 80%;
    }

    .vt-select-search {
        margin-bottom: 20px;
        padding: 0px;
        width: 80%;
        z-index: 99999;
        color: rgba(0, 0, 0, 0.87);
    }
}

.add-update-driver {
    .input-driver {
        width: 80%;
    }

    .input-driver>div>input {
        padding: 10.5px 10px;
    }

    .input-driver>label {
        top: -8px
    }
}

.transporter-drawer-vehicles-drivers {
    z-index: 0;

    @media (max-width: 900px) {
        width: calc(#{$drawer-vehicles-drivers-width} + 60px);
    }

    @media (min-width: 901) {
        width: $drawer-vehicles-drivers-width;
    }

    width: max-content;
    flex-shrink: 0;

    .transporter-drawer-paper {
        height: '100vh';

        /*@media (max-width: 900px) {
            width: calc(#{$drawer-vehicles-drivers-width} + 60px);
        }*/
        @media (min-width: 421px) {
            width: auto;
        }

        @media (max-width: 420px) {
            width: 100%;
        }

        .TitleSectionSelectVehiclesToShow {
            @media (min-width: 421px) {
                width: calc(#{$drawer-vehicles-drivers-width} + 60px);
            }

            @media (max-width: 420px) {
                width: 100%;
            }
        }

    }
}

.container-transporter {
    height: 100%;
    min-width: 320px;

    @media (max-width: 800px) {
        font-size: 0.9em;
    }

    transition: margin 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;

    .text-confirmation-command {
        @media (min-width: 1260px) {
            margin-right: 0.5em;
        }
    }

    .div-transporter-cards {
        margin-top: 1em;

        @media (min-width: 1847px) {
            margin-right: 0.5em;
            position: fixed;
            overflow: auto;
            height: calc(100% - 285px);
            width: calc(100% - 1.3em);

            &.external-cards {
                top: 265px;
            }

            &.internal-cards {
                top: 220px;
            }
        }

        @media (min-width: 1260) and (max-width: 1846px) {
            margin-right: 0.5em;
            top: 270px;
            position: fixed;
            overflow: auto;
            height: calc(100% - 270px);
            width: calc(100% - 1.3em);

            &.external-cards {
                top: 270px;
            }

            &.internal-cards {
                top: 225px;
            }
        }

        .transporter-cards {
            @media (min-width: 1260px) {
                margin-right: 0.5em;
            }

            padding-bottom: 1em;

            .expansion-panel-logistics-unit {
                margin: 0em !important;
            }
        }
    }

    .agency-nb-camion-title {
        font-weight: bold;
        font-size: 1.2em;
        line-height: normal;
    }

    .transporter-vehicles-cards {
        width: 100%;
    }

    .gdpr-text {
        font-size: 0.9em !important;
        color: black;
    }

    .gdpr-text-approuved {
        font-size: 0.8em !important;
        color: gray;
        padding-bottom: 0.5em;
    }

    .gdpr-link {
        text-decoration: underline;
        vertical-align: initial !important;
    }

    .gdpr-control {
        margin-bottom: unset !important;
    }

    .separator-left {
        padding-left: 1em;
    }
}

.highlighted {
    background-color: $color-highlighted;
}