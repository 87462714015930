@import '../../../_variables.scss';

.generic-purchase-prices-referential-view {
    .title-section {
        padding: $referencial-generic-purchase-prices-title-section-padding-top-left-right $referencial-generic-purchase-prices-title-section-padding-top-left-right 0 $referencial-generic-purchase-prices-title-section-padding-top-left-right;
        height: $referencial-generic-purchase-prices-title-section-height;
    }

    .separator {
        height: 1px;
        background-color: $header-menu-background-color;
        margin-top: unset;
        margin-bottom: unset;
        padding-right: 2em;
        padding-left: 2em;
    }

    .cell-right {
        text-align: right;
    }

    .content-section {
        padding: $referencial-generic-purchase-prices-content-padding;

        .error-message {
            color: red;
            font-weight: 500;
            padding-left: 30px;
        }
    }

    .header-content {
        padding: 0 30px 0 0;
        height: $referencial-generic-purchase-prices-header-content-height;

        .logistics-unit-select,
        .grids-list {
            width: 370px;

            div {
                z-index: 10;
            }
        }

        .btn-icon-pricing-rules {
            background-color: #CCEEFF;
        }

        .btn-icon-pricing-rules-disabled {
            background-color: lightgrey;
        }
    }

    .grid {
        width: 100%;

        .k-grid td,
        .k-grid th {
            font-family: Roboto;
        }

        .k-grid th {
            color: $color-thead;
        }

        .k-grid-header th {
            font-weight: 500;
            color: $color-disabled;
        }

        thead {
            background-color: #F0F4F6;
            height: 17px;
            font-size: 12px;
            font-weight: 600;
            letter-spacing: -0.3px;
            line-height: 17px;

            tr {
                height: 40px;
            }
        }

        .k-grid-content {
            overflow-y: auto;

            .field-changed {
                background-color: $field-td-diff-background-color;
            }
        }
    }
}