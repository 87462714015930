﻿$gray: #e6e6e6;
$gray-background-page: #e5eaee;
$gray-background-calendar-file: #eff2f4;
$blue: #6098f2;
$blue-disabled: #cbdcf8;
$gray-background-card: #fafafa;

$color-generic: #1b5eaa;
$color-transporter: #ef426f;
$color-internal: #00b2a9;
$color-dailyVehicle: #994878;
$color-contracted: orange;

$color-thead: #686868;
$blue-label-reservation: #08295e;

$icon-pen-blue: #00a3e0;
$color-light-blue: #00a3e0;

$search-input-text-active-bgc: #FFFF66;
$light-blue: #ECF8FD;
$primary: #38BFE5;
$blue-light-background: #ecf8fd;

$gray-light-border: #e0e0e0;
$color-finished: #4FBA2B;
$color-optimized: rgb(85, 16, 81);
$color-canceled: #D76137;
$color-needed: #38bfe5;
$color-planned: #707070;
$color-confirmed: #1561BE;
$color-inprogess: #FDAB01;
$color-delayed: #ff0000;
$color-rejected: #D76137;
$color-pending: #f4981d;
$color-validated: #1561BE;
$color-published: rgba(0, 112, 255, 0.66);
$color-inProgress-transportRequest: #FDAB01;
$text-color-status: #FFFFFF;
$text-color-status-pending: #000000;
$text-color-filtered: darkblue;
$color-confirmed-flow: #1561BE;
$color-inProgress-flow: #FDAB01;
$color-finished-flow: #4FBA2B;
$color-plannedFlow-flow: #707070;

$color-highlighted: #FFD400;

$color-black: #000000;
$color-white: #ffffff;
$color-gray: #c5c5c5;
$color-card-content-summary: #fafafa;
$color-expansion-panel-details-border: #E9EBEE;
$color-price-positive: #69db7cb3;
$color-price-negative: #ec23238f;

$color-row-light-blue: #8ec9f8ab;
$color-row-grey: #80808075;
$color-row-orange-light: #fff2cc;

$finished-checkbox-green: #008000;
$finished-checkbox-black: #000000;

$loading-card-font-phone_color: #6098f2;
$loading-card-font-color: #686868;

$snackbar-background-success: #43a047;
$snackbar-background-warning: #ffa000;
$snackbar-background-error: #d32f2f;
$snackbar-background-info: #3f51b5;
$border-grid-color: rgba(0, 0, 0, 0.12);

$margin-content-size: 15px;

$color-icons-blue-dark: #004466;

$header-dc-card-height: 85px;
$header-dc-trip-height: 40px;

$bg-c-rectangle-color: #eae8e8;

$texte-date-color: #433c35;

$drawer-dc-details-width: 370px;
$drawer-vehicles-width: 445px;
$drawer-flow-management-width: 490px;
$drawer-alertes-width: 500px;
$drawer-vehicles-drivers-width: 425px;
$drawer-margin-width: 500px;
$drawer-transporter-margin-width: 350px;
$drawer-lot-width: 450px;
$drawer-cm-width: 300px;

$header-pt-card-header: 50px;

$removal-transport-service-kind-color: #FFF2B3;
$jobsitevehicle-transport-service-kind-color: #E4D5CE;
$delivery-perishable-transport-service-kind-color: #a3e4ea;
$delivery-not-perishable-transport-service-kind-color: #8FDB8F;
$unit-label-color: #686868;
$green-text: #4FBA2B;

$dialog-transport-flow-title-color: #004568;
$dialog-header-border-color: #BDCFD8;
$dialog-required-fields-alert-color: #FFD400;
$dialog-transport-flow-section-border-bottom: #F0F4F6;
$dialog-transport-flow-enabled-button-bgc: #CCEEFF;
$dialog-transport-flow-enabled-button-color: #1DB3DD;
$dialog-transport-flow-disabled-button-color: #ebebe4;
$dialog-transport-flow-label-color: #858889;
$dialog-transport-flow-error-label-color: #FF6E00;
$dialog-transport-flow-warning-label-color: #ffc107;
$dialog-transport-flow-input-text-border-color: #ced4da;
$dialog-transport-flow-arrival-time-fixed: #1DB3DD;

$button-open-drawer-backgroud-color: #FFD400;
$button-icon-open-drawer-color: #004568;
$border-color: #BDCFD8;
$filter-color: #858889;
$filter-button-not-selected: #CCEEFF;
$filter-button-selected: #1DB3DD;

$filter-button-perissable-not-selected: #a3e4ea;
$filter-button-not-perissable-not-selected: #8FDB8F;
$filter-button-removal-not-selected: #FFF2B3;
$filter-button-jobSite-not-selected: #E4D5CE;

$header-menu-background-color: #004568;
$header-menu-item-selected-color: #FFD400;
$header-menu-background-transport-agencies: #F0F4F6;
$header-grid: #F0F4F6;

$button-track-flow-background-color: #FFD400;

$input-background-color: #F0F4F6;
$icon-input-search-background-color: #004568;

$icon-datepicker-color: #1DB3DD;

$title-color: #004568;

$icon-phone-number-color: #1DB3DD;

$icon-camera-color: #1DB3DD;

$icon-user-color: #FFD400;

$information-message-color: #1DB3DD;

$map-marker-color: #2272B9;
$map-marker-text-color: #FFFFFF;

$color-nb-todo-invoice: #ef426f;

$color-disabled: #858889;

$sms-content-color: #3f51b5;

$dialog-label-color: #858889;
$dialog-required-label-color: #FF6E00;

$header-menu-height: 67px;
$view-padding: 5px;

$referencial-view-title-height: 30px;
$referencial-header-content-height: 55px;
$referencial-header-content-margin-bottom: 10px;

$vehicle-header-content-height: 76px;
$vehicle-header-content-padding-top-bottom: 10px;

$transport-sales-drawer-margin-title: 69px;
$visualisation-popup-title-height: 65px;
$visualisation-btn-copy-height: 36px;
$visualisation-btn-copy-margin-bottom: 10px;
$visualisation-visualisation-popup-content-padding-top-bottom: 8px;

$pulished-costs-transporter-height: 50px;
$pulished-costs-calendar-height: 50px;
$pulished-costs-list-padding: 10px;
$pulished-costs-content-padding: 15px;

$invoices-Details-content-padding: 15px;
$invoices-Details-beneficiary-height: 50px;
$invoices-Details-calendar-height: 50px;
$invoices-Details-input-search-height: 50px;
$invoices-Details-list-padding: 10px;
$invoices-Details-list-buttons: 132px;
$referential-diesel-indexes-header-height: 84px;
$referential-diesel-indexes-header-grid-height: 86px;
$referential-diesel-indexes-header-title: 40px;
$referential-diesel-indexes-header-padding: 10px;
$referential-diesel-indexes-content-header-height: 85px;
$referential-diesel-indexes-content-header-padding: 16px;

$referential-overhead-expense-header-title: 57px;

$purchase-diesel-indexes-header-title: 40px;
$purchase-diesel-indexes-header-padding: 10px;
$purchase-provisionings-header-padding: 10px;
$purchase-provisioning-header-filter-height: 207px;

$content-header-drawer-vehicle-height: 279px;
$content-header-drawer-vehicle-padding: 15px;

$content-header-drawer-flows-height: 198px;
$content-drawer-flows-item-height: 230;
$content-drawer-flows-item-margin-bottom: 5;

$vehicles-selector-list-width: 430px;
$flows-selector-list-width: 480px;
$flows-selector-item-size: $content-drawer-flows-item-height + $content-drawer-flows-item-margin-bottom;

$referencial-negotiated-sell-prices-content-padding-top-bottom: 20px;
$referencial-negotiated-sell-prices-title-section-padding-top-left-right: 16px;
$referencial-negotiated-sell-prices-title-section-height: 50px;
$referencial-negotiated-sell-prices-header-content-height: 40px;
$referencial-dispatcher-agencies-header-margin-bottom: 15px;
$referencial-dispatcher-agencies-header-height: 30px;
$referencial-negotiated-purchase-prices-content-padding-top-bottom: 20px;
$referencial-negotiated-purchase-prices-title-section-padding-top-left-right: 16px;
$referencial-negotiated-purchase-prices-title-section-height: 50px;
$referencial-negotiated-purchase-prices-header-content-height: 40px;
$transport-sales-margin-title-padding-top: 68px;
$transport-sales-margin-grid-header-height: 24px;
$transport-sales-margin-grid-footer-height: 29px;
$transport-sales-margin-grid-section-padding-top-left-right: 16px;
$referencial-quotation-prices-content-padding-top-bottom: 20px;
$referencial-quotation-prices-title-section-padding-top-left-right: 16px;
$referencial-quotation-prices-title-section-height: 50px;
$referencial-quotation-prices-header-content-height: 40px;

$referencial-generic-purchase-prices-title-section-padding-top-left-right: 16px;
$referencial-generic-purchase-prices-title-section-height: 50px;
$referencial-generic-purchase-prices-content-padding-top-bottom: 20px;
$referencial-generic-purchase-prices-content-padding: 20px 7px 0px 7px;
$referencial-generic-purchase-prices-header-content-height: 40px;

$referencial-grid-offset-from-window-top: $header-menu-height + $referencial-view-title-height + $referencial-header-content-height + $referencial-header-content-margin-bottom + ($view-padding * 2);
$vehicle-grid-offset-from-window-top: $header-menu-height + $vehicle-header-content-height + $vehicle-header-content-padding-top-bottom;
$visualisation-grid-offset-from-window-top: $visualisation-popup-title-height + $visualisation-btn-copy-height + $visualisation-btn-copy-margin-bottom + ($visualisation-visualisation-popup-content-padding-top-bottom * 2);
$transport-sales-drawer-margin-grid-offset-from-window-top: $header-menu-height + $transport-sales-drawer-margin-title + ($visualisation-visualisation-popup-content-padding-top-bottom * 3);
$published-grid-offset-from-window-top: $header-menu-height + $pulished-costs-transporter-height + $pulished-costs-transporter-height + ($pulished-costs-list-padding * 2) + ($pulished-costs-content-padding * 2);
$invoices-Details-grid-offset-from-window-top: $header-menu-height + $invoices-Details-beneficiary-height + $invoices-Details-list-buttons + ($invoices-Details-list-padding * 2) + ($invoices-Details-content-padding * 2);
$beneficiary-grid-offset-from-window-top: $header-menu-height + $invoices-Details-beneficiary-height + $invoices-Details-calendar-height + ($invoices-Details-list-padding * 2) + ($invoices-Details-content-padding * 2);
$diesel-indexes-grid-offset-from-window-top: $header-menu-height + $referential-diesel-indexes-header-title + $referential-diesel-indexes-header-height + ($referential-diesel-indexes-header-padding * 3) + $referential-diesel-indexes-header-grid-height;
$overhead-expense-grid-offset-from-window-top: $header-menu-height + $referential-overhead-expense-header-title + ($referential-diesel-indexes-header-padding * 2) + $referential-diesel-indexes-header-grid-height;
$purchase-provisionings-grid-offset-from-window-top: $header-menu-height + $purchase-provisioning-header-filter-height + ($purchase-provisionings-header-padding * 2);
$referencial-negotiated-sell-prices-grid-offset-from-window-top: $header-menu-height + $referencial-negotiated-sell-prices-title-section-height + $referencial-negotiated-sell-prices-title-section-padding-top-left-right + $referencial-negotiated-sell-prices-header-content-height + ($referencial-negotiated-sell-prices-content-padding-top-bottom * 4);
$referencial-negotiated-purchase-prices-grid-offset-from-window-top: $header-menu-height + $referencial-negotiated-purchase-prices-title-section-height + $referencial-negotiated-purchase-prices-title-section-padding-top-left-right + $referencial-negotiated-purchase-prices-header-content-height + ($referencial-negotiated-purchase-prices-content-padding-top-bottom * 4);
$referencial-dispatchers-agencies-grid-offset-from-window-top: $referencial-grid-offset-from-window-top + $referencial-dispatcher-agencies-header-margin-bottom + $referencial-dispatcher-agencies-header-height;
$transport-sales-margin-grid-container-height: $header-menu-height + $transport-sales-margin-title-padding-top + $transport-sales-margin-grid-header-height + $transport-sales-margin-grid-footer-height + ($transport-sales-margin-grid-section-padding-top-left-right * 2);
$referencial-quotation-prices-grid-offset-from-window-top: $header-menu-height + $referencial-quotation-prices-title-section-height + $referencial-quotation-prices-title-section-padding-top-left-right + $referencial-quotation-prices-header-content-height + ($referencial-quotation-prices-content-padding-top-bottom * 4);
$referencial-generic-purchase-prices-grid-offset-from-window-top: $header-menu-height + $referencial-generic-purchase-prices-title-section-height + $referencial-generic-purchase-prices-title-section-padding-top-left-right + $referencial-generic-purchase-prices-header-content-height + ($referencial-generic-purchase-prices-content-padding-top-bottom * 4);
$attach-zephyr-flow-manually-grid-from-window-top: 58px + 65px + 30px;
$costs-are_agreed_color: #43a047;
$costs-are_not_agreed_color: #d32f2f;
$costs-are_not_published_color: #ced4da;
$costs-are_published_color: #FF6E00;

$cartography-content-header-height: 161px;
$cartography-content-backgroundColor: white;
$cartography-btn-inactive-color: #CCEEFF;
$cartography-btn-active-color: #1DB3DD;
$cartography-details-drawer-width: 450px;
$cartography-details-header-height: 50px;
$cartography-details-header-background-color: #e6e6e6;
$cartography-details-title-color: #686868;
$cartography-eye-icon-width: 50px;
$cartography-selected-marker-background-color: #1edd1e;
$cartography-delivery-color: #303f9f;
$cartography-loading-color: orange;
$cartography-matches-search-text-point-border-bottom-color: #1DB3DD;
$cartography-service-kind-button-selected-border-color: #104c5c;
$cartography-priority-button-selected-background-color: #004568;
$cartography-vehicle-type-filter-width: 370px;
$cartography-input-search-text-width: 655px;

:export {
    referencialGridOffsetFromWindowTop: $referencial-grid-offset-from-window-top;
    vehicleGridOffsetFromWindowTop: $vehicle-grid-offset-from-window-top;
    visualisationGridOffsetFromWindowTop: $visualisation-grid-offset-from-window-top;
    transportSalesDrawerMarginGridOffsetFromWindowTop: $transport-sales-drawer-margin-grid-offset-from-window-top;
    publishedGridOffsetFromWindowTop: $published-grid-offset-from-window-top;
    invoicesDetailsGridOffsetFromWindowTop: $invoices-Details-grid-offset-from-window-top;
    beneficiaryGridOffsetFromWindowTop: $beneficiary-grid-offset-from-window-top;
    dieselIndexesGridOffsetFromWindowTop: $diesel-indexes-grid-offset-from-window-top;
    overheadExpenseGridOffsetFromWindowTop: $overhead-expense-grid-offset-from-window-top;
    purchaseProvisioningsGridOffsetFromWindowTop: $purchase-provisionings-grid-offset-from-window-top;
    referencialNegotiatedSellPricesGridOffsetFromWindowTop: $referencial-negotiated-sell-prices-grid-offset-from-window-top;
    referencialNegotiatedPurchasePricesGridOffsetFromWindowTop: $referencial-negotiated-purchase-prices-grid-offset-from-window-top;
    referencialDispatchersAgenciesGridOffsetFromWindowTop: $referencial-dispatchers-agencies-grid-offset-from-window-top;
    referencialQuotationPricesGridOffsetFromWindowTop: $referencial-quotation-prices-grid-offset-from-window-top;
    referencialGenericPurchasePricesGridOffsetFromWindowTop: $referencial-generic-purchase-prices-grid-offset-from-window-top;
    vehiclesSelectorListWidth: $vehicles-selector-list-width;
    flowsSelectorListWidth: $flows-selector-list-width;
    flowsSelectorItemSize: $flows-selector-item-size;
    attachZephyrFlowManuallyGridFromWindowTop: $attach-zephyr-flow-manually-grid-from-window-top;
}

$field-td-diff-background-color: #fde0ca;

$grid-transport-flow-diff-color: #FF6E00;
$grid-referential-transport-purchase-prices-warning-color: #FF6E00;
$grid-referential-internal-vehicle-difference-data-warning-color: #FF6E00;
$grid-referential-internal-driver-difference-data-warning-color: #FF6E00;

$button-actions-enabled: #1DB3DD;
$button-actions-disabled: gray;

$card-title-color: #1DB3DD;

$template-card-selected: #dae3f3;

$blue-label-transporter: #08295e;

$filter-option-selected: #FFFF66;

$list-item-selected: #CCEEFF;

$purchase-provision-icon-work-color: #004568;
$purchase-provision-icon-success-color: #4FBA2B;
$purchase-provision-icon-error-color: #FF0000;
$purchase-provision-icon-pending-color: #FF6E00;

$overriden-color: #38acff;

$icon-info-color: #FF6E00;

$loader-liner-progress-color: #FFD400;
$to-be-visible-BI-icon-color: #FFD400;
$to-be-invisible-BI-icon-color: gray;
$to-be-visible-BI-icon-color-disabled: #E0E0E0;

$color-order-internal: #707070;
$color-order-external: #1561BE;
$color-blue-dark: #004466;

$title-diff-color: #FF6E00;

$color-orange-light: #fff2cc;

$color-regularization-orange: #FDE0CA;
$canceled-vehicle-color: red;

$card-specific-price-backgroundColor: #a3e4ea;
$card-negociated-price-backgroundColor: #8fdb8f;

$color-mauve: #b326b3;
$color-orange: #FF6E00;

$color-secondary: #f50057;