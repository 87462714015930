@import '../../../_variables.scss';

.dispatchers {
    padding: $view-padding;

    .dispatchers-referential-grid, .dispatcher-agencies-referential-grid {
        width: 100%;

        .k-grid td, .k-grid th {
            font-family: Roboto;
        }

        .k-grid th {
            color: $color-thead;
        }

        .k-checkbox-label {
            min-height: 24px;
            min-width: 28px;
            margin-left: 2px;
            margin-top: 3px;
        }

        thead {
            background-color: $gray-background-card;
        }

        thead tr {
            height: 40px;
        }

        .dispatchers-grid, .dispatcher-agencies-grid {
            padding-left: 0.2em;
            width: 100%;

            .k-grid-header-wrap {
                margin-left: 0.5em;
            }

            .k-grid-container {
                margin-left: 0.5em;
            }
        }
    }

    .dispatcher-agencies-content {
        margin-left: 15px;

        .dispatcher-agencies-header {
            margin-bottom: $referencial-dispatcher-agencies-header-margin-bottom;
            height: $referencial-dispatcher-agencies-header-height;
        }

        .fa-trash, .fa-plus {
            color: $blue;
        }

        .fa-trash-disabled, .fa-plus-disabled {
            color: $blue-disabled;
        }
    }
}

.add-dispatcher-dialog {
    .dialog-close-button {
        padding: 0 10px;
    }

    .add-dispatcher-content {
        width: 300px;
    }

    .username-input-text {
        width: 100%;
    }

    .username-input-text-label, .user-role-label {
        color: $dialog-transport-flow-label-color;
    }
}

.add-dispatcher-agencies-dialog {
    .dialog-close-button {
        padding: 0 10px;
    }

    .add-dispatcher-agencies-dialog-content {

        .content {
            height: calc(100% - 46px);
            overflow: auto;
            margin-bottom: 10px;
            padding-right: 5px;
        }


        .label {
            font-size: 12px;
            color: $dialog-label-color;
        }

        .input-select {
            width: 500px;
        }
    }
}

#add-dispatcher-tooltip-image .info-user-text {
    line-height: 1.5;
}

#add-dispatcher-tooltip-image > .MuiTooltip-tooltip {
    width: 415px;
    max-width: none;
}
