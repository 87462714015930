@import '../../../_variables.scss';
@import '../../../shared/_common.scss';

$dialog-content-height: 130px;

.internal-production-sites {
    padding: $view-padding;

    .internal-production-sites-grid {
        width: 100%;

        .k-grid td,
        .k-grid th {
            font-family: 'Roboto', sans-serif;
        }

        .k-grid th {
            color: $color-thead;
        }

        .k-checkbox-label {
            min-height: 24px;
            min-width: 28px;
            margin-left: 2px;
            margin-top: 3px;
        }

        thead {
            background-color: $gray-background-card;
        }

        thead tr {
            height: 40px;
        }

        .production-sites-grid {
            width: 100%;
        }
    }
}

.add-production-site-dialog {
    .MuiDialog-paper {
        max-width: none;
        width: 684px;
    }

    .dialog-close-button {
        padding: 0 10px;
    }

    .add-production-site-component {
        .loading-content {
            height: 30px;
        }

        .search-production-site-component {
            .production-site-infos {
                height: $dialog-content-height;
            }

            .input-text-content {
                width: 450px;
            }

            .production-site-id-input-text-label {
                color: $dialog-transport-flow-label-color;
            }

            .production-site-id-input-text {
                width: 100%;
            }
        }

        .production-site-choices {

            .production-site-choices-grid {
                width: 100%;
                height: $dialog-content-height;

                .k-grid td,
                .k-grid th {
                    font-family: Roboto, sans-serif;
                }

                .k-grid-content {
                    overflow-y: hidden !important;
                }

                thead {
                    background-color: $gray-background-card;
                    color: $color-thead;
                }

                thead tr {
                    height: 40px;
                }
            }
        }

        .production-site-activity-st {
            position: absolute;
            top: 254px;
            left: 150px;
        }

        .previous-button {
            background-color: #fff;
            margin-right: 10px;
            color: unset !important;
        }
    }
}

.edit-production-site-dialog {
    .MuiDialog-paper {
        max-width: unset !important;
    }

    .simple-dialog-content {
        padding-top: 4px;

        .dialog-close-button {
            padding: 0 10px;
        }

        .edit-content {
            width: 700px;
            padding-left: 1em;

            .customer-title {
                .customer-name {
                    font-weight: bold;
                }

                font-size: 1.2em;
            }

            .text-field {
                width: 90%;

                .MuiInputBase-root {
                    height: 56px;
                }
            }

            .phone-number .MuiInputBase-root {
                height: 56px;
            }

            label {
                margin-bottom: unset;
            }

            .contacts-title {
                color: $color-thead;
            }
        }
    }
}


#add-production-site-tooltip-image .info-user-text {
    line-height: 1.5;
}

#add-production-site-tooltip-image>.MuiTooltip-tooltip {
    width: 699px;
    max-width: none;
}