@import '../../variables';
@import '../../shared/common';

.fa-user-driver-temporary {
    color: black;
}

.fa-user-driver-internal {
    color: $icon-user-color;
}

.vehicles-management-content {
    height: 100%;

    .input-search-vehicles {
        height: 40px !important;
        border-radius: 21.5px;
        background-color: $input-background-color;
        padding-right: unset;

        .icon-search {
            width: 56px;
            color: white;
        }

        .input-icon-close-root,
        .input-icon-search-root {
            max-height: unset;
        }

        .input-icon-search-root {
            background-color: $icon-input-search-background-color;
            height: 100%;
            border-radius: 21.5px;
        }

        #input-search-text {
            font-size: 12px;
            padding-left: 1em !important;
        }
    }

    .hidden-grid-check {
        visibility: hidden;
    }

    .k-detail-cell {
        background-color: #CCEEFF;
        height: 75px;

        .card-section-specific-negotiated-price {
            width: 400px;
            border-radius: 4px;
            box-shadow: 0 0 10px 0 rgba(189, 207, 216, 0.3);
            min-height: 96px;

            .label {
                font-weight: bold;
            }

            .input-cell-override {
                height: 20px;
                width: 80px;

                .k-input {
                    height: 20px;
                    padding: 0 !important;
                }
            }

            &.Removal {
                background-color: $removal-transport-service-kind-color;
            }

            &.JobsiteVehicle {
                background-color: $jobsitevehicle-transport-service-kind-color;
            }

            &.Delivery_Perishable {
                background-color: $delivery-perishable-transport-service-kind-color;
            }

            &.Delivery_Not_Perishable {
                background-color: $delivery-not-perishable-transport-service-kind-color;
            }
        }
    }

    .card-body {
        padding-top: 8px;
        padding-right: unset !important;
        padding-bottom: unset !important;
        padding-left: 8px;
    }

    .k-detail-row .k-hierarchy-cell {
        background-color: #CCEEFF;
    }

    .line-border {
        border-right: 2px solid #BDCFD8;
    }

    .card-section {
        border-radius: 4px;
        background-color: #FFFFFF;
        box-shadow: 0 0 10px 0 rgba(189, 207, 216, 0.3);
        height: 96px;

        .label {
            font-weight: bold;
        }
    }

    .card-forfait-bg {
        background-color: #f0f4f6 !important
    }

    .tarif-forfait {
        height: 17px;
        color: #000000;
        font-size: 12px;
        font-weight: 600;
        letter-spacing: 0px;
        line-height: 17px;
    }

    .type-forfait {
        height: 16px;
        color: #2B2B2B;
        font-size: 14px;
        font-weight: bold;
        letter-spacing: 0px;
        line-height: 24px;
        margin-top: 2px;
    }

    .cost {
        height: 17px;
        color: #858889;
        font-size: 12px;
        font-weight: 600;
        letter-spacing: -0.3px;
        line-height: 17px;
    }

    .cost-value {
        height: 16px;
        color: #2B2B2B;
        font-size: 12px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 16px;
        margin-top: 7px;
        width: 60px;
    }

    .cost-quantity-value {
        height: 16px;
        color: #2B2B2B;
        font-size: 12px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 16px;
        margin-top: 7px;
        background-color: white;
        width: 60px;
    }

    .unit-price-readonly {
        width: 70px !important;
        padding-right: 5px;
        padding-left: 0px
    }

    .k-icon-custom {
        color: #1DB3DD !important;
        cursor: pointer;
    }

    .k-icon.hidden {
        display: none;
    }

    .k-selected {
        .k-icon-custom {
            color: #FFF !important;
            cursor: pointer;
        }
    }

    .rec-qt {
        height: 24px;
        width: 80px;
        border-radius: 2px;
        background-color: #F0F4F6;
    }

    .input-cost {
        border: none;
        outline: none;
        background-color: #f0f4f6;
    }

    .input-cost:focus {
        border-bottom: 1px solid grey;
        outline: none;
    }

    .input-currency {
        border: none;
        outline: none;
        margin-left: -5px !important;
    }

    .input-quantity {
        background-color: #F0F4F6;
        border: none;
        outline: none;
        width: 47px;
        height: 16px;
        color: #2B2B2B;
        font-size: 14px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 16px;
        margin-top: 7px;
    }

    .input-quantity:focus {
        border-bottom: 1px solid grey;
        background-color: transparent;
        outline: none;
        width: 47px;
        height: 16px;
        color: #2B2B2B;
        font-size: 14px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 16px;
        margin-top: 7px;
    }

    .cost-type {
        width: 90px
    }

    .per-hta {
        height: 32px;
        border-radius: 2px;
        background-color: #1DB3DD;
        box-shadow: 0 0 4px 0 rgba(0, 69, 104, 0.3);
        margin-top: 7px;
    }

    .per-ht {
        height: 32px;
        border-radius: 2px;
        background-color: #CCEEFF;
        margin-top: 7px;
    }

    .per-ht-text {
        color: #1DB3DD;
        font-size: 14px;
        font-weight: 600;
        letter-spacing: -0.35px;
        line-height: 30px;
        text-align: center;
    }

    .per-hta-text {
        color: #FFFFFF;
        font-size: 14px;
        font-weight: 600;
        letter-spacing: -0.35px;
        line-height: 30px;
        text-align: center;
    }

    .margin-h {
        margin-right: 3px
    }

    .margin-t {
        margin-right: 10px;
    }

    .transporter-drawer,
    .dc-details-drawer-content {
        flex-shrink: 0;
    }

    .transporter-drawer > .MuiDrawer-paper,
    .dc-details-drawer-content > .MuiDrawer-paper {
        display: contents;
    }

    .overridden {
        color: #38acff;
    }

    .read-only {
        color: #c5c5c5 !important;
    }

    .transporter-drawer.open {
        height: calc(100vh - (70px + 0.8rem));
    }

    .dc-details-drawer-content.open {
        margin-top: 5px;
        height: calc(100vh - (140px + 0.8rem));
        background-color: #d9dfe5;
    }

    .transporter-drawer {
        width: 350px;
        height: 0;
    }

    .dc-details-drawer-content {
        width: 450px;
        padding-left: 10px;
        padding-right: 10px;
    }

    .dc-details-drawer-header {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .total-fees-content {
            padding: 20px 0;
            width: 100%;

            .fees-label {
                font-family: Roboto;
                font-size: 12px;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: -0.38px;
                color: #000000;
                align-self: center;
                text-align: center;
                width: 25%;
            }

            .fees-type-label {
                font-family: Roboto;
                font-size: 12px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: -0.38px;
                color: #686868;
                text-align: center;
            }

            .fees-value-label {
                font-family: Roboto;
                font-size: 18px;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: -0.56px;
                color: #004466;
                text-align: center;
            }
        }
    }

    .common-component-content {
        margin-left: -350px;
        width: 100%;
        height: 100%;

        &-vehicles {
            display: flex;
            height: calc(100% - #{$vehicle-header-content-height});
        }
    }

    .is-prd {
        .common-component-content {
            margin-left: 0;
        }
    }

    .common-component-content.open {
        flex-grow: 1;
        margin-left: 0;
    }

    .vehicles-content {
        flex-grow: 1;
        margin-right: -450px;
        width: 100%;
    }

    .vehicles-content.open {
        margin-right: 0;
        width: 0;
        padding: 5px 0 5px 0;
    }

    .vehicles-content.transporter-drawer-opened {
        width: 0;
        padding: 5px 0 5px 10px;
    }

    .content-header {
        padding: $vehicle-header-content-padding-top-bottom 0;
        height: $vehicle-header-content-height;
        align-items: center;

        .transporters-button {
            color: black;
            font-weight: bold;
            font-size: 18px;
            letter-spacing: -0.63px;
        }

        .btn-calendar {
            margin-right: 10px;
        }

        .td-tarifs {
            width: 85px !important;
            color: white
        }

        .content-header-fees-section {
            width: 450px;
            height: 82px;
            padding: 10px 10px;

            .fees-type {
                width: 25%;
                background-color: #eff2f4;
            }

            .fees-type.border-radius {
                border-bottom-right-radius: 5px;
                border-top-right-radius: 5px;
            }

            .vehicle-label {
                width: 25%;
                background-color: #004466;
                border-bottom-left-radius: 5px;
                border-top-left-radius: 5px;
            }

            .fees-type-label-type {
                font-family: Roboto;
                font-size: 12px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: -0.38px;
                color: #686868;
                text-align: center;
            }

            .fees-type-label-value {
                font-family: Roboto;
                font-size: 18px;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: -0.56px;
                color: #004466;
                text-align: center;
            }

            .vehicle-label-title {
                font-family: Roboto;
                font-size: 18px;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: -0.56px;
                color: #ffffff;
                text-align: center;
            }

            .vehicle-label-value {
                font-family: Roboto;
                font-size: 12px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: -0.38px;
                text-align: center;
                color: #ffffff;
            }
        }

        .production-foreman-section {
            align-self: center;

            .production-foreman-label {
                margin-right: 5px;
                font-family: Roboto;
                font-size: 18px;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                align-self: center;
            }

            .production-foreman-select {
                height: 35px;
            }
        }
    }

    .content-transporter-drawer {
        background-color: #fff;
        height: 100%;
        width: 100%;
        padding: 0;

        .drawer-title {
            color: black;
            font-weight: bold;
            font-size: 18px;
            letter-spacing: -0.63px;
        }

        .transporter-drawer-header {
            padding: 15px 15px 15px 15px;
            background-color: $gray;
            border-bottom: 1px solid $gray-light-border;
        }

        .transporter-drawer-body {
            padding: 15px 15px 15px 15px;
            height: calc(100% - 127px);
            overflow-y: auto;
        }

        .vehicle-count {
            margin-left: 5px;
            align-self: center;
        }

        .orange-cls {
            color: darkorange;
        }

        .black-cls {
            color: #000000;
        }

        .black-cls,
        .orange-cls {
            font-family: Roboto;
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: -0.44px;
        }

        .MuiFormControlLabel-label {
            width: 100%;
        }

        .transporter-item {
            margin: 0;
            padding: 0 10px;
            border-bottom: 1px solid $gray-light-border;
        }

        .transporter-item.selected-transporter {
            background-color: $blue-light-background;
        }

        .btn-actions {
            background-color: $button-track-flow-background-color;

            .btn-icon {
                color: $button-icon-open-drawer-color;
            }

            .Mui-disabled {
                color: gray;
            }
        }
    }

    .vehicles-trip-grid {
        width: 100%;

        .k-grid td,
        .k-grid th {
            font-family: Roboto;
        }

        .k-grid th {
            color: $color-thead;
        }

        .k-grid-header th {
            font-weight: 500;
            color: $color-disabled;
        }

        .k-checkbox-label {
            min-height: 24px;
            min-width: 28px;
            margin-left: 2px;
            margin-top: 3px;
        }

        thead {
            background-color: #F0F4F6;
            height: 17px;
            font-size: 12px;
            font-weight: 600;
            letter-spacing: -0.3px;
            line-height: 17px;
        }

        .vehicles-grid {
            width: 100%;

            .k-numerictextbox {
                background-color: unset !important;

                .k-input-inner {
                    padding: 0;
                    text-overflow: unset;
                }
            }

            .k-timepicker {
                background: unset;
                border: none;

                .k-input-inner {
                    padding-left: 0;
                    padding-right: 0;
                }
            }

            .k-detail-row > .k-detail-cell {
                padding-top: 0px;
                padding-bottom: 0px;
            }
        }

        .hide-title-column a,
        .hide-title-column a:hover,
        .hide-title-column span,
        .hide-title-column span:hover {
            color: transparent !important;
        }

        .k-sort-order {
            color: transparent !important;
        }

        .hide-title-column {
            color: transparent !important;
            user-select: none;
        }

        .pv-has-inconsistent-flow,
        .pv-in-progress {
            color: $color-orange;
            text-align: center;
        }

        .quantities-used-old {
            color: $color-orange;
        }

        .quantities-used-planned {
            color: $color-gray;
        }

        .quantities-used-mixed {
            color: $color-mauve;
        }
    }

    .undo-icon {
        color: $overriden-color;
        margin-left: 5px;
        font-size: 12px;
        cursor: pointer;
    }

    .undo-icon.disabled {
        color: $color-disabled;
        cursor: default;
    }

    .text-blue {
        color: $overriden-color;
    }

    .text-mauve {
        color: $color-mauve;
    }

    .warning {
        color: $grid-transport-flow-diff-color;
        text-align: center;
    }

    .dc-details-content {
        overflow-y: auto;
        height: calc(100% - 76px);
        overflow-x: hidden;

        .dc-panel {
            margin: 10px 0 !important;
            margin-top: 0 !important;

            .dc-panel-header {
                padding: 0 5px;
                background-color: #fafafa;

                .panel-header {
                    width: 100%;
                    align-items: center;

                    .status-label {
                        font-family: Roboto;
                        font-size: 12px;
                        font-weight: normal;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: 1.8;
                        letter-spacing: -0.31px;
                        text-align: center;
                        color: #ffffff;
                        padding: 0 5px;
                        border-radius: 4px;
                    }

                    .pendingTransportFlow {
                        color: $color-black;
                        background-color: $color-white !important;
                    }

                    .optimizedTransportFlow {
                        color: $color-white;
                        background-color: $color-optimized !important;
                    }

                    .inProressTransportFlow {
                        color: $color-white;
                        background-color: $color-inProgress-flow !important;
                    }

                    .plannedTransportFlow {
                        color: $color-white;
                        background-color: $color-plannedFlow-flow !important;
                    }

                    .confirmedTransportFlow {
                        color: $color-white;
                        background-color: $color-confirmed-flow !important;
                    }

                    .finishedTransportFlow {
                        color: $color-white;
                        background-color: $color-finished-flow !important;
                    }

                    .canceledTransportFlow {
                        color: $color-white;
                        background-color: $color-canceled !important;
                    }

                    .businessId-label,
                    .businessId-label {
                        font-family: Roboto;
                        font-size: 18px;
                        font-weight: bold;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: normal;
                        letter-spacing: -0.56px;
                        color: #000000;
                    }

                    .beneficiaryName-label {
                        font-family: Roboto;
                        font-size: 14px;
                        font-weight: normal;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: normal;
                        letter-spacing: -0.44px;
                        color: #000000;
                    }
                }
            }

            .dc-panel-details {
                padding: 0 5px;

                .panel-details {
                    width: 100%;

                    .detail-title {
                        font-family: Roboto;
                        font-size: 12px;
                        font-weight: bold;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: normal;
                        letter-spacing: -0.38px;
                        color: #000000;
                        margin-bottom: 5px;
                    }

                    .item-value {
                        font-family: Roboto;
                        font-size: 12px !important;
                        font-weight: normal;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: normal;
                        letter-spacing: -0.38px;
                        color: #000000;
                    }

                    .item-value.touched {
                        color: $color-light-blue;
                    }

                    .item-value.text-mauve {
                        color: $color-mauve;
                    }

                    .details-section {
                        border-bottom: 3px solid $gray-light-border;
                        margin: 5px 0;
                    }

                    p {
                        margin-bottom: 0;
                    }

                    input {
                        width: 80px;
                        padding: 1px 5px;
                    }

                    .inconsistent-flow {
                        color: $color-orange;
                    }
                }
            }
        }

        .override-grid {
            border: none;

            .k-grid-content {
                overflow: hidden;
            }

            .k-master-row,
            .k-header {
                background-color: #FFFFFF !important;
            }

            .k-header {
                padding: 0 !important;

                .title {
                    color: black !important;
                }
            }

            th,
            td {
                border: none;
            }

            .k-link {
                font-family: Roboto;
                font-size: 12px;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: -0.38px;
                color: $color-black;
                margin: -5px 0 0 0;
                padding: 0;
            }

            table {
                width: 100% !important;
                overflow: hidden;
            }

            tr {

                .cell-override,
                .input-cell-override {
                    font-size: 14px !important;
                    font-weight: bold;
                    display: flex;
                    align-items: center;
                }

                .k-numerictextbox {
                    border-width: 0 !important;

                    .k-input-inner {
                        color: $color-light-blue !important;
                    }
                }
            }

            .editable-header {
                cursor: default;
                color: $color-black;
            }
        }

        .vehicles-fees {
            .header-title {
                font-size: 12px;
                color: $color-thead;
                font-weight: 700;
                margin-bottom: 10px;
            }

            .icon-pen-blue {
                margin-left: 5px;
            }

            .input-cell-fees {
                background-color: $blue-light-background;
                font-size: 14px !important;
                font-weight: bold;
                display: flex;
                align-items: center;
                color: $color-light-blue !important;
            }

            .input-cell-fees input {
                color: $color-light-blue !important;
            }

            .input-cell-fees {
                border: none;
            }

            .item-value {
                margin-bottom: 10px !important;
            }
        }
    }

    .icon-pen-blue {
        color: $icon-pen-blue;
    }

    .editable-header {
        cursor: pointer;
    }

    .editable-header-title {
        margin-right: 5px;
    }

    .calendar-daterange-picker .k-dateinput {
        width: 48%;
        border: none;

        .k-input-inner {
            padding-left: 0;
            padding-right: 0;
            border-bottom: 1px solid rgba(0, 0, 0, .54);
        }
    }

    .planning-vehicle_costs-are-not-published {
        color: $costs-are_not_published_color;
    }

    .planning-vehicle_costs-are-published {
        color: $costs-are_published_color;
    }

    .planning-vehicle_costs-are-agreed {
        color: $costs-are_agreed_color;
    }

    .planning-vehicle_costs-are-not-agreed {
        color: $costs-are_not_agreed_color;
    }

    .btn-track {
        border-top-left-radius: 0%;
        border-top-right-radius: 50%;
        border-bottom-left-radius: 0%;
        border-bottom-right-radius: 50%;
        background-color: $button-track-flow-background-color;

        .btn-icon {
            color: $button-icon-open-drawer-color;
        }
    }

    #show-only-with-costs-in-disagreement,
    #show-only-canceled-vehicles {
        margin: 0;
    }

    #show-only-with-costs-in-disagreement > span,
    #show-only-canceled-vehicles > span {
        font-size: 12px;
        font-weight: 500;
        white-space: nowrap;
        padding: 0;
    }

    .purchase-is-canceled {
        font-weight: 500;
        color: $color-disabled !important;
    }
}

.transporters-email-notifications-dialog {
    .MuiDialog-paper {
        max-width: none;
        width: 800px;
        height: auto;
        max-height: 80vh;
        padding-bottom: 5px;
    }

    .dialog-close-button {
        padding: 0 10px;
    }

    .simple-dialog-content {
        padding: 24px 24px 0;
        height: calc(100% - 90px);
    }

    .transporters-notifications-content {
        .email-notifications-content {
            height: 100%;
            overflow: auto;
            padding-right: 25px;
        }

        .email-content {
            word-break: break-word;
            margin-left: 50%;
            margin-bottom: 10px;
            padding: 10px;
            border-radius: 4px;
            background-color: $sms-content-color;
            color: white;
            font-size: 12px;
        }

        .email-text-input {
            width: 100%;
            resize: none;
            border: 1px solid $dialog-transport-flow-input-text-border-color;
            border-radius: 4px;

            &.error {
                border: 1px solid red;
            }
        }

        .send-email {
            background-color: #6098f2;
        }

        .send-email:hover {
            background-color: #6098f2;
        }

        .email-text-input:focus {
            outline: 0;
        }

        .email-length-counter {
            font-weight: bold;

            &.error {
                color: red;
            }
        }

        .send-section {
            padding: 20px 10px 0;
            border-top: 1px solid $dialog-transport-flow-input-text-border-color;
        }
    }
}

.vehicles-management-content.is-prd {
    .common-component-content {
        margin-left: 0;
    }
}

.prev-confirm-btn {
    height: 40px;
    border-radius: 2px !important;
    background-color: #1DB3DD !important;
    color: #FFFFFF !important;
    font-size: 14px !important;
    font-weight: 500 !important;
    letter-spacing: -0.35px !important;
    line-height: 19px !important;
    text-align: center !important;
    margin-left: 5px !important;
}

.prev-confirm-btn:disabled {
    background-color: #e0e0e0 !important;
}

.visualisation-popup {

    .MuiDialog-paper {
        max-width: none;
        width: 80%;
        height: 80%;
    }

    .visualisation-popup-title {
        height: $visualisation-popup-title-height;
    }

    .visualisation-popup-content {
        padding: $visualisation-visualisation-popup-content-padding-top-bottom 24px;
    }

    .visualisation-popup-close-icon {
        top: 8px;
        color: #9e9e9e;
        right: 8px;
        position: absolute;
    }

    .btn-copy {
        margin-bottom: $visualisation-btn-copy-margin-bottom;
        height: $visualisation-btn-copy-height;
    }

    .data-to-copy-grid {
        //height: calc(100% - 46px);
        border: 0;
    }

    .k-grid td,
    .k-grid th {
        font-family: Roboto;
    }

    .k-grid th {
        color: $color-thead;
    }

    thead {
        background-color: $gray-background-card;
    }

    .k-grid-header {
        border: 1px solid $border-grid-color;
    }

    .k-grid-container {
        border: 1px solid $border-grid-color;
    }

    p {
        font-size: 12px !important;
        padding: 5px 0;
        margin-bottom: 0;
    }
}