@import '../../../_variables.scss';
@import '../../../shared/_common.scss';

$dialog-content-height: 440px;

.colasTransporters {
    padding: $view-padding;

    .colas_transporters-grid {
        width: 100%;
        height: calc(100vh - (170px + 0.8rem));

        .k-grid td, .k-grid th {
            font-family: Roboto;
        }

        .k-grid th {
            color: $color-thead;
        }

        .k-checkbox-label {
            min-height: 24px;
            min-width: 28px;
            margin-left: 2px;
            margin-top: 3px;
        }

        thead {
            background-color: $gray-background-card;
        }

        thead tr {
            height: 40px;
        }

        .transporters-grid {
            width: 100%;

            .k-grid-header-wrap {
                margin-left: 0.5em;
            }

            .k-grid-container {
                margin-left: 0.5em;
            }
        }

        .warning-icon-cell {
            color: $grid-referential-transport-purchase-prices-warning-color;
            text-align: center;
        }
    }

    .colas_transporters-prices-grid {
        width: 100%;
        height: calc(100vh - (170px + 0.8rem));

        .k-grid td, .k-grid th {
            font-family: Roboto;
        }

        .k-grid th {
            color: $color-thead;
        }

        .k-checkbox-label {
            min-height: 24px;
            min-width: 28px;
            margin-left: 2px;
            margin-top: 3px;
        }

        thead {
            background-color: $gray-background-card;
        }

        thead tr {
            height: 40px;
        }

        .transporters-prices-grid {
            width: 100%;

            .vehicle-disabled {
                padding: 6px;
                background-color: lightgray;
            }

            .k-grid-header-wrap {
                margin-left: 0.5em;
            }

            .k-grid-header .pac-field {
                color: $color-contracted;
            }

            .k-grid-container {
                margin-left: 0.5em;

                .k-virtual-content {
                    overflow: auto;
                }
            }
        }
    }

    #global-search-transporters-referential {
        margin: 0 10px;
    }

    #global-search-transporters-referential > span {
        font-size: 14px;
        font-weight: 500;
        white-space: nowrap;
    }
}

.simple-dialog-purchase-price {
    .MuiDialog-paperWidthSm {
        max-width: unset;
        min-width: 850px;
        max-height: calc(100vh - 110px);
    }

    .warning-message {
        color: $color-orange;
    }
}

.add-existing-purchase-prices-template-dialog {
    .selected-item {
        background-color: #2684FF;
    }

    .selected-item-label {
        color: #fff;
    }
}

.add-transporter-dialog {

    .MuiDialog-paper {
        max-width: none;
        width: 1100px;
    }

    .dialog-close-button {
        padding: 0 10px;
    }

    .add-transporter-component {
        .loading-content {
            height: 30px;
        }

        .search-transporter-component {
            .transporter-infos {
                height: $dialog-content-height;
            }

            .input-text-content {
                width: 450px;
            }

            .idmdm-input-text-label, .transporter-name-input-text-label {
                color: $dialog-transport-flow-label-color;
            }

            .transporter-id-input-text, .transporter-name-input-text {
                width: 100%;
            }
        }

        .transporter-choices {
            .transporter-choices-grid {
                width: 100%;
                height: $dialog-content-height;

                .k-grid td, .k-grid th {
                    font-family: Roboto;
                }

                thead {
                    background-color: $gray-background-card;
                    color: $color-thead;
                }

                thead tr {
                    height: 40px;
                }
            }
        }

        .confirm-add-transporter {
            .input-texts-content {
                height: $dialog-content-height;
                overflow: auto;

                .transporter-input-text {
                    width: 300px;
                    border-bottom: 1px solid $dialog-transport-flow-input-text-border-color;
                }

                .transporter-input-text.invalid {
                    border-bottom: 1px solid $dialog-transport-flow-error-label-color;
                }

                .transporter-input-text::before {
                    border-bottom: none;
                }
            }

            .text-field {
                width: 400px;
                margin-top: 0.4em;

                .MuiInputBase-root {
                    height: 56px;
                }
            }
        }

        .previous-button {
            background-color: #fff;
            margin-right: 10px;
            color: unset !important;
        }
    }
}

.edit-transporter-dialog {
    .MuiDialog-paper {
        max-width: none;
        width: 1100px;
    }

    .simple-dialog-content {
        padding-top: 4px;

        .dialog-close-button {
            padding: 0 10px;
        }

        .mr-1 {
            margin-right: 1em !important;
        }

        .map-marker-icon {
            padding: 0 20px 5px 0;
        }

        .map-marker-button {
            outline: none;
            padding: 0;
        }

        .map-marker-button:hover {
            background-color: transparent;
        }

        .map-marker {
            color: $map-marker-color;
        }

        .map-marker-disabled {
            color: $blue-disabled;
        }

        .map-marker-text {
            font-size: small;
            color: $map-marker-text-color;
            margin-left: -15px;
            margin-top: -10px;
        }

        .map-content {
            width: 500px;
            height: 267px;
            border-radius: 4px;
            border: 1px solid $dialog-header-border-color;
            margin-right: 10px;

            .content {
                position: relative;
                width: 100%;
                height: 100%;

                .hidden-accessible-element, .atlas-control-container, .mapboxgl-control-container {
                    display: none;
                }

                .mapboxgl-canvas-container {
                    height: 100%;
                }
            }
        }

        .edit-content {
            .transporter-title {
                .transporter-name {
                    font-weight: bold;
                }

                font-size: 1.2em;
            }

            .text-field {
                width: 80%;

                .MuiInputBase-root {
                    height: 56px;
                }
            }

            label {
                margin-bottom: unset;
                z-index: 0;
            }

            .async-select {
                width: 470px;
            }

            .async-select.required > div {
                border: 1px solid $dialog-transport-flow-error-label-color;
            }

            .async-select.warning > div {
                border: 1px solid $dialog-transport-flow-warning-label-color;
            }

            .async-select input {
                opacity: 1 !important;
            }

            .address-failed {
                border: 1px solid $dialog-transport-flow-error-label-color;
                padding-top: 15px;
                margin: 0 10px 10px 0;
            }
        }
    }
}

.add-new-purchase-prices-template-dialog {
    .selected-item {
        background-color: #2684FF;
    }

    .selected-item-label {
        color: #fff;
    }

    .invalid-number {
        color: red;
        border-color: red;
    }

    .number-of-lines {
        background-color: white;

        .k-input-inner {
            padding-left: 0px;
            padding-right: 0px;
        }
    }
}

#add-transporter-tooltip-image .info-user-text {
    line-height: 1.5;
}

#add-transporter-tooltip-image > .MuiTooltip-tooltip {
    width: 720px;
    max-width: none;
}
