@import '../../../_variables.scss';
@import '../../../shared/_common.scss';

.userinfomessage {
    padding: $view-padding;
}

.user-info-message-header{
    display: flex;
    justify-content: space-between;
}

.simple-dialog-new-add-user-info-message {
    .fa-info-circle-icon {
        color: $icon-info-color;
        cursor: pointer;
    }

    .headerMessage {
        display: flex;
        justify-content: space-between;

        .msgColor {
            display: inline-block;

            > div {
                display: inline-block;
                margin-right: 10px;
            }

            input[type="radio"] {
                display: none;

                & + label {
                    border: 2px solid transparent;
                }

                &:checked + label {
                    border: 2px solid black;
                }
            }

            label {
                display: inline-block;
                width: 20px;
                height: 20px;
                border-radius: 50%;
                border: 1px solid #000;
                cursor: pointer;
            }
        }
    }

    .title {
        font-weight: 500;
        color: $information-message-color;
    }

    .subtitle {
        font-size: 12px;
    }

    .bannerPreview {
        background-color: #f5f5f5;
    }
}