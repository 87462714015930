@import '../../variables';

.cartography {
    background-color: $cartography-content-backgroundColor;
    height: calc(100vh - #{$header-menu-height});

    .header {
        height: $cartography-content-header-height;
        padding: 15px 0 15px 15px;
        margin-top: 3px;

        .async-select {
            width: 400px;
        }

        .async-select input {
            opacity: 1 !important;
        }

        .camera-icon {
            padding: 0;

            svg {
                height: 35px;
                width: 35px;
            }
        }

        .favorite-addresse {
            width: 300px;
        }

        .input-search-vehicles {
            height: 40px !important;
            border-radius: 21.5px;
            background-color: $input-background-color;
            padding-right: unset;

            .input-icon-close-root, .input-icon-search-root {
                max-height: unset;
            }

            .input-icon-search-root {
                background-color: $icon-input-search-background-color;
                height: 100%;
                border-radius: 21.5px;
            }

            #input-search-text {
                font-size: 12px;
                padding-left: 1em !important;
            }
        }

        .input-search-Point {
            height: 40px !important;
            border-radius: 21.5px;
            background-color: $input-background-color;
            padding-right: unset;

            input {
                width: $cartography-input-search-text-width;
            }

            .icon-search {
                width: 56px;
                color: white;
            }

            .input-icon-close, .input-icon-search {
                max-height: unset;
            }

            .input-icon-search {
                background-color: $icon-input-search-background-color;
                height: 100%;
                border-radius: 21.5px;
            }

            &.search-text-active {
                background-color: $search-input-text-active-bgc !important;
            }
        }

        .external-transporters-btn, .external-transporters-btn:hover, .colas-agencies-btn, .colas-agencies-btn:hover {
            background-color: $cartography-btn-inactive-color;
            width: 80px;

            &.active {
                background-color: $cartography-btn-active-color;
            }
        }

        .colas-agencies-btn {
            margin-left: 10px;
        }

        .filter-section {
            padding-left: 15px;
            border-left: 5px solid $gray;
        }

        .perishable-btn, .not-perishable-btn, .removal-btn, .jobsite-btn, .low-btn, .day-btn, .fix-btn {
            color: #fff;
            margin-right: 5px;
        }

        .perishable-btn {
            background-color: $filter-button-perissable-not-selected
        }

        .not-perishable-btn {
            background-color: $filter-button-not-perissable-not-selected;
        }

        .removal-btn {
            background-color: $filter-button-removal-not-selected;
        }

        .jobsite-btn {
            background-color: $filter-button-jobSite-not-selected;
        }

        .perishable-btn, .not-perishable-btn, .removal-btn, .jobsite-btn {
            &.active {
                color: $filter-button-selected;
                border: 1px solid $cartography-service-kind-button-selected-border-color;
            }
        }

        .low-btn, .day-btn, .fix-btn {
            background-color: $filter-button-not-selected;

            &.active {
                color: $cartography-priority-button-selected-background-color;
                background-color: $filter-button-selected;
            }
        }

        .select-all {
            color: $filter-button-selected;
            cursor: pointer;
        }

        .delivery-btn {
            background-color: $cartography-delivery-color;
        }

        .loading-btn {
            background-color: $cartography-loading-color;
        }

        .delivery-btn, .loading-btn {
            color: white;
            opacity: 0.5;
            margin-top: 5px;

            &.active {
                opacity: 1;
            }
        }

        .radio-group-item {
            margin: 5px 0 0;

            span {
                padding: 0;
                color: gray;
            }

            &.disabled {
                opacity: 0.5;
            }
        }

        .vehicle-type-filter > div {
            width: $cartography-vehicle-type-filter-width;
            margin-right: 10px;
            align-self: flex-end;
        }

        .details-header {
            width: $cartography-details-drawer-width;
            height: $cartography-details-header-height;
            background-color: $cartography-details-header-background-color;
            padding-left: 10px;

            .title {
                font-size: 22px;
                font-weight: bold;
            }

            .eyeIcon {
                width: $cartography-eye-icon-width;
            }
        }

        .k-datepicker {
            background: none;
            height: 100%;

            .k-input-inner {
                padding: 0;
            }

            .k-icon-button {
                color: rgba(0,0,0,.54);
            }
        }
    }

    .map-content {
        width: 100%;
        height: calc(100vh - #{$header-menu-height} - #{$cartography-content-header-height});

        .content {
            position: relative;
            width: 100%;
            height: 100%;

            .mapboxgl-canvas-container {
                height: 100%;

                .cluster-content {
                    display: flex;
                    flex-direction: row;

                    .content {
                        display: flex;
                        flex-direction: column;
                        align-items: center;

                        .count {
                            border: 1px solid;
                            border-radius: 50%;
                            height: 24px;
                            width: 24px;
                            color: #000;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            margin-bottom: 5px;
                        }
                    }
                }
            }

            .hidden-accessible-element, .map-copyright {
                display: none;
            }

            .pin-marker {
                cursor: pointer;
                display: flex;
                flex-direction: column;
                align-items: center;

                img {
                    margin-top: 5px;
                }

                &.stump {
                    opacity: 0.5;
                }

                &.selected {
                    background-color: $cartography-selected-marker-background-color;
                    padding: 5px;
                }

                &.matches-search-text {
                    border-bottom: 4px solid $cartography-matches-search-text-point-border-bottom-color;
                    padding: 5px;
                }

                .delivery-section {
                    background-color: $cartography-delivery-color;
                }

                .loading-section {
                    background-color: $cartography-loading-color;
                }

                .delivery-section, .loading-section {
                    padding: 5px;
                    text-align: center;
                    color: #fff;
                    width: 100%;
                }
            }
        }
    }

    .details-drawer {
        z-index: unset;
        height: calc(100vh - #{$header-menu-height} - #{$cartography-content-header-height});
        margin-top: calc(#{$header-menu-height} + #{$cartography-content-header-height});
        width: 0;
    }

    .details-drawer.opened {
        width: $cartography-details-drawer-width;
    }

    .details-drawer-content {
        padding: 10px;

        .title {
            color: $cartography-details-title-color;
            font-size: 12px;
        }

        .label {
            font-size: 14px;
        }

        .planning-vehicles-count-by-group-grid {
            border: none;

            .grid-header {
                text-align: center;

                .k-cell-inner {
                    margin-left: 0px;
                    margin-right: 0px;

                    .k-column-title {
                        width: 100%;
                    }
                }
            }

            td {
                border: none;
                text-align: center
            }
        }

        .remarks {
            width: 100%;

            label {
                color: $cartography-details-title-color;
                font-size: 15px;
            }
        }

        .edit-button {
            padding: 10px;

            .pen-icon {
                color: $icon-pen-blue;
            }
        }
    }
}

.favorite-dialog {
    .simple-dialog-content {
        width: 400px;

        .favorite-name {
            input {
                padding: 17px !important;
                width: 270px;
            }
        }

        .exist-favorite {
            color: red;
        }

        .favorite-dialog-btn {
            margin-left: 10px;
            color: #fff;
            background-color: $filter-button-selected;

            &.disabled {
                background-color: $filter-button-not-selected;
            }
        }
    }
}
