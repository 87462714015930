@import '../../../_variables.scss';
@import '../../../shared/_common.scss';

$dialog-content-height: 440px;

.external-contacts {
    padding: $view-padding;

    .colas_customers-grid {
        width: 100%;
        height: calc(100vh - (170px + 0.8rem));

        .k-grid td, .k-grid th {
            font-family: Roboto;
        }

        .k-grid th {
            color: $color-thead;
        }

        .k-checkbox-label {
            min-height: 24px;
            min-width: 28px;
            margin-left: 2px;
            margin-top: 3px;
        }

        thead {
            background-color: $gray-background-card;
        }

        thead tr {
            height: 40px;
        }

        .customer-grid {
            width: 100%;
            padding-left: 0.2em;

            .k-grid-header-wrap {
                margin-left: 0.5em;
            }

            .k-grid-container {
                margin-left: 0.5em;
            }
        }
    }

    .colas_customers-contact-grid {
        width: 100%;
        height: calc(100vh - (170px + 0.8rem));

        .k-grid td, .k-grid th {
            font-family: Roboto;
        }

        .k-grid th {
            color: $color-thead;
        }

        .k-checkbox-label {
            min-height: 24px;
            min-width: 28px;
            margin-left: 2px;
            margin-top: 3px;
        }

        thead {
            background-color: $gray-background-card;
        }

        thead tr {
            height: 40px;
        }

        .customers-contacts-grid {
            width: 100%;
            padding-left: 0.2em;

            .k-grid-container {
                .k-virtual-content {
                    overflow: auto;
                }
            }
        }

        th.k-header.logistics-unit, td.logistics-unit-cell {
            border-left: solid;
            padding-left: 0.5em !important;
            text-align: center;
        }

        td.logistics-unit-cell {
            .checkbox-label {
                align-self: center;
                margin-right: 10px;
            }

            .custom-checkbox {
                margin-right: 10px;
            }
        }
    }
}

.add-customer-dialog {
    .MuiDialog-paper {
        max-width: none;
        width: 845px;
    }

    .dialog-close-button {
        padding: 0 10px;
    }

    .add-customer-component {
        .loading-content {
            height: 30px;
        }

        .search-customer-component {
            .customer-infos {
                height: $dialog-content-height;
            }

            .input-text-content {
                width: 450px;
            }

            .idmdm-input-text-label, .customer-name-input-text-label {
                color: $dialog-transport-flow-label-color;
            }

            .customer-idmdm-input-text, .customer-name-input-text {
                width: 100%;
            }
        }

        .customer-choices {
            .customer-choices-grid {
                width: 100%;
                height: $dialog-content-height;

                .k-grid td, .k-grid th {
                    font-family: Roboto;
                }

                thead {
                    background-color: $gray-background-card;
                    color: $color-thead;
                }

                thead tr {
                    height: 40px;
                }
            }
        }

        .confirm-add-customer {
            .input-texts-content {
                height: $dialog-content-height;
                overflow: auto;

                .contact-content {
                    padding: 10px;
                    border: 1px solid $dialog-transport-flow-input-text-border-color;
                }

                .contact-content.invalid {
                    border: 1px solid $dialog-transport-flow-error-label-color;
                }

                .customer-input-text {
                    width: 300px;
                    border-bottom: 1px solid $dialog-transport-flow-input-text-border-color;
                }

                .customer-input-text.invalid {
                    border-bottom: 1px solid $dialog-transport-flow-error-label-color;
                }

                .customer-input-text::before {
                    border-bottom: none;
                }
            }
        }

        .previous-button {
            background-color: #fff;
            margin-right: 10px;
            color: unset !important;
        }
    }
}

.edit-customer-dialog {
    .MuiDialog-paper {
        max-width: unset !important;
    }

    .simple-dialog-content {
        padding-top: 4px;
        .dialog-close-button {
            padding: 0 10px;
        }
    
        .edit-content {
            width: 700px;
            padding-left: 1em;
            .customer-title {
                .customer-name {
                    font-weight: bold;
                }
                font-size: 1.2em;
            }

            .text-field {
                width: 90%;
                .MuiInputBase-root { 
                    height: 56px;
                }
            }

            .phone-number .MuiInputBase-root { 
                height: 56px;
            }
    
            label {
                margin-bottom: unset;
            }

            .contacts-title {
                color: $color-thead;
            }
        }
    }
}

#add-customer-tooltip-image .info-user-text {
    line-height: 1.5;
}

#add-customer-tooltip-image > .MuiTooltip-tooltip {
    width: 720px;
    max-width: none;
}
