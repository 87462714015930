@import '../../../variables';

@media (max-width: 500px) {
    .rectangle {
        width: 100% !important;
    }

    .job-foreman-card-content {
        background-color: $blue-light-background;
        min-width: 100% !important;
        height: 100%;
    }

    .job-foreman-dc-expansionPanel-details {
        min-width: 100% !important;
        padding: 0px 0px 0px !important;
    }

    .job-foreman-dc-card {
        margin-right: 0px !important;
    }
}

.bg-c-rectangle {
    padding-top: 1em;
    padding-bottom: 1em;
    padding-left: 0.75em;
    width: 100% !important;
    background-color: $color-white !important;
}

.texte-date-cta {
    width: 103px;
    height: 26.7px;
    font-family: Roboto;
    font-size: 13px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.23;
    letter-spacing: normal;
    color: $texte-date-color;
}

.beneficiary-header {
    margin-top: 15px;

    .invoices-details-button {
        width: 190px;
        color: white !important;
        border-radius: 4px !important;
        background-color: $color-light-blue !important;
    }

    .invoices-details-button.disabled {
        color: #00000042 !important;
    }

    .transporter-select {
        width: 300px;
    }

    .k-datepicker {
        background: none;
        height: 100%;

        .k-input-inner {
            padding: 0;
        }

        .k-icon-button {
            color: rgba(0,0,0,.54);
        }
    }
}

.job-foreman-card-content {
    padding: 0px !important;
    background-color: $blue-light-background !important;
}

.job-foreman-dc-expansionPanel {
    background-color: #a3ddf5 !important;
    padding-bottom: 0px !important;
    margin-bottom: 3px !important;

    .job-foreman-dc-expansionPanel-summary {
        height: 85px;
        padding-top: 0.5em;

        .nb-vehicles {
            margin-right: 1em;
            font-size: 1.1em;
            font-weight: bold;
            color: $color-icons-blue-dark;
        }

        .transportRequest {
            font-family: Roboto !important;
            font-size: 14px !important;
            font-weight: normal !important;
            font-stretch: normal !important;
            font-style: normal !important;
            line-height: normal !important;
            letter-spacing: -0.44px !important;
            color: #000000 !important;

            .product-quantity {
                font-weight: bold !important;
                font-size: 15px !important;
            }
        }

        .finish-checkbox-label {
            font-size: 14px;
        }
    }

    .job-foreman-dc-expansionPanel-details {
        min-width: 550px;
        padding-left: 0.25em;

        .job-foreman-dc-card {
            margin-right: 0.5em;

            .job-foreman-card-content {
                background-color: $blue-light-background;
                min-width: 600px;
                height: 100%;

                .job-foreman {
                    font-weight: bold;
                }

                .job-foreman-card-content-vehicle-type-title {
                    font-size: 1em;
                }

                .title-field {
                    font-size: 0.8em;
                    color: $color-thead;
                    margin-right: 1em;
                }

                .value-field {
                    font-size: 0.9em;
                    font-weight: bold;
                }

                .phone-number {
                    color: $color-light-blue;

                    .icon-phone-number {
                        padding-left: 0.2em;
                    }
                }
            }
        }
    }
}

.loading-card-mg-b {
    margin-bottom: 0px !important;
    margin-top: 0px !important;
    padding-bottom: 0px !important;
    padding-top: 0px !important;
}

.card-content-summary {
    background-color: $color-card-content-summary !important;
    height: 45px !important;
    min-height: 45px !important;
    margin-top: 10px !important;

    .jobsite-name {
        height: 21px;
        font-family: Roboto !important;
        font-size: 15px !important;
        font-weight: bold !important;
        font-stretch: normal !important;
        font-style: normal !important;
        line-height: normal !important;
        letter-spacing: -0.56px !important;
        color: #000000 !important;
    }
}
