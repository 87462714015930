@import '../../variables';
@import '../../shared/common';

.container-dc {
    height: 99%;
    transition: margin 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;

    .pt-splitter {
        height: calc(100% - 120px);
    }

    .parent-card-dc {
        height: 100%;

        .container-expansionPanel {
            height: 100%;

            .icon-collapse-disabled{
                color: rgba(0, 0, 0, 0.12);
            }

            .container-expansionPanel-title {
                width: 100%;
                height: $header-pt-card-header;
                padding-left: 0px;
                background-color: $gray-background-card;

                .card-dc {
                    background-color: $gray-background-card;
                    padding-top: 0px !important;
                    padding-left: 0.8rem !important;
                    padding-right: 0.8rem !important;
                }

                .MuiExpansionPanelSummary-content {
                    margin-top: 7px !important;
                }
            }

            .MuiExpansionPanelSummary-expandIcon.Mui-expanded {
                padding-top: 10px !important;
            }

            .MuiExpansionPanelSummary-root.Mui-expanded {
                min-height: 50px;
                padding-top: 7px;
            }

            .MuiCollapse-entered {
                height: calc(100% - #{$header-pt-card-header}) !important;

                .MuiCollapse-wrapper {
                    height: 100% !important;

                    .MuiCollapse-wrapperInner > div {
                        height: 100% !important;
                    }
                }
            }

            .container-expansionPanel-details {
                padding: 0px;
                height: 100%;

                .transport-request-dc {
                    height: 100%;

                    .k-grid td, .k-grid th {
                        font-family: Roboto;
                    }

                    .k-grid th {
                        color: $color-thead;
                    }

                    .k-grid .al-number {
                        font-weight: bold !important;
                    }

                    .k-checkbox-label {
                        min-height: 24px;
                        min-width: 28px;
                        margin-left: 2px;
                        margin-top: 3px;
                    }

                    td.transport-required-col {
                        font-weight: bold;
                        color: red;
                    }

                    .hide-title-column a {
                        color: $gray-background-card !important;
                    }

                    .tf-has-diff-from-request{
                        color: $grid-transport-flow-diff-color;
                        text-align: center;
                    }

                    .has-missing-coordinates {
                        color: red;
                        text-align: center;
                    }

                    .k-numerictextbox {
                        background: unset !important;
                        
                        .k-input-inner {
                            padding: 0 !important;
                            text-overflow: unset;
                        }
                    }

                    .k-dateinput {
                        background: unset;
                        color: white;
                        font-size: 14px;
            
                        &.k-disabled {
                            border-style: dashed;
                            border-color: black;
                            color: black;
                        }

                        .k-input-inner {
                            padding-left: 0;
                            padding-right: 0;
                        }
                    }
                }

                thead {
                    background-color: $gray-background-card;
                }
            }
        }

        .header-dc {
            height: $header-dc-card-height;
            padding-right: 0.8rem;
        }
    }

    .transport-request-content-grid {
        width: 100%;
        height: 100%;
    }

    .card-dc {
        .content-card-dc {
            height: 100%;
            .select-search-inside-td > div {
                z-index: 2000 !important;
                overflow: hidden;
                width: 100%;
                color: rgba(0,0,0,0.87);
            }

            .status-select {
                font-weight: bold;
                color: $blue;
            }

            .content-box-dc {
                height: 100%;

                .title-card-dc {
                    height: 100%;

                    .fa-card {
                        color: $blue;
                    }

                    .fa-card-disabled {
                        color: $blue-disabled;
                    }

                    .btn-trip-divider-left, .btn-flow-divider-left {
                        border-left: solid 2px gray;
                        margin-left: 1rem;
                    }

                    .title-dc {
                        color: black;
                        font-weight: bold;
                        font-size: 1.2rem !important;
                        letter-spacing: -0.63px;
                    }

                    .count-transportplan {
                        height: 21px;
                        font-family: Roboto;
                        font-size: 19px;
                        font-weight: 900;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: normal;
                        letter-spacing: -0.56px;
                        color: #004466;
                        padding-left: 20px;
                    }
                    .count-diff{
                        font-size: 1.2em;
                        font-weight: bold;
                        padding-left: 1em;
                        color: $grid-transport-flow-diff-color;
                    }
                }

                .transport-request-dc {
                    height: 100%;

                    .k-grid td, .k-grid th {
                        font-family: Roboto;
                    }

                    .k-grid th {
                        color: $color-thead;
                    }

                    .k-grid .al-number {
                        font-weight: bold !important;
                    }

                    .k-checkbox-label {
                        min-height: 24px;
                        min-width: 28px;
                        margin-left: 2px;
                        margin-top: 3px;
                    }

                    td.transport-required-col {
                        font-weight: bold;
                        color: red;
                    }

                    .hide-title-column a {
                        color: $gray-background-card !important;
                    }
                }

                thead {
                    background-color: $gray-background-card;
                }
            }
        }
    }
}

.delivery-call-content-shiftRight {
    transition: margin 225ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
    margin-right: calc(#{$drawer-dc-details-width} + 5px);
}

.delivery-call-content-shiftLeft {
    transition: margin 225ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
    margin-left: $drawer-vehicles-width;
}

.calendar-daterange-picker .k-dateinput {
    background-color: $gray-background-calendar-file;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    border: none;

    .k-input-inner {
        padding-left: 0;
        padding-right: 0;
        border-bottom: 1px solid rgba(0,0,0,.54);
    }
}

.btn-edit-flow, .btn-edit-flow-disabled {
    padding-left: 10px !important;
    outline: none !important;

    .fa-card {
        color: $blue;
    }
}

.btn-edit-flow-disabled {
    cursor: not-allowed !important;
}

.transport-flow-dialog {
    z-index: 100000 !important;

    .MuiDialog-paper {
        max-width: none;
        height: 100%;
        width: 95%;
    }
}

.upload-attachment {
    width: 100%;

    .k-upload {
        border: none;
    }

    .k-dropzone {
        padding: 0;
        margin-bottom: 10px;
    }

    .k-upload .k-upload-button {
        border-radius: 4px;
        background-color: $blue;
        color: #fff;
        width: 100%;
    }

    .k-upload-status {
        display: none !important;
    }

    .fa-delete-attachment-icon {
        color: $color-delayed;
    }

    .attachment-name {
        color: $blue;
        cursor: pointer;
    }
}
