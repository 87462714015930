@import '../../variables';

.user-signature {
    z-index: 100000 !important;

    .MuiDialog-paper {
        position: unset;
        max-width: unset;
        width: 90%;
        height: 90%;
    }

    .signature-fields-and-preview {
        margin-bottom: 10px;
        height: calc(100% - 230px);

        .fields {
            height: 100%;
            padding-right: 11px;
            padding-top: 11px;

            .ul-list {
                max-height: 100%;
                min-height: 45px;
                padding-left: 0;
                margin: 0;
                user-select: none;
                list-style: none;
                border: 1px solid rgba(0, 0, 0, 0.12);
                padding: 10px 15px;
                overflow-y: auto;

                .k-item {
                    cursor: move;

                    svg {
                        margin-right: 10px;
                        font-size: 20px;
                    }

                    .text-icon {
                        margin-right: 10px;
                        text-align: center;
                        width: 16px;
                        font-size: 20px;
                        font-weight: bold;
                    }

                    .input-text {
                        margin-right: 10px;
                        width: 100%;

                        &.invalid {
                            border: 1px solid red;
                        }
                    }

                    .btn-bold, .btn-italic {
                        color: #000;
                        min-width: unset;
                        font-size: 20px;
                        border: 1px solid;
                        padding: 5px;
                        height: 32px;
                        width: 32px;
                        margin-left: 5px;

                        &.checked {
                            color: #1976d2;
                        }
                    }
                }
            }
        }

        .preview {
            width: 50%;

            fieldset {
                border: 1px solid rgba(0, 0, 0, 0.12);
                height: 100%;
                overflow-y: auto;
                display: flex;
                align-items: center;
            }

            legend {
                width: auto;
                margin-left: 10px;
                margin-bottom: 0;
                font-size: 1rem;
                font-weight: 400;
                line-height: 1.5;
                color: #212529;
                padding: 0 10px;
            }
        }
    }

    .radio-group {
        flex-wrap: nowrap;

        .logo-radio-button {
            .MuiTypography-root {
                height: 100%;
                display: flex;
                align-items: center;
                width: 100%;
            }
        }

        .default-images {
            overflow-x: auto;
            width: 547px;

            .logo-radio-button {
                height: 100%;
                margin-bottom: 0;
            }
        }

        .upload-attachment {
            width: 154px;
            margin-top: 15px;

            input {
                z-index: unset;
            }

            .k-upload {
                border: none;
            }

            .k-dropzone {
                padding: 0;
                margin-bottom: 10px;
            }

            .k-upload .k-upload-button {
                border-radius: 4px;
                background-color: $blue;
                color: #fff;
                width: 100%;
                text-transform: none;
            }

            .k-upload-status {
                display: none !important;
            }

            .attachment-label {
                margin-right: 5px;
                word-break: break-word;
            }
        }
    }
}

.delete-signature-modal {
    z-index: 100002;
    top: 20%;
}

.delete-signature-modal-backdrop {
    z-index: 100001;
}
