@import '../../variables';

.reservation-content {
    height: 90vh;

    p {
        margin: 0;
    }

    .full-height {
        height: 100%;
    }

    .full-width {
        width: 100%;
    }

    .section {
        overflow: auto;
        height: 100%;
        width: 100%;
    }

    .projected-need-tabs {
        width: 100%;
        min-height: 80px;
        border-radius: 2px;
        box-shadow: 0 1px 3px 0 rgba(145, 145, 145, 0.3), 0 1px 2px 0 rgba(0, 0, 0, 0.1);
        background-color: $blue-light-background;
        margin-bottom: 20px;
    }

    .projected-need-details {
        width: 100%;
        height: 70%;
    }

    .projected-need-element {
        height: 80px;
        outline: none;
        padding: 0;
    }

    .projected-need-element span:first-of-type {
        border-right: 1px solid #d9dfe5;
    }

    .projected-need-element.selected {
        background-color: #e6e6e6;
    }

    .projected-need-tabs .MuiTabs-indicator {
        background-color: transparent;
    }

    .jobforeman-weekly-requested, .transporter-list-table, .reservation-table, .details-table {
        overflow-x: auto;
        width: 100%;
    }

    .banner-vehicle-number-font-style, .banner-date-font-style, .title {
        font-family: Roboto;
        font-style: normal;
        font-stretch: normal;
    }

    .banner-vehicle-number-font-style {
        font-size: 21px;
        font-weight: bold;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: $blue-label-reservation;
    }

    .banner-date-font-style {
        font-size: 10px;
        font-weight: normal;
        line-height: 1.3;
        letter-spacing: normal;
        text-align: center;
        color: #000000;
    }

    .title {
        font-size: 18px;
        font-weight: 500;
        line-height: normal;
        letter-spacing: -0.56px;
        color: #000000;
    }

    .weekly-need-by-chef, .transporter-list {
        margin-right: 15px;
        width: 20%;
    }

    .missed-vehicles {
        width: 80%;
    }

    .transporter-selected-item, .table-header-cell {
        background-color: $blue-light-background;
    }

    .reservation-list {
        margin-right: 15px;
        width: 50%;
        overflow: auto;
    }

    .reserved-number-text {
        width: 55px;
        border: none;
        padding: 4px;
        border-bottom: 1px solid $gray;
        outline: none;
        text-align: center;
    }

    .separator {
        border: 1px solid;
        margin: 5px;
        color: $gray
    }

    .text-nowrap {
        white-space: nowrap;
    }

    td.table-cell, th.table-cell {
        padding: 5px;
        border: 1px solid $gray;
        min-width: 105px;
    }

    td.table-cell {
        min-width: 105px;
    }

    .gray-cell {
        background-color: $gray-background-card;
    }

    .reservation-details-list {
        width: 28%;
        overflow-y: auto;
    }

    .blue-label {
        color: $blue-label-reservation;
    }

    .bold {
        font-weight: bold;
    }

    .title-details-table-label {
        color: #686868;
    }

    .align-center {
        align-self: center;
    }

    .filter-item {
        margin: 0;
    }

    .differentiated-transporter {
        color: darkorange;
    }

    .fixed-head {
        background-color: $blue-light-background;
        position: sticky;
        top: 0;
        z-index: 1000;
    }
}
