@import '../../variables';
@import '../common';

.drawer-vehicle {
    z-index: 0;
    width: $drawer-vehicles-width;
    flex-shrink: 0;

    .drawer-vehicle-paper {
        height: 100vh;
        width: $drawer-vehicles-width;

        .content-drawer-vehicle {
            height: 93vh;
            flex-grow: 1;

            .content-header-drawer-vehicle {
                background-color: $gray;
                padding: $content-header-drawer-vehicle-padding;
                margin-top: 10px;
                height: $content-header-drawer-vehicle-height;

                .title-vehicle {
                    color: black;
                    font-weight: bold;
                    font-size: 1.2rem !important;
                    letter-spacing: -0.63px;
                }

                .track-count {
                    font-weight: bold;
                    font-size: 0.8rem;
                    text-align: end;
                }

                .MuiFormControlLabel-label {
                    font-size: 0.8rem;
                    font-weight: bold;
                }

                .label-generic {
                    color: $color-generic;
                }

                .label-transporter {
                    color: $color-transporter;
                }

                .label-internal {
                    color: $color-internal;
                }

                .label-dailyVehicle {
                    color: $color-dailyVehicle;
                }

                .label-pac {
                    color: $color-contracted;
                }

                .daily-vehicles-datepicker {
                    width: 100%;
                    margin-top: -10px;

                    .datepicker {
                        padding-left: 3rem;

                        input {
                            height: 100%;
                            font-size: 12px;
                            padding: 0;
                        }

                        .k-datepicker {
                            flex-direction: row-reverse;
                            border: none;
                            background: none;
                        }

                        > button {
                            color: $color-dailyVehicle;
                        }
                    }

                    .datepicker > span {
                        height: 20px;
                        width: 100px;
                    }
                }

                .contracted-vehicles-section {
                    width: 100%;
                    margin-top: -9px;
                }
            }

            .content-body-drawer-vehicle {
                overflow: auto;
                padding: 0.5rem;
                height: calc(100% - (#{$content-header-drawer-vehicle-height} + #{$content-header-drawer-vehicle-padding}));

                .vehicle-card {
                    border: 1px solid $gray;

                    .header-vehicle {
                        width: 0.2rem;
                    }

                    .header-generic {
                        background-color: $color-generic;
                    }

                    .header-transporter {
                        background-color: $color-transporter;
                    }

                    .header-internal {
                        background-color: $color-internal;
                    }

                    .header-daily {
                        background-color: $color-dailyVehicle;
                    }

                    .header-contracted {
                        background-color: $color-contracted;
                    }

                    .spacing-left {
                        margin-left: 1px;
                    }

                    .vehicle-title {
                        font-size: 0.72rem;
                        font-weight: 500;
                        font-family: Roboto;

                        .icon-user-internal-external-vehicle {
                            padding-left: 0.4rem;
                            padding-right: 0.4rem;
                            color: #000;
                        }

                        .vehicle-title-row {
                            width: 60%;
                        }
                    }

                    .vehicle-disabled {
                        background-color: lightgray;
                    }

                    .vehicle-driver-destination {
                        font-size: 0.8em;
                    }

                    .vehicle-libelle {
                        font-size: 0.8rem;
                        font-weight: 500;
                        font-family: Roboto;
                        color: $color-thead;
                    }

                    .remaining-numbers, .reservation-numbers {
                        font-size: 0.8rem;
                        font-weight: 500;
                        font-family: Roboto;
                        color: $color-thead;
                        align-self: center;
                    }

                    .remaining-numbers.contracted {
                        width: 18%;
                    }

                    .remaining-numbers.contracted.red, .remaining-numbers.red {
                        color: red;
                    }

                    .hover-vehicle {
                        overflow: hidden;
                        padding: 0.5rem;
                        width: 94%;
                    }

                    .remove-daily-vehicle {
                        width: 3%;
                        text-align: end;
                        padding-top: 0.3rem;
                        cursor: pointer;

                        .close-icon {
                            color: red;
                        }
                    }

                    .reserved-number {
                        width: 20%;
                        align-self: center;
                        text-align: end;
                    }

                    .with-reserved-number, .contracted {
                        width: 75%;
                    }
                }

                .vehicle-card:hover {
                    cursor: pointer;
                    background-color: #337ab7;
                    color: #fff;

                    .vehicle-libelle, .remaining-numbers, .reservation-numbers {
                        color: #fff;
                    }
                }
            }
        }
    }
}

.vehicle-tooltip {
    font-size: 0.8rem !important;
    background-color: white !important;
    color: black !important;
    border: 1px solid black;
    border-radius: unset !important;
}
