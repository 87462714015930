@import '../../../../../shared/_common.scss';

.import-coupa-purchase-prices {
    .MuiDialog-paper {
        height: 80vh;
        width: 70vw;
        max-width: none;
    }

    .content {
        display: flex;
        flex-direction: column;

        .header {
            display: flex;
            flex-direction: row;
            align-items: center;

            .header-item {
                margin-left: 10px;
            }

            .agencies {
                width: 400px;

                .item-option {
                    display: flex;
                    flex-direction: row;
                    width: 100%;
                    justify-content: space-between;
                }
            }

            .spinnerClass {
                margin-left: 15px;
            }
        }

        .purchases-list {
            margin-top: 10px;

            .purchases-list-grid {
                .k-grid-table {
                    z-index: 0;
                }

                .k-grid td, .k-grid th {
                    font-family: Roboto;
                }

                .k-grid th {
                    color: $color-thead;
                }

                .k-checkbox-label {
                    min-height: 24px;
                    min-width: 28px;
                    margin-left: 2px;
                    margin-top: 3px;
                }

                thead {
                    background-color: $gray-background-card;
                }

                thead tr {
                    height: 40px;
                }

                td {
                    height: 40px;
                    font-family: Roboto;
                }
            }
        }
    }
}
