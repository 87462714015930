@import './variables';
@import './shared/common';

@font-face {
    font-family: Roboto;
    src: url(./assets/fonts/Roboto-Regular.woff2) format("woff2"),url(./assets/fonts/Roboto-Regular.woff) format("woff");
    font-weight: 300;
}

@font-face {
    font-family: Roboto;
    src: url(./assets/fonts/Roboto-Regular.woff2) format("woff2"),url(./assets/fonts/Roboto-Regular.woff) format("woff");
    font-weight: 400
}

@font-face {
    font-family: Roboto;
    src: url(./assets/fonts/Roboto-Medium.woff2) format("woff2"),url(./assets/fonts/Roboto-Medium.woff) format("woff");
    font-weight: 500
}

@font-face {
    font-family: Roboto;
    src: url(./assets/fonts/Roboto-Bold.woff2) format("woff2"),url(./assets/fonts/Roboto-Bold.woff) format("woff");
    font-weight: 700
}

@media (max-width: 767px) {
    /* On small screens, the nav menu spans the full width of the screen. Leave a space for it. */
    body {
        padding-top: 50px;
    }
}

.internal-layout > header, .external-layout > header {
    z-index: 99999 !important;
}

body {
    background-color: $gray-background-page !important;
    font-family: 'Roboto' !important;
    -webkit-print-color-adjust: exact !important;
}

body .internal-layout {
    min-width: 1370px;
}

p {
    font-family: 'Roboto' !important;
    font-size: 1rem !important;
}

.transporters-instructions {
    white-space: pre;
}

/*
    scroll bar
*/
::-webkit-scrollbar {
    width: 10px;
    height: 10px
}

::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
}

::-webkit-scrollbar-thumb {
    background: #808080;
    border-radius: 90px;
}

::-webkit-scrollbar-thumb:hover {
    background: #000000;
}


/*
    Menu
*/
a.navbar-brand {
    white-space: normal;
    text-align: center;
    word-break: break-all;
}

html {
    font-size: 14px;
}

@media (min-width: 768px) {
    html {
        font-size: 16px;
    }
}

.box-shadow {
    box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05);
}

.containerDiv {
    width: 100%;
    padding-right: 20px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

.navbar > .containerDiv, .navbar > .container-fluid {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.navbar-nav .nav-item:hover, .navbar-nav .nav-item:focus {
    border-top-color: white;
}

.navbar-nav .nav-item, .navMenu .dropdown-item, .navMenu .div-item {
    border-top: 2px solid transparent;
    text-transform: uppercase;
    color: white;
}

.div-item {
    padding: 0.25rem 1.5rem;
    white-space: nowrap;
    cursor: pointer;
}

.navbar-nav {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
    font-size: small;
}

.dropdown-menu {
    background-color: #000 !important;
}

.dropdown-item:hover, dropdown-toggle:focus, .div-item:hover, .div-item:focus {
    border-top-color: white;
    background-color: #000 !important;
}

.dropdown-toggle::after {
    color: $border-color;
}

/*
    paggination
*/
.pagination > li > a, .pagination > li > span {
    position: relative;
    float: left;
    padding: 6px 12px;
    line-height: 1.42857143;
    text-decoration: none;
    color: #337ab7;
    background-color: #fff;
    border: 1px solid #ddd;
    margin-left: -1px;
}

.pagination > li:first-child > a, .pagination > li:first-child > span {
    margin-left: 0;
    border-bottom-left-radius: 4px;
    border-top-left-radius: 4px;
}

.pagination > .disabled > a, .pagination > .disabled > a:focus, .pagination > .disabled > a:hover, .pagination > .disabled > span, .pagination > .disabled > span:focus, .pagination > .disabled > span:hover {
    color: #777;
    background-color: #fff;
    border-color: #ddd;
    cursor: not-allowed;
}

.glyphicon {
    position: relative;
    top: 1px;
    display: inline-block;
    font-family: Glyphicons Halflings;
    font-style: normal;
    font-weight: 400;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.pagination > .active > a, .pagination > .active > a:focus, .pagination > .active > a:hover, .pagination > .active > span, .pagination > .active > span:focus, .pagination > .active > span:hover {
    z-index: 3;
    color: #fff;
    background-color: #337ab7;
    border-color: #337ab7;
    cursor: default;
}

.b-grid-header-container {
    height: 300px !important;
    max-height: 3em !important;

    .b-grid-headers {
        .b-grid-header, .b-sch-header-row > .b-sch-header-timeaxis-cell {
            color: $title-color !important;
        }

        .b-sch-timerange.b-sch-line {
            margin-left: -1.4em !important;
            width: 3em;
        }
    }

    .b-sch-current-time {
        background-color: $header-menu-item-selected-color !important;
        color: $title-color !important;
        border-radius: 1em !important;
    }
}

.b-grid-body-container {
    .b-grid-subgrid .b-sch-current-time {
        border-left-color: $header-menu-item-selected-color;
    }
}

.menu-list {
    background-color: $header-menu-background-color !important;
}

.menu-item {
    font-size: small !important;
    padding: 0px !important;
}

.nav-link {
    padding: 0.5rem !important;
}

.icon-header {
    padding-left: unset !important;
    padding-top: 0.4rem !important;
}

.nav-link:hover .nav-item, .active:not(.icon-header) .nav-item {
    border-bottom: 3px solid $header-menu-item-selected-color;
    padding-bottom: 0.5em;
}

.nav-link:not(.active) .nav-item {
    color: white !important;
}

.active .nav-item, .nav-link:hover .nav-item {
    color: $header-menu-item-selected-color !important;
}

.transport-agencies-selected {
    background-color: $header-menu-background-transport-agencies;
    border-radius: 2rem;
    margin-right: 1em;
    color: $header-menu-background-color;
}

.notifAl {
    border: 0.15em white solid;
    border-radius: 2rem;
    font-weight: bold;
    padding: 0.1em;
}

.notifAl-cp {
    width: 30px !important;
    height: 30px !important;
    background-color: #e88271 !important;
}

.alert-cell {
    width: 10px !important;
    height: 10px !important;
    align-items: center !important;
}

.container-alert-cell {
    width: 20px !important;
    height: 20px !important;
    background-color: white !important;
    border: 1px solid gray;
    align-items: center !important;
}

.alert-drawer-root {
    background-color: rgba(27, 94, 170, 0.1);

    .alert-drawer-paper {
        width: $drawer-alertes-width;

        .alert-drawer-content {
            flex-grow: 1;
        }
    }
}

.navMenu {
    background-color: #000000;
    font-family: Arial, sans-serif;
}

.nav-menu-drawer-paper {
    width: $drawer-alertes-width;
    background-color: transparent !important;
}

.heightRow {
    height: 10px;
}

thead {
    background-color: rgb(232, 232, 232);
    color: #064379;
    //text-transform: uppercase;
}

.k-grid tbody tr {
    height: 5px;
}

.custom-border {
    border-bottom: 1px solid black;
    border-top: none;
    border-left: none;
    border-right: none;
}

.custom-border:focus {
    border-bottom: 1px solid yellow;
    border-top: none;
    border-left: none;
    border-right: none;
    outline: none !important;
    outline-color: transparent !important;
}


.search-input {
    position: relative;
    padding: 1rem 0 0.5rem 0;
    height: 4.5rem;
    width: 22.5rem;
}

.search-input input {
    border: none;
    border-bottom: 1px solid rgba(0,0,0,1);
    display: block;
    font-size: 1rem;
    line-height: 1rem;
    margin: 0;
    padding: 0.5rem 0;
    width: 100%;
    text-align: left;
    background-color: transparent;
    color: rgba(0,0,0,1);
}

.search-input input:focus {
    outline: none;
}

.search-input label {
    position: absolute;
    left: 0;
    top: 1.625rem;
    width: 100%;
    color: rgba(0,0,0,.3);
    font-size: 1rem;
    line-height: 1rem;
    font-weight: normal;
    pointer-events: none;
    transition: 0.2s ease all;
    -moz-transition: 0.2s ease all;
    -webkit-transition: 0.2s ease all;
}

.search-input input:focus ~ label {
    top: 0;
    font-size: 0.75rem;
    color: rgba(0,0,0,1);
    display: inline-block !important;
}

.search-input span:nth-of-type(3) {
    position: absolute;
    color: rgba(0,0,0,1);
    left: 100%;
    top: 1.625rem;
    font-size: 1rem;
    transition: 0.2s ease all;
    -moz-transition: 0.2s ease all;
    -webkit-transition: 0.2s ease all;
}

.search-input input:focus ~ span:nth-of-type(3) {
    left: -1.625rem;
    top: 1.625rem;
    color: rgba(0,0,0,1);
}

.search-input span:nth-of-type(3).not-empty {
    left: -1.625rem !important;
    top: 1.625rem !important;
}

.search-input span:nth-of-type(4) {
    position: absolute;
    color: rgba(0,0,0,1);
    right: 0;
    top: 1.625rem;
    font-size: 1rem;
    opacity: 0;
    transition: 0.2s ease all;
    -moz-transition: 0.2s ease all;
    -webkit-transition: 0.2s ease all;
}

.search-input input:focus ~ span:nth-of-type(4) {
    color: rgba(0,0,0,1);
    opacity: 1;
    cursor: pointer;
}

.search-input span:nth-of-type(4).not-empty {
    opacity: 1 !important;
}

.search-input .bar {
    display: block;
    width: 0;
    margin-left: 50%;
    height: 1px;
    background: rgba(0,0,0,1);
    opacity: 0;
    transition: 0.2s ease all;
    -moz-transition: 0.2s ease all;
    -webkit-transition: 0.2s ease all;
}

.search-input input:focus ~ .bar {
    opacity: 1;
    width: 100%;
    margin-left: 0;
}

.k-sorted {
    background-color: transparent !important
}



.k-grid-header .k-header {
    height: 20px;
    padding: 0px !important;
    vertical-align: middle !important;
}

.k-grid tbody tr {
    line-height: 14px;
}

.k-grid tbody td {
    padding: 0px;
    vertical-align: middle !important;
}

.k-checkbox-label {
    min-height: 24px !important;
    min-width: 28px !important;
    margin-left: 2px !important;
    margin-top: 3px !important;
}
/*
    on focus set placeholder input 
*/

input:focus::-webkit-input-placeholder {
    color: transparent;
}

input:focus:-moz-placeholder {
    color: transparent;
}
/* FF 4-18 */
input:focus::-moz-placeholder {
    color: transparent;
}
/* FF 19+ */
input:focus:-ms-input-placeholder {
    color: transparent;
}
/* IE 10+ */
.search-input label.not-empty {
    top: 0;
    font-size: 0.75rem;
}


.b-virtual-scrollers {
    height: 10px
}

.product-name {
    font-size: 12px;
    padding: 0 0 !important;
    height: 15px !important;
}

.teamMate:after {
    content: ".";
    display: block;
    height: 0;
    line-height: 0;
    clear: both;
    visibility: hidden;
}

.teamMate span {
    font-size: 12px;
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
    font-weight: bold;
    padding-top: 13px;
    margin: 0;
    line-height: 1.2;
}

.teamMate p {
    margin: 0;
    font-size: .8em;
}

.teamMate svg {
    display: inline-block;
    vertical-align: top;
    margin-left: 10px;
    margin-top: 10px;
    margin-bottom: 5px;
    margin-right: 15px;
}

.mate-info {
    display: inline-block;
    vertical-align: top;
    margin-top: -5px;
    margin-left: -20px;
}

.panelbar-wrapper {
    max-width: 300px;
    margin: 0 auto;
}

.k-master-row {
    font-size: 12px;
    font-weight: 400;
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
    padding: 0 0 0 0 !important;
}

.k-master-row td {
    padding: 0 0 0 0 !important;
}

.k-header {
    font-size: 12px;
    font-weight: 400;
    /*font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";*/
    padding: 16px 24px 16px 0 !important;
}


.k-panelbar {
    height: 48vh;
    overflow-y: scroll
}

tr.k-selected > td {
    background-color: #0094ff !important;
    color: white !important;
}

.spinnerClass {
    margin-top: 0vh;
    text-align: center;
}

.main-spinner {
    margin-top: 0vh;
    margin-left: 48%;
}

.b-sch-event {
    height: 30px !important;
}

.scheduler-expedition {
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
    font-size: 12px;
}

.filtered {
    color: $text-color-filtered;
}

.b-grid-subgrid.b-grid-subgrid-locked.filtered .b-grid-cell {
    color: $text-color-filtered !important;
}

.generic-vehicle-length {
    border: 1px solid black;
    border-radius: 50%;
    background-color: black;
    position: absolute;
    right: 40px;
    width: 35px;
    font-weight: bold;
    font-family: arial, verdana, sans-serif;
    text-align: center;
    font-size: 12px;
    color: white;
}

.vehicule-title {
    font-size: 12px !important;
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol" !important;
    font-weight: bold !important;
}

.veh-type-col {
    height: 22vh;
    overflow-y: scroll;
    overflow-x: hidden
}

.veh-type-container {
    height: 48vh;
    overflow-y: scroll
}

.custom-btn {
    margin-top: 15px !important;
    width: 35px !important;
    height: 35px !important;
}

.vehicule-padding {
    padding: 0 0 0 0 !important;
}


#left-panel-close {
    position: absolute;
    left: calc(16% );
    z-index: 11;
    top: 0%;
    cursor: pointer;
}

.left-hide {
    left: 0% !important;
}

.resize-panel-vertical {
    border: 2px solid white;
    padding: 20px;
    resize: vertical;
    overflow: auto;
    height: 30vh;
}

.resize-table-vertical {
    border: 2px solid white;
    padding: 20px;
    resize: vertical;
    overflow: auto;
    height: 35vh;
}

.barloader-spinner div {
    width: 99%;
    color: black;
    position: absolute;
    top: -1%;
}

.hover-vehicle {
    overflow: hidden
}

.hover-vehicle:hover {
    background-color: #337ab7;
}

.cursor-pointer {
    cursor: pointer;
}

.card-alert-enabled {
    border-left: 2px solid rgba(84, 204, 20, 1) !important;
    height: 3em;
    background: -moz-linear-gradient(left, rgba(84, 204, 20, 0) 0%, rgba(84, 204, 20, 0.025) 100%) !important;
    background: -webkit-linear-gradient(left, rgba(84, 204, 20, 0) 0%,rgba(84, 204, 20, 0.025) 100%) !important;
    background: linear-gradient(to right, rgba(84, 204, 20, 0) 0%,rgba(84, 204, 20, 0.025) 100%) !important;
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0054cc14', endColorstr='#bf54cc14',GradientType=1 ) !important;
}

.card-alert-enabled span {
    font-size: 12px
}

.row-border {
    border-bottom: 2px solid #6c757d !important;
}

.del-cal-trp > .k-widget > .k-grid-container > .k-grid-content {
    overflow-y: auto
}

.field-different-value-transportRequest {
    background-color: $field-td-diff-background-color;
    color: black;
    height: 36px;
    display: flex;
    align-items: center;
    padding-left: 2px;
}

.field-different-value-transportRequest-multi-lines {
    .MuiInputBase-formControl {
        background-color: $field-td-diff-background-color;
        color: black;
        display: flex;
        align-items: center;
        padding-left: 2px;
    }
}

.flex-container {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.flex-container > div {
    margin: 10px !important;
    height: auto;
    min-height: 45px;
    overflow-x: hidden;
    overflow-y: auto;
}

.flex-container > div > div:nth-child(1) {
    min-height: 45px;
    height: 45px;
}

.flex-container > div > div:nth-child(2) {
    min-height: 0px;
    max-height: calc(100% - 45px);
    overflow-y: auto !important;
    overflow-x: hidden;
}


.flex-content {
    display: flex;
}

.flex-content > div {
    margin: 10px !important;
}

.flex-wrap {
    flex-wrap: wrap;
}

.flex-row {
    flex-direction: row;
}

.justify-content-start {
    justify-content: flex-start;
}

.justify-content-center {
    justify-content: center;
}

.justify-content-end {
    justify-content: flex-end;
}

.driver-vehicle {
    margin-top: -6px;
    margin-left: -25px;
}

.k-splitbar-static-vertical {
    display: none !important;
}

.expanded-panel {
    height: 100% !important
}

.btn-grid-bar {
    padding: 7px !important;
    padding-top: 28px !important;
    z-index: 100;
    width: 30px;
    color: rgba(214, 51, 87, 0.82) !important;
}

.k-grid-toolbar {
    height: 10px
}

.k-grid-toolbar:hover {
    background-color: transparent;
    color: transparent
}

.pd-select {
    padding-top: 1.4em;
}

.mrg-btm {
    margin-bottom: 11px !important;
}

.CustomCellTime div {
    border: none;
}


/************************** Begin css TransortPlanSharing **********************************/

.chargments_content {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.chargement_section {
    margin: 10px 0;
}

.card_content {
    box-shadow: none !important;
    background-color: $color-white !important;
}

.expansionPanelDetails {
    border: 1px solid $color-expansion-panel-details-border;
    padding: 0px 0px 0px 0px !important;
}

.expansionPanelDetails > div {
    width: 100%;
}

.expandOpen {
    transform: rotate(180deg);
}

.Show_more {
    margin-left: auto !important;
}

.date_title {
    align-self: center;
}

/*************************** end css TransortPlanSharing **********************************/

.del-cal-trp .k-grid .k-grid-header .k-header .k-link {
    //padding-left: 27px !important;
    //padding-top: 0px !important;
    //padding-right: 0px !important;
    //padding-bottom: 0px !important;
    //margin: -15px -25px;
    font-size: 12px;
}

.delivery-trip-grid .k-grid .k-grid-header .k-header .k-link {
    //padding-left: 27px !important;
    //padding-top: 0px !important;
    //padding-right: 0px !important;
    //padding-bottom: 0px !important;
    //margin: -40px -25px;
    font-size: 12px;
}

.delivery-trip-grid .cell-padding-left {
    padding-left: 0.4em !important;
}

.vehicle-grid .k-grid .k-grid-header .k-header .k-link {
    /*padding-left: 27px !important;
    padding-top: 0px !important;
    padding-right: 0px !important;
    padding-bottom: 0px !important;
    margin: -40px -25px;*/
    font-size: 10px;
}

.vehicle-grid .k-grid tbody td {
    padding: 10px 24px;
}

.al-vehicle-grid .k-grid .k-grid-header .k-header .k-link {
    //padding: 16px 24px !important;
    //margin: -31px -25px;
}

.vehicle-grid .k-grid-header .k-header > .k-link {
    margin: 0 !important;
    padding: 0 !important;
}

.k-grid .k-grid-header .k-header {
    white-space: normal;
}

.paper-cls > div:nth-child(1) {
    width: 80%;
}

.paper-cls > button:nth-child(2) {
    width: 20%;
}


.clear-btn {
    padding: 10px,
}

.divider-cls {
    width: 1px;
    height: 28px;
    margin: 4px,
}


.orange-cls > div > span {
    color: darkorange;
    font-size: 11px !important;
}

.black-cls > div > span {
    color: black;
    font-size: 11px !important;
}

.vehicle-cls > div > div {
    overflow: hidden !important;
}


.tr_dc_section {
    border-radius: 5px;
    border: 1px solid #ccc;
    margin: 5px 0;
}

.hr_style {
    width: 100%;
    border: 2px solid #ccc;
}


.al-vehicle-grid > div > div > div > table > thead {
    background-color: #8bd8ee !important;
}

.TransportNeeded-trip {
    border: gray solid thin;
    color: $color-black !important;
}

.TransportPlanned-trip {
    background-color: $color-planned !important;
}

.TransportConfirmed-trip {
    background-color: $color-confirmed !important;
}

.InProgress-trip {
    background-color: $color-inprogess !important;
}

.Canceled-trip {
    background-color: $color-canceled !important;
}

.Delayed-trip {
    background-color: $color-delayed !important;
}

.Finished-trip {
    background-color: $color-finished !important;
}

.Rejected-Trip {
    background-color: $color-rejected !important;
}

.TransportNeeded-trip, .TransportPlanned-trip, .TransportConfirmed-trip, .InProgress-trip, .Canceled-trip, .Delayed-trip, .Finished-trip, .Rejected-Trip,
.Optimized-flow, .Confirmed-flow, .InProgress-flow, .Finished-flow, .Planned-flow, .Pending-flow, .Canceled-flow,
.Pending-transport-request, .ProductionPlanPublished-transport-request, .TransportPlanPublished-transport-request, .ProductionPlanned-transport-request, .TransportPlanned-transport-request, .Rejected-transport-request,
.Approved-transport-request, .InProgress-transport-request, .Finished-transport-request {
    color: $text-color-status;
    margin-right: 0.5em;
    padding: 5px 0;
    text-align: center;
    border-radius: 4px;
}

.Optimized-flow {
    color: $color-white;
    padding: 5px 0;
    margin-right: 0.5em;
    text-align: center;
    border-radius: 4px;
    background-color: $color-optimized !important;
}

.Confirmed-flow {
    color: $color-white;
    padding: 5px 0;
    margin-right: 0.5em;
    text-align: center;
    border-radius: 4px;
    background-color: $color-confirmed-flow !important;
}

.InProgress-flow {
    color: $color-white;
    padding: 5px 0;
    margin-right: 0.5em;
    text-align: center;
    border-radius: 4px;
    background-color: $color-inProgress-flow !important;
}

.Finished-flow {
    color: $color-white;
    padding: 5px 0;
    margin-right: 0.5em;
    text-align: center;
    border-radius: 4px;
    background-color: $color-finished-flow !important;
}

.Planned-flow {
    color: $color-white;
    padding: 5px 0;
    margin-right: 0.5em;
    text-align: center;
    border-radius: 4px;
    background-color: $color-plannedFlow-flow !important;
}

.Pending-flow {
    color: $color-black;
    padding: 5px 0;
    margin-right: 0.5em;
    text-align: center;
    border: gray solid thin;
    border-radius: 4px;
    background-color: $color-white !important;
}

.Canceled-flow {
    color: $color-white;
    padding: 5px 0;
    margin-right: 0.5em;
    text-align: center;
    border: gray solid thin;
    border-radius: 4px;
    background-color: $color-canceled !important;
}

.Approved-transport-request {
    background-color: $color-validated !important;
    color: $text-color-status !important;
}

.ProductionPlanned-transport-request, .TransportPlanned-transport-request {
    background-color: $color-planned !important;
}

.InProgress-transport-request {
    background-color: $color-inProgress-transportRequest !important;
}

.Finished-transport-request {
    background-color: $color-finished !important;
}

.Rejected-transport-request {
    background-color: $color-rejected !important;
}

.Pending-transport-request, .ProductionPlanPublished-transport-request, .TransportPlanPublished-transport-request {
    color: $text-color-status-pending !important;
    border: gray solid thin;
}

.detailsAddressLabel {
    line-height: 1;
}

.b-sch-event-selected {
    outline: none !important;
}

.simple-dialog-content {
    padding: 24px 24px 20px;
    height: calc(100% - 90px);
}

.dialog-close-button {
    top: 20px;
    right: 8px;
    position: absolute !important;
}

.dialog-header-border {
    border-bottom: 1px solid $dialog-header-border-color;
}

.k-animation-container {
    z-index: 100001 !important;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .simple-dialog-content {
        width: 600px;
    }

    .b-grid-body-container {
        height: calc(88vh - 36px - 83px - 64px - 15px);
    }
}

.b-grid-header-container {
    max-height: 50px !important;
}

/*on a surchagr� la class k-grid-norecords de Kendo pour sauter 
 display:flex qui affiche "Aucune donn�e disponible" tronqu� quand on n'a pas de data. */
.k-grid-norecords {
    text-align: -webkit-left !important;
    display: table-row !important;
}

tr.k-grid-norecords > td {
    padding: 0.5rem;
}

.btn-calendar {
    padding-top: 8px;
    font-size: 10px;
    cursor: pointer;
}

.rec-env-label {
    color: red;
    font-size: 20px;
    font-weight: 500;
    padding-right: 0.1em;
}

.select-search {
    margin-bottom: 20px;
    padding: 0px;
}

.select-search > div {
    z-index: 99999;
    width: 100%;
    color: rgba(0,0,0,0.87);
}

.success-toast {
    background-color: green !important;
    padding-top: 100px;
}

.error-toast {
    background-color: red !important;
    height: 70px;
    font-size: 16px !important;
    font-weight: bold !important;
}

.Toastify__toast-container, .MuiSnackbar-root {
    padding-top: 4rem !important;
}

.simple-dialog > div:nth-child(2) > div:nth-child(1) {
    height: 600px !important
}

.simple-dialog {
    .purchase-price-import-label {
        font-weight: 500;
        color: red;
    }
}

.drawerNotifAL {
    background-color: #fafafa;
    color: black;
}

.drawerPaper {
    background-color: transparent !important;
}

.drawerRoot {
    background-color: rgba(27, 94, 170, 0.1);
}

.drawerList {
    width: 500px;
}

.layout-row > .flex {
    -ms-flex-basis: auto;
    flex-basis: auto;
}

.layout-column > .flex {
    -ms-flex-basis: auto;
    flex-basis: auto;
}

.b-grid-subgrid-locked .b-grid-row.b-selected {
    background-color: #2196F3 !important
}

.b-grid .b-timeline-subgrid .b-grid-row.b-selected:not(.b-group-row) {
    background-color: transparent !important;
}

.b-grid:not(.b-moving-splitter) .b-grid-row.b-hover .b-grid-cell {
    background-color: #f0f1f3 !important;
}

.b-grid.b-timelinebase .b-timeline-subgrid .b-grid-row.b-hover .b-grid-cell,
.b-grid.b-timelinebase .b-timeline-subgrid .b-grid-row.b-hover .b-grid-cell:hover {
    background-color: transparent !important;
}

.b-grid-cell.b-focused:after {
    border: 2px solid white !important;
    border-radius: 0;
}

.drawer > div {
    width: 385px;
    top: 63px;
}

.drawer .MuiDrawer-paper {
    height: calc(100% - 63px) !important;
}

.selectAll > span {
    font-weight: bold;
}

.transporterMainContent {
    padding-right: 400px !important;
}

.ml-label {
    margin-left: 7em !important;
}

.header-menu {
    background-color: $header-menu-background-color !important;
    font-size: small;
    height: $header-menu-height;
}

@media print {
    .screenOnly {
        display: none !important;
    }
}

@media screen {
    .screenOnly {
        display: block !important;
    }
}

.card-header-top {
    border-top-left-radius: 0.2em;
    border-top-right-radius: 0.2em;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0em;
}

.card-header-bottom {
    border-top-left-radius: 0em;
    border-top-right-radius: 0em;
    border-bottom-left-radius: 0.2em;
    border-bottom-right-radius: 0.2em;
}

.card-header-top-added {
    background-color: #2baf00;
}

.card-header-top-modified {
    background-color: $color-light-blue;
}

.card-header-top-deleted {
    background-color: #ff0000;
}

.color-added {
    color: #2baf00;
}

.color-deleted {
    color: #ff0000;
}

.field-new-value, .color-modified {
    color: $color-light-blue;
}

.field-title {
    color: gray;
    font-size: 0.9em;
}

.field-title-dc {
    color: gray;
    font-size: 0.8em;
}

.field-old-value, .field-new-value, .field-value, .field-display, .icon-chevron {
    font-size: 0.8rem !important;
}

.my-card-content {
    padding-bottom: 0px !important;
    padding-left: 8px !important;
    padding-right: 0px !important;
    padding-top: 8px !important;
    font-family: Roboto !important;
}

.drawer-group-notif-dc {
    font-family: Roboto !important;
}

.card-header-dc {
    background-color: $blue-light-background;
}

.card-separator {
    padding-bottom: 0.5rem;
}

.card-title-text {
    font-weight: bold;
    font-size: 1em;
}

.circle-dc-observed {
    color: gray !important;
}

.circle-dc-unobserved {
    color: red !important;
}

.circle-alert-dc-observed {
    background-color: gray !important;
}

.circle-alert-dc-unobserved {
    background-color: red !important;
}

.field-group-dc-title {
    font-weight: bold;
    font-size: 1rem;
}

.field-group-dc-detail {
    font-size: 0.8rem;
}

.container-group-dc, .container-expansionPanel-title {
    display: flex;
    flex-direction: row;
}

.container-expansionPanel-title {
    width: 100%;
}

.container-expansionPanel-title-date a, .field-group-dc-title a {
    color: black;
}

.container-expansionPanel-title-date a:hover, .field-group-dc-title a:hover {
    color: $color-light-blue;
}

.container-expansionPanel-title-date {
    width: 80%;
}

.container-expansionPanel-title-count {
    width: 20%;
    justify-content: flex-end;
    display: flex;
}

.field-group-dc-display {
    cursor: pointer;
    color: $color-light-blue;
    font-weight: bold;
    font-size: 0.8rem;
    justify-content: flex-end;
    display: flex;
    align-items: center;

    .icon-chevron {
        margin-left: 0.4rem;
    }
}

.drawer-group-notif-dc {
    background-color: $gray !important;
    color: black;
}


.container-fluid {
    padding-top: 4rem;
    padding-right: unset !important;
    padding-left: unset !important;
    height: 100vh;
    min-height: 250px;
}

.tooltip-menu-display {
    display: block;
}

.tooltip-menu-not-display {
    display: none;
}

.td-cel-emp {
    font-weight: bold !important;
    color: red;
}


.switch {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 14px;
    margin-bottom: 0;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    left: 0;
    bottom: -3px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
    width: 20px;
    height: 20px;
    box-shadow: 0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12);
    border-radius: 50%;
}

input:checked + .slider:before {
    background-color: #3f51b5;
}

input:checked + .slider {
    background-color: #B7BDE3;
}

input:focus + .slider {
    box-shadow: 0 0 1px #3f51b5;
}

input:checked + .slider:before {
    -webkit-transform: translateX(22px);
    -ms-transform: translateX(22px);
    transform: translateX(22px);
}

/* Rounded sliders */
.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}

.switch-section {
    display: flex;
    align-items: center;
    padding: 5px;
}

.switch-label {
    font-weight: bold;
    margin-left: 0.5rem !important;
}

.finish-checkbox-control {
    border: 2px solid $gray;
    padding: 0px 10px;
    border-radius: 4px;
    margin: 10px 0 !important;
    min-width: 166px;
}

.finish-checkbox-control.isFinish {
    border: 2px solid green;
}

.finished-checkbox {
    color: $finished-checkbox-green !important;
}

.not-finished-checkbox {
    color: $finished-checkbox-black !important;
}

.finish-checkbox-label {
    font-weight: bold;
}

.vehicle-count-container {
    background-color: $input-background-color;
    margin-left: 1em;
    width: 665px;
    border: 1px solid rgba(0, 0, 0, 0.12);
    border-radius: 4px;
    margin-bottom: 2px;

    .vehicle-count-title-margin {
        padding-left: 1em;
    }

    .table-head {
        height: 23px;
    }

    .vt-count-title-master {
        font-size: 0.8em;
        font-weight: bold;
        color: $title-color;
    }

    .divider-title {
        height: 2px;
    }

    .vehicle-count-content {
        padding-top: 5px;
        padding-bottom: 5px;
    }

    .vt-count-title {
        font-size: 0.8em;
        color: $title-color;
    }

    .vt-center {
        text-align: center;
    }

    .vt-count-value {
        font-size: 1em;
        font-weight: bold;
        float: right;
    }
}

.b-grid-body-container {
    height: 66vh !important;
}

.b-sch-event-selected {
    outline: 2px solid yellow !important;
}

.b-sch-event:not(.b-milestone).b-sch-event-selected, .b-sch-event:not(.b-milestone).b-sch-event-selected .specific-price-kind-font {
    color: #000000 !important;
}

.b-sch-event:not(.b-milestone).b-sch-event-selected, .b-sch-event:not(.b-milestone).b-sch-event-selected .negotiated-price-kind-font {
    color: white !important;
}

/*Work around for Bryntum for touch screen*/
.b-gridbase.b-split.b-touch .b-grid-splitter {
    transform: none !important;
}

.b-toast {
    bottom: 84vh !important;
    background-color: red !important;
}

.error-toast-scheduler {
    background-color: red !important;
    height: 35px;
    font-size: 16px !important;
    font-weight: bold !important;
}

.scheduler-popup-w {
    width: 580px;
    max-width: unset !important;

    .b-panel-header {
        background-color: white;
        border-bottom: 1px $border-color solid;

        .b-header-title {
            color: black;
            font-size: 16px !important;
            font-weight: 500;
        }
    }

    .title {
        font-size: 12px;
        color: $filter-color;
    }

    .value {
        font-size: 14px;
        color: black;
    }

    .phone-number-ref a {
        color: blue;
    }

    .icon-phone-number {
        color: $icon-phone-number-color;
        height: 100%;
    }
}

.bg-vtc-title {
    font-family: Roboto;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.38px;
}

.bg-vtc-content {
    background-color: aliceblue;
    font-family: Roboto;
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.56px;
    color: #004466 !important;
}

.tr-billing-error-content {
    overflow-y: auto;
    height: 250px;
}

.vehicle-count-title-margin {
    height: 24px;
}

//Permettre le survol sur les bouttons grisés pour afficher leurs tooltips
.span-disabled {
    cursor: default !important;
}

.MuiIconButton-root.Mui-disabled {
    pointer-events: none !important;
}

.k-widget.k-state-invalid > .k-dateinput-wrap {
    border-color: rgba(0,0,0,0.42) !important;
}

.rectangle_transportRequest {
    height: 100px;
    background-color: #ecf8fd;
}

.bg-button {
    height: 40px;
    border-radius: 2px;
    box-shadow: 0 1px 3px 0 rgba(145, 145, 145, 0.3), 0 1px 2px 0 rgba(0, 0, 0, 0.1);
    background-color: $color-white;
}

.bg-button-finished {
    height: 40px;
    border-radius: 2px;
    box-shadow: 0 1px 3px 0 rgba(145, 145, 145, 0.3), 0 1px 2px 0 rgba(0, 0, 0, 0.1);
    border: solid 1px #2baf00;
    background-color: $color-white;
}

.loading-card-font {
    height: 16px !important;
    font-family: Roboto !important;
    font-size: 12px !important;
    font-weight: normal !important;
    font-stretch: normal !important;
    font-style: normal !important;
    line-height: normal !important;
    letter-spacing: -0.38px !important;
    color: $loading-card-font-color !important;
}

.loading-card-font-p {
    font-family: Roboto !important;
    font-size: 14px !important;
    font-weight: 500 !important;
    font-stretch: normal !important;
    font-style: normal !important;
    line-height: normal !important;
    letter-spacing: -0.44px !important;
    color: #000000 !important;
}

.loading-card-font-phone {
    height: 19px !important;
    font-family: Roboto !important;
    font-size: 14px !important;
    font-weight: 500 !important;
    font-stretch: normal !important;
    font-style: normal !important;
    line-height: normal !important;
    letter-spacing: -0.44px !important;
    color: $loading-card-font-phone_color !important;
}
/*toolbar mixins*/
@media (min-width: 600px) {
    .toolbar-styles {
        min-height: 64px;
    }
}

@media (min-width: 0px) and (orientation: landscape) {
    .toolbar-styles {
        min-height: 48px;
    }
}

.toolbar-styles {
    min-height: 56px;
}

.input-specific-price {
    border: none;
    outline: none;
}

.input-specific-price:focus {
    border-bottom: 1px solid grey;
    outline: none;
}

.phone-number-ref a {
    color: blue !important;
    text-decoration: underline !important;
}

.sendStatus-Successfully {
    background-color: #69db7cb3 !important;
}

tr.k-selected > .sendStatus-Successfully {
    background-color: #69db7cb3 !important;
    color: white !important;
}

.sendStatus-Error {
    background-color: #ec23238f !important;
}

tr.k-selected > .sendStatus-Error {
    background-color: #ec23238f !important;
    color: white !important;
}

.sendStatus-ErrorPartially {
    background-color: #ffa500b3 !important;
}

tr.k-selected > .sendStatus-ErrorPartially {
    background-color: #ffa500b3 !important;
    color: white !important;
}

input[type="checkbox"] {
    cursor: pointer;
}

.generic-error-toast {
    z-index: 100001 !important;
}

.k-upload {
    padding: 0px !important;

    .k-upload-button {
        margin: 0px !important;
        background-color: $gray-background-calendar-file;
    }
}

.Toastify {
    .Toastify__toast-container {
        width: auto;

        .Toastify__toast {
            border-radius: 4px;
            font-size: 0.9em;

            .Toastify__toast-body {
                color: white;

                .Toastify__toast-icon {
                    display: flex;
                    flex-direction: row;
                    align-self: start;

                    > svg {
                        fill: white;
                    }
                }

                .lst-msg-error, .lst-msg-warning {
                    overflow: auto;
                    padding-left: 0.5em;
                }
            }

            .Toastify__close-button {
                color: white;
            }
        }

        .Toastify__toast--error {
            background-color: $snackbar-background-error !important;
        }

        .Toastify__toast--success {
            background-color: $snackbar-background-success !important;
        }

        .Toastify__toast--warning {
            background-color: $snackbar-background-warning !important;
        }
    }
}

.MuiButton-contained {
    &.MuiButton-containedPrimary {
        color: #fff !important;
        background-color: #3f51b5;
    }

    &.MuiButton-containedPrimary:hover {
        background-color: #303f9f;
    }

    &.MuiButton-containedSecondary {
        background-color: $color-secondary;
    }

    &.MuiButton-containedSecondary:hover {
        background-color: #c51162;
    }

    &.Mui-disabled {
        color: rgba(0, 0, 0, 0.26) !important;
        box-shadow: none;
        background-color: rgba(0, 0, 0, 0.12);
    }
}

.label-dailyVehicle, .label-internal, .label-transporter, .label-generic, .label-pac {
    .Mui-checked {
        color: $color-secondary !important;
    }
}

.simple-dialog-title {
    font-weight: 700 !important;
    font-size: 22px !important;
}
