@import '../../../_variables.scss';
@import '../../../shared/_common.scss';

$dialog-content-height: 130px;

.internal-agencies {
    padding: $view-padding;

    .internal-work-agencies-grid {
        width: 100%;

        .k-grid td, .k-grid th {
            font-family: 'Roboto', sans-serif;
        }

        .k-grid th {
            color: $color-thead;
        }

        .k-checkbox-label {
            min-height: 24px;
            min-width: 28px;
            margin-left: 2px;
            margin-top: 3px;
        }

        thead {
            background-color: $gray-background-card;
        }

        thead tr {
            height: 40px;
        }

        .agencies-grid {
            width: 100%;
        }
    }
}

.add-work-agency-dialog {
    .MuiDialog-paper {
        max-width: none;
        width: 684px;
    }

    .dialog-close-button {
        padding: 0 10px;
    }

    .add-work-agency-component {
        .loading-content {
            height: 30px;
        }

        .search-work-agency-component {
            .work-agency-infos {
                height: $dialog-content-height;
            }

            .input-text-content {
                width: 450px;
            }

            .work-agency-id-input-text-label {
                color: $dialog-transport-flow-label-color;
            }

            .work-agency-id-input-text {
                width: 100%;
            }
        }

        .work-agency-choices {
            .work-agency-choices-grid {
                width: 100%;
                height: $dialog-content-height;

                .k-grid td, .k-grid th {
                    font-family: Roboto, sans-serif;
                }

                .k-grid-content {
                    overflow-y: hidden !important;
                }

                thead {
                    background-color: $gray-background-card;
                    color: $color-thead;
                }

                thead tr {
                    height: 40px;
                }
            }
        }

        .previous-button {
            background-color: #fff;
            margin-right: 10px;
            color: unset !important;
        }
    }
}

#add-work-agency-tooltip-image .info-user-text {
    line-height: 1.5;
}

#add-work-agency-tooltip-image > .MuiTooltip-tooltip {
    width: 597px;
    max-width: none;
}