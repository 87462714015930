@import '../../../_variables.scss';

.overhead-expense-referential-view {
    .separator {
        height: 1px;
        background-color: $header-menu-background-color;
        margin-top: unset;
        margin-bottom: unset;
        padding-right: 2em;
        padding-left: 2em;
    }

    .btn-actions {
        margin-right: 0.5em;
        background-color: $button-actions-enabled !important;
        color: white !important;
    }

    .btn-actions-disabled {
        background-color: $button-actions-disabled !important;
        color: white !important;
    }

    .content-header {
        .input-search-overhead-expense {
            height: 40px !important;
            max-width: 20em;
            border-radius: 21.5px;
            background-color: $input-background-color;
            padding-right: unset;

            .icon-search {
                width: 56px;
                color: white;
            }

            .input-icon-close-root, .input-icon-search-root {
                max-height: unset;
            }

            .input-icon-search-root {
                background-color: $icon-input-search-background-color;
                height: 100%;
                border-radius: 21.5px;
            }

            #input-search-text {
                font-size: 12px;
                padding-left: 1em !important;
            }
        }

        label {
            margin: unset !important;
        }

        .fa-trash {
            color: $blue;
        }

        .fa-trash-disabled {
            color: $blue-disabled;
        }
    }

    .overhead-expense-grid {
        .sweet-loading {
            text-align: center;
        }

        .cell-center {
            text-align: center;
        }

        .k-grid td, .k-grid th {
            font-family: Roboto;
        }

        .k-grid th {
            color: $color-thead;
        }

        .k-grid-content {
            overflow: auto;
        }

        .k-checkbox-label {
            min-height: 24px;
            min-width: 28px;
            margin-left: 2px;
            margin-top: 3px;
        }

        thead {
            background-color: $gray-background-card;
        }

        thead tr {
            height: 40px;
        }
    }
}

.simple-dialog-new-add-overhead-expense {
    
    .fa-info-circle-icon {
        color: $icon-info-color;
        cursor: pointer;
    }
    
    .title {
        font-weight: 500;
        color: $information-message-color;
    }

    .k-numerictextbox {
        border-radius: 21.5px !important;
        border: 1px solid $gray !important;
        background-color: $input-background-color !important;
        padding-right: unset;
        padding-left: 1em !important;
        height: 36px !important;
        width: 200px;
    }

    .logistics-unit-select {
        z-index: 2;
    }
}