@import '../../../variables';

.planning-vehicle-status {
    margin-right: 10px;

    .vehicle-canceled, .vehicle-canceled.order-sent, .vehicle-canceled.order-sent.confirmed {
        font-weight: bold;
        font-size: 16px;
        height: 20px;
        width: 20px;
        text-align: center;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .vehicle-canceled, .vehicle-canceled.order-sent {
        color: $canceled-vehicle-color;
    }

    .vehicle-canceled.order-sent {
        border: 1px solid $canceled-vehicle-color;
    }

    .vehicle-canceled.order-sent.confirmed {
        color: white;
        background-color: $canceled-vehicle-color;
    }

    .confirmed-vehicle {
        height: 20px;
        width: 20px;
        border-radius: 50%;
    }

    .confirmed-vehicle-external-orderSent {
        border: 1px solid $color-order-external;
    }

    .confirmed-vehicle-external-orderConfirmed {
        border: 1px solid $color-order-external;
        background-color: $color-order-external;
    }

    .confirmed-vehicle-internal-orderSent {
        border: 1px solid $color-order-internal;
    }

    .confirmed-vehicle-internal-orderConfirmed {
        background-color: $color-order-internal;
        border: 1px solid $color-order-internal;
    }
}
