@import '../../../variables';

.transporter-print {
    font-family: "DejaVu Sans", "Arial", sans-serif;
    font-size: 12px;
    width: 100%;
    padding-top: 8px;

    .important-information {
        font-weight: bold;
        color: red;
    }

    .transporter-print-header {
        min-height: 4em;
        color: white;
        font-weight: 500;
        font-size: 1.2em;
        background-color: $header-menu-background-color !important;

        .transporter-name {
            text-align: center;
            text-decoration: underline;
        }

        .delivery-date {
            text-align: center;
        }

        .reference {
            text-align: end;
        }
    }

    .transporter-print-content {
        .billing-address {
            font-size: 1.1em;
            font-weight: bold;
            color: $blue-label-transporter;
            padding-top: 1em;
            padding-left: 1.5em;
        }

        .vehicle-type {
            font-weight: 500;
            font-size: 1.1em;
            color: $blue-label-transporter;
        }

        .transporter-print-card-vehicle {
            margin: 1em;
            border: 2px solid $blue-label-transporter;

            .MuiCardContent-root {
                padding-bottom: unset;
            }

            .delivery-title {
                text-decoration: underline;

                .nb-delivery {
                    color: $blue-label-transporter;
                    font-weight: 500;
                }
            }

            .transport-request-print {
                margin-bottom: 0.5em;

                .separator-card {
                    margin-top: 0.5em;
                }

                .transport-request-print-card {
                    border: 2px solid $blue-label-transporter;

                    .MuiCardContent-root {
                        padding: unset;
                    }

                    .transport-request-print-header {
                        min-height: 4em;
                        color: white;
                        font-weight: 500;
                        background-color: $header-menu-background-color !important;

                        .formule {
                            text-align: center;
                        }

                        .jobForeman {
                            text-align: end;
                        }
                    }

                    .transport-request-print-content {
                        padding: 1em;

                        .details {
                            min-width: calc(100% - 260px);
                        }
                        
                        @media (max-width: 799px) {
                            .details {
                                width: 100%;
                            }
                        }

                        @media (min-width: 800px) {
                            .details {
                                min-width: calc(100% - 260px);
                            }
                        }
                    }
                }
            }

            .icon-work {
                margin-left: 1em;
                margin-right: 1em;
            }

            .main-price-kind-ending-letters {
                font-size: 0.6em !important;
            }

            .main-price-kind-first-letter {
                margin-left: 0.5em;
                font-weight: bold;
                font-size: 1.2em;
            }

            .contract-number {
                margin-left: 0.5em;
                color: $color-contracted;
            }

            .trip-order-title {
                font-weight: 500;
                padding-right: 0.5em;
                min-width: 150px;
            }

            .trip-order-card {
                color: white;
                background-color: $color-blue-dark;
                margin-right: 0.5em;
                border: 1px solid;
                border-radius: 0.4em !important;
                width: 200px;

                .trip-number {
                    margin-left: 0.2em;
                    font-size: 1em;
                }

                .trip-label {
                    font-size: 0.8em;
                    width: 100%;
                }
            }
        }

        .icon-transporter {
            padding-right: 5px;
        }
    }

    .value-field {
        font-weight: bold;
    }
}

.highlighted {
    background-color: $color-highlighted;
}

@media print {
    .transporter-print {
        page-break-after: always;
    }

    .transport-request-print-card {
        page-break-inside: avoid;
    }
}

@font-face {
    font-family: "DejaVu Sans";
    src: url(../../../assets/fonts/DejaVuSans.ttf) format("truetype");
}

@font-face {
    font-family: "DejaVu Sans";
    font-weight: bold;
    src: url(../../../assets/fonts/DejaVuSans-Bold.ttf) format("truetype");
}

@font-face {
    font-family: "DejaVu Sans";
    font-style: italic;
    src: url(../../../assets/fonts/DejaVuSans-Oblique.ttf) format("truetype");
}

@font-face {
    font-family: "DejaVu Sans";
    font-weight: bold;
    font-style: italic;
    src: url(../../../assets/fonts/DejaVuSans-Oblique.ttf) format("truetype");
}
