@import '../../../_variables.scss';

.negotiated-purchase-prices-referential-view {
    .title-section {
        padding: $referencial-negotiated-purchase-prices-title-section-padding-top-left-right $referencial-negotiated-purchase-prices-title-section-padding-top-left-right 0 $referencial-negotiated-purchase-prices-title-section-padding-top-left-right;
        height: $referencial-negotiated-purchase-prices-title-section-height;
    }

    .separator {
        height: 1px;
        background-color: $header-menu-background-color;
        margin-top: unset;
        margin-bottom: unset;
        padding-right: 2em;
        padding-left: 2em;
    }

    .content-section {
        padding: $referencial-negotiated-purchase-prices-content-padding-top-bottom;
    }

    .header-content {
        padding: 0 300px;
        height: $referencial-negotiated-purchase-prices-header-content-height;

        .logistics-unit-select {
            width: 370px;

            div {
                z-index: 10;
            }
        }

        .fa-btn {
            color: $blue;
        }

        .fa-btn-disabled {
            color: $blue-disabled;
        }

        .input-search-negotiated-purchase-price {
            height: 40px !important;
            max-width: 20em;
            border-radius: 21.5px;
            background-color: $input-background-color;
            padding-right: unset;

            .icon-search {
                width: 56px;
                color: white;
            }

            .input-icon-close-root, .input-icon-search-root {
                max-height: unset;
            }

            .input-icon-search-root {
                background-color: $icon-input-search-background-color;
                height: 100%;
                border-radius: 21.5px;
            }

            #input-search-text {
                font-size: 12px;
                padding-left: 1em !important;
            }
        }
    }

    .negotiated-purchase-prices-content {
        padding: $referencial-negotiated-purchase-prices-content-padding-top-bottom 0;

        .negotiated-purchase-prices-grid {
            .k-grid td, .k-grid th {
                font-family: Roboto;
            }

            .k-grid th {
                color: $color-thead;
            }

            .k-grid-header th {
                font-weight: 500;
                color: #858889;
                height: 40px;
            }

            .k-checkbox-label {
                min-height: 24px;
                min-width: 28px;
                margin-left: 2px;
                margin-top: 3px;
            }

            thead {
                background-color: #F0F4F6;
                height: 17px;
                font-size: 12px;
                font-weight: 600;
                letter-spacing: -0.3px;
                line-height: 17px;
            }
        }
    }
}

.add-purchase-price-dialog {
    .MuiDialog-paper {
        max-width: none;
        width: 875px;
        height: 80vh;
    }

    .dialog-close-button {
        padding: 0 10px;
    }

    .add-negotiated-purchase-prices-dialog {
        height: 100%;

        .selected-item {
            background-color: #2684FF;
        }

        .selected-item-label {
            color: #fff;
        }

        .prices-overlapping-dates-error {
            color: red;
        }

        .content {
            height: calc(100% - 46px);
            overflow: auto;
            margin-bottom: 10px;
            padding-right: 5px;


            .site-fieldset {
                border: 1px solid $dialog-transport-flow-input-text-border-color;
                border-radius: 4px;
                padding: 15px;
                margin-bottom: 10px;
            }

            .site-fieldset > legend {
                width: 70px;
                margin-left: 20px;
                margin-bottom: 0;
                padding: 0 5px;
                font-size: 12px;
                line-height: 17px;
                color: $dialog-label-color;
            }

            .label {
                font-size: 12px;
                color: $dialog-label-color;
            }

            .label.required {
                color: $dialog-required-label-color;
            }

            .input-select {
                width: 250px;
            }

            .input-select-vehicle-type-group {
                width: 350px;
            }

            .input-select-vehicle-type-group.required > div {
                border-color: $dialog-required-label-color;
            }

            .input-select.required > div {
                border-color: $dialog-required-label-color;
            }

            .input-text {
                height: 38px;
                border: 1px solid $dialog-transport-flow-input-text-border-color;
                border-radius: 0.25rem;
                padding: 0 10px;
            }

            .input-text.required {
                border-color: $dialog-required-label-color;
            }

            .numeric-input {
                width: 100px;
                border: 1px solid !important;
                border-color: $dialog-transport-flow-input-text-border-color !important;
                border-radius: 4px !important;
            }

            .numeric-input.required {
                border-color: $dialog-required-label-color !important;
            }

            .k-datepicker {
                background: none;
                height: 100%;
    
                .k-input-inner {
                    z-index: unset;
                    padding: 0;
                }

                .k-icon-button {
                    color: rgba(0,0,0,.54);
                }
            }

            .k-input-inner {
                z-index: unset;
            }
        }
    }
}
