@import '../../../variables';
@import '../../../shared/common';

.production-foreman-management-content {
    padding: $view-padding;

    .component-content {
        width: 100%;
        padding-left: 1em;
        padding-right: 1em;
    }

    .is-prd {
        .component-content {
            margin-left: 0;
        }
    }

    .vehicles-content {
        flex-grow: 1;
        width: 100%;
    }

    .content-header {
        padding: $vehicle-header-content-padding-top-bottom 0;
        height: $vehicle-header-content-height;
        align-items: center;

        .btn-calendar {
            margin-right: 10px;
        }

        .production-foreman-section {
            align-self: center;

            .production-foreman-label {
                margin-right: 5px;
                font-family: Roboto;
                font-size: 18px;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                align-self: center;
            }

            .production-foreman-select {
                width: 270px;

                div {
                    z-index: 10;
                }
            }
        }

        .btn-file-excel-avatar {
            background-color: green;

            .btn-file-excel-icon {
                color: white;
            }
        }
    }

    .vehicles-trip-grid {
        height: 100%;
        width: 100%;

        .k-grid td, .k-grid th {
            font-family: Roboto;
        }

        .k-grid th {
            color: $color-thead;
        }

        .k-checkbox-label {
            min-height: 24px;
            min-width: 28px;
            margin-left: 2px;
            margin-top: 3px;
        }

        thead {
            background-color: $gray-background-card;
        }

        .vehicles-grid {
            //height: calc(100vh - (153px + 0.8rem)) !important;
            width: 100%;
        }

        .hide-title-column a, .hide-title-column a:hover,
        .hide-title-column span, .hide-title-column span:hover {
            color: transparent !important;
        }

        .k-sort-order {
            color: transparent !important;
        }

        .hide-title-column {
            color: transparent !important;
            user-select: none;
        }
    }
}

.production-foreman-management-content.is-prd {
    .component-content {
        margin-left: 0;
    }
}
