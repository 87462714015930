@import '../../variables';

.transport-flow-form-header {
    .title {
        font-size: 20px;
        font-weight: bold;
        line-height: 24px;
        color: $dialog-transport-flow-title-color;

        .title-flow-businessid {
            font-size: 16px;
            color: $dialog-transport-flow-label-color;
        }
    }

    .alert-required-fields {
        color: black;
        background-color: $dialog-required-fields-alert-color;
        border-radius: 4px;
        height: 32px;
        width: 376px;
        box-shadow: 2px 2px 8px 0 rgba(0,69,104,0.3);
        font-size: 14px;
        line-height: 19px;
        font-weight: 400;
    }

    .fa-pen-icon {
        color: $dialog-transport-flow-title-color;
        cursor: default;
    }
}

.transport-flow {
    overflow-y: auto;
    height: 100%;

    .transport-flow-dialog-content {
        overflow-y: auto;
        height: calc(100% - 78px);
    }

    .section {
        padding: 15px 0;
        border-bottom: 1px solid $dialog-transport-flow-section-border-bottom;
    }

    .btn-enabled, .btn-enabled:hover {
        background-color: $dialog-transport-flow-enabled-button-bgc;
        color: $dialog-transport-flow-enabled-button-color;
        margin-right: 5px;
    }

    .btn-selected, .btn-selected:hover {
        background-color: $dialog-transport-flow-enabled-button-color;
        color: white;
        margin-right: 5px;
    }

    .btn-selected.disabled {
        background-color: $dialog-transport-flow-disabled-button-color;
        color: white;
    }

    .label, .required-label, .warning-label {
        font-size: 12px;
        line-height: 17px;
    }

    .required-checkbox {
        outline: 1px solid $dialog-transport-flow-error-label-color;
    }

    .required-label-checkbox {
        color: $dialog-transport-flow-error-label-color;
    }

    .billed-price-label, .required-billed-price-label {
        color: $dialog-transport-flow-enabled-button-color;
        font-weight: bold;
        height: 22px;
    }

    .required-label, .required-billed-price-label {
        color: $dialog-transport-flow-error-label-color;
    }

    .warning-label {
        color: $dialog-transport-flow-warning-label-color;
    }

    .input-select {
        width: 250px;
    }

    .input-select.required {
        border: 1px solid $dialog-transport-flow-error-label-color !important;
        border-radius: 0.25em;
    }

    .input-text {
        height: 38px;
        border: 1px solid $dialog-transport-flow-input-text-border-color;
        border-radius: 0.25em;
        padding: 0 10px;

        &.required {
            border: 1px solid $dialog-transport-flow-error-label-color !important;
        }
    }

    .k-numerictextbox {
        background: none;
        border: unset !important;
        
        .k-input-solid {
            border: unset !important;
        }

        .k-input-inner {
            padding: 0 !important;
            text-overflow: unset;
            z-index: unset;
        }
    }

    .input-text-transport-price {
        width: 110px !important;
    }

    .fixed-arrival-time, .fixed-arrival-time input {
        color: $dialog-transport-flow-arrival-time-fixed !important;
    }

    .euro-symbole {
        margin-left: -15px;
        margin-top: 8px;
    }

    .billed-price-item {
        border: 2px solid $dialog-transport-flow-enabled-button-color;
        padding: 5px 10px;
        margin-top: -12px;

        .transport-price .k-input {
            font-weight: bold;
        }

        .euro-symbole {
            font-weight: bold;
        }
    }

    .priceKind {
        width: 130px;
    }

    .purchasePriceKind {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        width: 300px;
        margin-right: 1em;
    }

    .negotiated-purchase-price-r {
        color: red;
        font-weight: 500;
        padding-right: 1.4em;
        font-size: 12px;
    }

    .container, .container-required {
        padding: 0;
        border-radius: 0.25rem;
    }

    .container {
        border: 1px solid $dialog-transport-flow-input-text-border-color !important;
    }

    .container-required {
        border: 1px solid $dialog-transport-flow-error-label-color !important;
    }

    .ton {
        border: 1px solid $dialog-transport-flow-input-text-border-color;
        border-radius: 0.25rem;

        .input-text {
            width: 105px !important;
        }
    }

    .input-text-transport-price.required, .container-required .input-text {
        .k-input-inner {
            padding: 0 !important;
        }
    }

    .input-text.tel {
        width: 176px;
    }

    .input-text.name {
        width: 224px;
    }

    .input-text.idmdm {
        width: 75px;
    }

    .input-text.jobsite-alias {
        width: 400px;
    }

    .input-text.product {
        width: 112px;
        border: 1px solid $dialog-transport-flow-input-text-border-color;
    }

    .container-disabled .input-text, .transport-price-disabled .input-text {
        background-color: #ebebe4;
    }

    .async-select {
        width: 792px;
    }

    .async-select.required > div {
        border: 1px solid $dialog-transport-flow-error-label-color;
    }

    .async-select.warning > div {
        border: 1px solid $dialog-transport-flow-warning-label-color;
    }

    .async-select input {
        opacity: 1 !important;
    }

    .input-text.zipCode {
        width: 75px;
    }

    .input-text.city {
        width: 201px;
    }

    .input-text.site-name {
        width: 185px;
    }

    .instructions-field {
        background-color: $dialog-transport-flow-section-border-bottom;
        border: none;
        border-radius: 4px;
    }

    .MuiInput-underline:before, .MuiInput-underline::after, .MuiInput-underline:hover:not(.mui-disabled):before {
        border: none;
    }

    .ton-icon {
        width: 40px;
    }

    .min-icon {
        text-align: center;
        width: 50px;
    }

    .ton-icon, .min-icon {
        background-color: $dialog-header-border-color;
        align-items: center;
        color: #2B2B2B;
        font-size: 14px;
        font-weight: bold;
        line-height: 19px;
        height: 38px;
        border-bottom-right-radius: 4px;
        border-top-right-radius: 4px;
        display: flex;
        justify-content: center;
    }

    .fa-calculator-icon {
        color: $dialog-transport-flow-enabled-button-color;
        margin-right: 5px;
    }

    .calculate-button {
        color: $dialog-transport-flow-enabled-button-color;
        border: 1px solid $dialog-transport-flow-enabled-button-color;
        margin-right: 20px;
        margin-left: 15px;
    }

    .calculate-button.disabled {
        background-color: #ebebe4;
        color: white;
    }

    .reset-button, .reset-button:hover {
        margin-right: 20px;
    }

    .quotation-settings {
        width: 100%;
        background-color: rgba(255, 0, 0, 0.07);

        .label {
            font-size: 12px;
            line-height: 17px;
            color: $dialog-transport-flow-label-color;
            border-bottom: 1px solid $dialog-transport-flow-input-text-border-color;
            padding-bottom: 5px;
            margin-bottom: 5px;
        }
    }

    .quotation-setting-section {
        border: 1px solid $dialog-transport-flow-input-text-border-color;
        padding: 5px;
        border-radius: 4px;
        margin-right: 20px;
        margin-bottom: 20px;
    }

    .fa-info-circle-icon {
        margin-right: 10px;
    }

    .pickup-delivery-site {
        width: 1163px;
    }

    .map-content {
        width: 500px;
        height: 267px;
        border-radius: 4px;
        border: 1px solid $dialog-header-border-color;
        margin-right: 10px;

        .content {
            position: relative;
            width: 100%;
            height: 100%;

            .hidden-accessible-element, .atlas-control-container, .mapboxgl-control-container {
                visibility: hidden;
            }

            .mapboxgl-canvas-container {
                height: 100%;
            }
        }

        .estimate-content {
            background-color: #fff;
            opacity: 0.75;
            height: 60px;
            margin-top: -60px;
            position: relative;
            padding: 10px;

            .fa-clock-icon {
                margin-left: 5px;
                margin-top: 0;
                color: $dialog-transport-flow-enabled-button-color;
            }

            .km-unit {
                background-color: $dialog-header-border-color;
                padding: 0 5px;
                margin-left: 5px;
            }
        }
    }

    .fa-clock-icon {
        margin-left: -24px;
        margin-top: 7px;
        color: $dialog-transport-flow-enabled-button-color;
    }

    .fa-clock-icon.loading-time {
        color: black;
        margin-top: 7px;
    }

    .loading-time {
        width: 124px;
        margin-bottom: 0;
        margin-top: 6px;
    }

    .fa-euro-sign {
        margin-top: 6px;
        background-color: $dialog-header-border-color;
        width: 40px;
    }

    .price, .trips {
        width: 70px;
        margin-bottom: 0;
        margin-top: 6px;
    }

    .price {
        width: 100%;
        text-align: end;
    }

    .estimated-price {
        width: 100%;
        margin-bottom: 0;
        margin-top: 6px;
    }

    .date-input input {
        width: 70px !important;
    }

    .k-datepicker, .k-timepicker {
        z-index: 0;
        height: 100%;
        background: none;
        padding-top: 3px;
        padding-bottom: 3px;

        &.k-disabled {
            border-style: dashed;
            background: none;
        }

        .k-input-inner {
            padding: 0;
        }

        .k-icon-button {
            color: rgba(0,0,0,.54);
        }
    }

    .date-input.required > .k-dateinput, .required-date-picker > .k-state-default {
        border-bottom: 1px solid $dialog-transport-flow-error-label-color;
    }

    .plannedStatus {
        color: $color-planned;
    }

    .confirmedStatus {
        color: $color-confirmed;
    }

    .inProgressStatus {
        color: $color-inprogess;
    }

    .finishedStatus {
        color: $color-finished;
    }

    .canceledStatus {
        color: $color-canceled;
    }

    .map-marker-icon {
        padding: 0 5px 0 0;
        width: 38px;
    }

    .map-marker-button {
        outline: none;
        padding: 0;
    }

    .map-marker-button:hover {
        background-color: transparent;
    }

    .map-marker {
        color: $map-marker-color;
    }

    .map-marker-disabled {
        color: $blue-disabled;
    }

    .map-marker-text {
        font-size: small;
        color: $map-marker-text-color;
        margin-left: -15px;
        margin-top: -10px;
    }

    .address-failed {
        border: 1px solid $dialog-transport-flow-error-label-color;
        padding-top: 15px;
        margin: 0 10px 10px 0;
    }

    .icons-reverse-button {
        width: 38px;
        font-size: 0.9em;
        padding: unset;
    }

    .alert-address-failed {
        color: $dialog-transport-flow-error-label-color;
        margin-top: 5px;
    }

    .job-code-zephyr-button, .job-code-zephyr-button:hover, .verify-jobmdmid-button, .verify-jobmdmid-button:hover {
        background-color: $dialog-transport-flow-enabled-button-color;
        color: white;
        height: 100%;
        width: 100%;
        outline: none;
    }

    .job-code-zephyr-button.disabled, .verify-jobmdmid-button.disabled {
        background-color: $dialog-transport-flow-disabled-button-color;
        color: white;
        height: 38px;
        outline: none;
    }

    .avatar-refresh-zephyr-job-code, .avatar-verify-jobmdmid {
        height: 38px;
        width: 38px;
    }

    .avatar-refresh-zephyr-job-code {
        margin-left: 10px;
    }

    .avatar-verify-jobmdmid {
        margin-right: 20px;
    }

    .fa-person-primary {
        color: $icon-user-color;
    }

    .fa-person-secondary {
        color: black;
    }

    .payer-section {
        padding: 0 20px 0 38px;
    }

    .payer-fieldset, .negotiated-price-fieldset {
        border: 1px solid $dialog-transport-flow-input-text-border-color;
        border-radius: 4px;

        .option-item {
            font-size: 0.9em;
        }
    }

    .payer-fieldset > legend, .negotiated-price-fieldset > legend {
        width: 45px;
        margin-left: 20px;
        margin-bottom: 0;
        padding: 0 5px;
        font-size: 12px;
        line-height: 17px;
        color: #858889;
    }

    .negotiated-price-fieldset {
        padding: 0.8em;
        margin-bottom: 1em;
    }

    .negotiated-price-fieldset > legend {
        font-size: 12px;
        line-height: 17px;
        color: #858889;
    }

    .negotiated-purchase-price-legend {
        width: 135px !important;
    }

    .negotiated-billed-price-legend {
        width: 80px !important;
    }

    .separator-fieldset {
        margin-right: 13em;
    }

    .negotiated-info {
        font-weight: bold;
        color: red;
    }

    .billed-unit-price-locked {
        cursor: pointer;
        color: #00ffff;
    }

    .billed-unit-price-not-locked {
        cursor: pointer;
        color: black;
    }

    .upload-attachment {
        width: 100%;
        margin-top: 15px;

        input {
            z-index: unset;
        }
        
        .k-upload {
            border: none;
        }

        .k-dropzone {
            padding: 0;
            margin-bottom: 10px;
        }

        .k-upload .k-upload-button {
            border-radius: 4px;
            background-color: $blue;
            color: #fff;
            width: 100%;
        }

        .k-upload-status {
            display: none !important;
        }

        .attachment-files {
            height: 100px;
            overflow-y: auto;
        }

        .fa-delete-attachment-icon {
            color: $color-delayed;
        }

        .attachment-name {
            color: $blue;
            cursor: pointer;
        }
    }
}

.confirm-validation-dialog {
    z-index: 100001 !important;
}
