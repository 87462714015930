@import '../../../_variables.scss';
@import '../../../shared/_common.scss';

.logisticsUnits-logisticians {
    padding: $view-padding;

    .header-content {
        .btn-signature {
            text-transform: none;
            color: rgba(0, 0, 0, 0.26);
            background-color: rgba(0, 0, 0, 0.12);
            cursor: default;

            &.enabled {
                color: #fff;
                background-color: #3f51b5;
                cursor: pointer;
            }
        }
    }

    .colas_logistics-unit-grid {
        width: 100%;
        height: calc(100vh - (170px + 0.8rem));

        .k-grid td, .k-grid th {
            font-family: Roboto;
        }

        .k-grid th {
            color: $color-thead;
        }

        thead {
            background-color: $gray-background-card;
        }

        thead tr {
            height: 40px;
        }

        .logistics-unit-grid {
            width: 100%;
            padding-left: 0.2em;

            .k-grid-header-wrap {
                margin-left: 0.5em;
            }

            .k-grid-container {
                margin-left: 0.5em;
            }
        }
    }

    .colas_logisticians-grid {
        width: 100%;
        height: calc(100vh - (170px + 0.8rem));

        .k-grid td, .k-grid th {
            font-family: Roboto;
        }

        .k-grid th {
            color: $color-thead;
        }

        .k-checkbox-label {
            min-height: 24px;
            min-width: 28px;
            margin-left: 2px;
            margin-top: 3px;
        }

        thead {
            background-color: $gray-background-card;
        }

        thead tr {
            height: 40px;
        }

        .logisticians-grid {
            width: 100%;
            padding-left: 0.2em;

            .k-grid-container {
                .k-virtual-content {
                    overflow: auto;
                }
            }
        }

        .checkbox-cell {
            .custom-checkbox {
                margin-right: 10px;
            }
        }

        .cell-disabled {
            color: lightgrey;
        }
    }
}
