@import '../../../_variables.scss';

.diesel-indexes-referential-view {
    .diesel-indexes-referential {
        .content-header-gasole {
            height: $referential-diesel-indexes-content-header-height;
            padding: $referential-diesel-indexes-content-header-padding;

            .cm-button {
                background-color: $icon-user-color;
                border-radius: 50%;
                height: 40px;
                width: 40px;
                min-width: 40px;
                outline: none;
                text-transform: none;
                font-weight: bold;
            }

            .logistics-unit-select {
                width: 370px;
            }
        }

        .title-column {
            font-weight: bold;
            color: $button-actions-enabled;
        }

        .btn-actions {
            width: 200px;
        }

        .gasole-separator {
            height: 1px;
            background-color: $header-menu-background-color;
            margin-top: unset;
            margin-bottom: unset;
            padding-right: 2em;
            padding-left: 2em;
        }

        .vertical-separator {
            width: 1px;
            border-left: 2px solid $header-menu-background-color;
            margin-top: 2em;
            min-height: 400px;
        }

        .btn-actions {
            margin-right: 0.5em;
            background-color: $button-actions-enabled !important;
            color: white !important;
        }

        .list-template {
            .sweet-loading {
                text-align: center;
            }

            overflow-y: auto;
            margin-right: 0.2em;
        }

        .btn-actions-disabled {
            background-color: $button-actions-disabled !important;
            color: white !important;
        }

        .template-card-selected {
            background-color: $template-card-selected;
        }

        .template-card {
            width: 100%;
            margin-bottom: 1em;
            border-radius: 0.5em;
            cursor: pointer;

            .template-content {
                padding-bottom: unset;

                .template-title {
                    color: $card-title-color;
                }

                .template-reference {
                    display: flex;
                    flex-direction: row;
                    width: 100%;
                    align-items: center;

                    .template-reference-title {
                        width: 40%;
                        max-width: 80px;
                    }

                    .template-reference-value {
                        width: 60%;
                    }
                }

                .template-header {
                    display: flex;
                    flex-direction: row;

                    .template-label {
                        width: 85%;
                    }

                    .template-remove-icon {
                        width: 7%;
                        text-align: end;
                        cursor: pointer;

                        .close-icon {
                            color: red;
                        }
                    }

                    .template-modify-save-icon {
                        width: 7%;
                        padding-right: 2px;
                        cursor: pointer;

                        .icon-blue {
                            color: $icon-pen-blue;
                        }
                    }

                    .icon-disabled {
                        color: $blue-disabled;
                        cursor: auto;
                    }
                }

                .weighting-line {
                    .weighting-title {
                        width: 6.5em;
                    }

                    .k-numerictextbox {
                        max-width: 80px;
                    }
                }
            }
        }

        .input-label {
            outline: none;
            height: 40px !important;
            border-radius: 21.5px;
            border: 1px solid $gray;
            background-color: $input-background-color;
            padding-right: unset;
            font-size: 12px;
            padding-left: 1em !important;
            width: 100%;
        }

        .k-numerictextbox {
            width: 200px;
            height: 40px !important;
            border-radius: 21.5px !important;
            border: 1px solid $gray;
            background-color: $input-background-color !important;
            padding-right: unset !important;
            padding-left: 1em !important;
        }

        .transporter-content {
            .sweet-loading {
                text-align: center;
            }

            .transporter__control {
                cursor: auto;

                .transporter__value-container {
                    max-height: 63px !important;
                }
            }

            .transporter__menu {
                background-color: white;
                overflow-y: auto;
                margin-top: 1em;
                display: flex;
                flex-direction: column;

                .transporter__menu-list {
                    padding-top: unset !important;
                    max-height: unset !important;

                    .transporter__option label {
                        margin-bottom: unset !important;
                        margin-left: 0.5em;
                    }
                }
            }
        }

        .diesel-index-component {
            .diesel-index-component-header {
                .input-search-diesel-indexes {
                    height: 40px !important;
                    max-width: 20em;
                    border-radius: 21.5px;
                    background-color: $input-background-color;
                    padding-right: unset;

                    .icon-search {
                        width: 56px;
                        color: white;
                    }

                    .input-icon-close-root, .input-icon-search-root {
                        max-height: unset;
                    }

                    .input-icon-search-root {
                        background-color: $icon-input-search-background-color;
                        height: 100%;
                        border-radius: 21.5px;
                    }

                    #input-search-text {
                        font-size: 12px;
                        padding-left: 1em !important;
                    }
                }

                label {
                    margin: unset !important;
                }

                .fa-trash {
                    color: $blue;
                }

                .fa-trash-disabled {
                    color: $blue-disabled;
                }
            }

            .diesel-index-grid {
                .sweet-loading {
                    text-align: center;
                }

                .cell-center {
                    text-align: center;
                }

                .k-grid td, .k-grid th {
                    font-family: Roboto;
                }

                .k-grid th {
                    color: $color-thead;
                }

                thead {
                    .cell-disabled-sort .k-link {
                        cursor: unset !important;
                    }
                }

                .k-grid-container {
                    .k-virtual-content {
                        overflow: auto;
                    }
                }

                .k-checkbox-label {
                    min-height: 24px;
                    min-width: 28px;
                    margin-left: 2px;
                    margin-top: 3px;
                }

                thead {
                    background-color: $gray-background-card;
                }

                thead tr {
                    height: 40px;
                }

                .cell-weighting {
                    text-align: center;
                }
            }
        }

        .gasoil-indexes-drawer {
            width: $drawer-cm-width;
        }

        .gasoil-indexes-drawer.open {
            height: calc(100vh - 171px);
            border-left: 2px solid $header-menu-background-color;
            padding: 10px;
            overflow: auto;
        }

        .gasoil-indexes-drawer > .MuiDrawer-paper {
            display: contents;
        }

        .indexes-content {
            flex-grow: 1;
            width: 100%;

            &.open {
                margin-right: 0;
                width: calc(100vh - #{$drawer-cm-width});

                @media (max-width: 1800px) {
                    .transporter-template-content {
                        width: 50% !important;
                    }

                    .template-content {
                        width: 25% !important;
                        min-width: 250px !important;
                    }
                }
            }
        }

        .gasoil-indexes-item {
            padding: 10px 0;
            border-bottom: 2px solid $header-menu-background-color;
        }
    }
}

.dialog-add-new-template {
    .weighting-title {
        width: 6em;
    }

    .diesel-template-label {
        font-weight: 500;
        color: $information-message-color;
    }

    .diesel-template-label-rv {
        width: 4em;
    }

    .input-label {
        outline: none;
        height: 40px !important;
        border-radius: 21.5px;
        border: 1px solid $gray;
        background-color: $input-background-color;
        padding-right: unset;
        font-size: 12px;
        padding-left: 1em !important;
        width: 100%;
    }

    .k-numerictextbox {
        height: 40px !important;
        width: 200px;
        border-radius: 21.5px !important;
        border: 1px solid $gray !important;
        background-color: $input-background-color !important;
        padding-right: unset;
        padding-left: 1em !important;
    }
}

.simple-dialog-existing-template {
    .dialog-duplicate-content {
        min-height: 10em;
        padding: unset !important;

        .dialog-duplicate-templates {
            .sweet-loading {
                text-align: center;
            }

            .template-card-logisticsUnit {
                .template-content {
                    padding-top: unset;
                    padding-bottom: unset;
                    padding-left: 0.5em;
                    padding-right: 0.5em;

                    .template-header-logisticsUnit {
                        font-weight: bold;
                        font-size: 1.1em;
                        padding-bottom: 0.5em;
                    }

                    .template-card-duplicate {
                        background-color: $gray-background-page !important;
                        margin-bottom: 0.5em;
                        border-radius: 0.5em;

                        .template-content {
                            padding-bottom: unset;

                            .template-header {
                                display: flex;
                                flex-direction: row;
                                width: 100%;
                                align-items: center;
                                padding-top: 0.5em;

                                .template-label {
                                    width: 92%;
                                }

                                .template-checkBox {
                                    width: 8%;
                                    text-align: end;

                                    .MuiCheckbox-root {
                                        padding-top: unset !important;
                                        padding-bottom: unset !important;
                                    }
                                }
                            }

                            .template-title {
                                color: $card-title-color;
                            }

                            .weighting-line {
                                .weighting-title {
                                    width: 6em;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.simple-dialog-new-template .MuiDialog-paperWidthSm {
    max-width: unset;
    min-width: 850px;
    max-height: calc(100vh - 110px);
}

.simple-dialog-existing-template .MuiDialog-paperWidthSm {
    max-width: unset;
    min-width: 550px;
    max-height: calc(100vh - 110px);
}