@import '../../../variables';

.beneficiaryInvoicesDetails {
    padding: $invoices-Details-content-padding;

    .beneficiary {
        padding-left: 10px;
        height: $invoices-Details-beneficiary-height;
    }

    .calendar {
        height: $invoices-Details-calendar-height;
    }

    .calendar-daterange-picker .k-dateinput {
        background-color: $gray-background-calendar-file;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        width: 48%;
        border: none;

        .k-input-inner {
            padding-left: 0;
            padding-right: 0;
            border-bottom: 1px solid rgba(0,0,0,.54);
        }
    }

    .input-search {
        height: $invoices-Details-input-search-height;
        width: 450px;
    }

    .invoices-details-list {
        width: 100%;
        min-width: 500px;
        padding: $invoices-Details-list-padding;

        .invoices-details-grid {
            width: 100%;

            thead {
                background-color: $header-grid;
                font-size: 12px;
                font-weight: 600;
                letter-spacing: -0.3px;
                line-height: 17px;
            }

            thead tr {
                height: 40px;
            }

            .k-grid-header th {
                font-weight: 500;
                color: #858889;
            }

            table{
                .header{
                    height: 40px;
                    background-color: $color-row-light-blue;
                }

                .vehicle{
                    height: 40px;
                    background-color: white;
                }
            }
        }
    }
}
